<div *ngIf="!isNotif" class="tooltips-container">
   <div class="margin-bottom-05"> {{ text.length > limit ? ( text | slice:0:limit)+"..." : text}}</div>
    <div *ngIf="text.length > limit"
        class="tooltips  fade-in elevate callout  padding-center-1 small-text border medium-grey-bg rounded">
        {{text}}
    </div>
</div>

<div *ngIf="isNotif" class="tooltips-container ">
   
    <ng-content></ng-content>
   
    <div class="relative">
        <div
        class="tooltips warning-bg tiny-text  fade-in elevate callout  padding-center-1 large-text border medium-grey-bg rounded">
      <fa-icon [icon]="faInfoCircle"></fa-icon>  {{text}}
    </div>
    </div>
    
</div>