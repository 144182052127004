import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements OnInit {
  faChevronDown = faChevronDown
  faSearch=faSearch
  faTimes=faTimes
  searchKey: null;
  @Output() onSearch = new EventEmitter()
  @Output() onSelect = new EventEmitter()
  @Input() option: any
  @Input() label: any
  @Input() isClean?: any
  @Input() value: any
  @Input() selected_option: any
  showOption: boolean;
  constructor() { }

  ngOnInit(): void {
    
  }

  search(e){
   
    this.onSearch.emit(e)
    
  }

  clearSearch(){
    this.searchKey = null
    this.onSearch.emit(this.searchKey)
  }

  selectOption(item){
    this.onSelect.emit(item)
    this.searchKey = null
    this.selected_option = item.label
    this.toggleOption()
  }

  toggleOption(){
    this.showOption = !this.showOption
  }
  onBlur(event){
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.showOption = false
    }
  }
  onSearchBlur(event){
    setTimeout(()=>{
      this.showOption = false
    },300)
   
  }
}
