import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';

@Component({
  selector: 'app-learning-center-backend-view',
  templateUrl: './learning-center-backend-view.component.html',
  styleUrls: ['./learning-center-backend-view.component.scss']
})
export class LearningCenterBackendViewComponent implements OnInit {
  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    showToolbar: false,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',

    toolbarHiddenButtons: [
      ['bold']
    ],

  };

  content_id: string;
  article: any;
  @Input() id: any




  constructor(
    private appService: BusinessIntelligenceService,
    private webService: ManualOrderService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.getLearningCenterId(this.id)

  }

  async getLearningCenterId(id) {

    try {

      let response = await this.webService.getLearningCenterId(id)
      this.article = response.data

      console.log(response)
    } catch (e) {



      console.log(e)
    } finally {

    }
  }

  async addLearningCenter() {

    try {

      let form = {

      }
      let response = await this.webService.addLearningCenter(form)

      console.log(response)

    } catch (e) {
      location.reload
      //this.appService.openToast(e)
      console.warn(e)
      console.log(e)
    } finally {


    }



  }


  async onChange(event: any, id) {
    const file: File = event.target.files[0];


    if (file) {

      if (event.target.files[0].size < 10000000) {
        const formData = new FormData();

        formData.append('file', file);



        try {
          this.appService.showLoading()
          let res = await this.webService.uploadPhoto(formData, 'id')
          console.log(res)
          if (res.data) {



            let photo = {

              'featured_image': res.data

            }
            let response = await this.webService.updateLearningCenter(photo, this.id)

          }

        } catch (error) {
          //this.appService.openToast(error)
        } finally {

          this.appService.hideLoading()

        }
      } else {
        this.appService.openToast('Ukuran file terlalu besar. Batas ukuran adalah 10 Mb')
      }



    }
  }


  async updateLearningCenter() {

    try {

      let form = {
        'term_id': this.article.term_id,
        'title': this.article.title,
        'type': this.article.type,

        'subtitle': this.article.subtitle,
        'article': this.article.article,
        'is_featured': this.article.is_featured,
      }
      let response = await this.webService.updateLearningCenter(form, this.id)

      console.log(response)

      if (response.data) {
        this.appService.openToast('Success')
      }
    } catch (e) {
      location.reload
      //this.appService.openToast(e)
      console.warn(e)
      console.log(e)
    } finally {



    }



  }

  async updateLearningCenterType() {

    try {

      let form = {
        'type': 'INSTAGRAM',

      }
      let response = await this.webService.updateLearningCenter(form, this.id)

      console.log(response)

      if (response.data) {
        this.appService.openToast('Success')
      }
    } catch (e) {
      location.reload
      //this.appService.openToast(e)
      console.warn(e)
      console.log(e)
    } finally {



    }



  }
}
