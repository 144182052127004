import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center-view',
  templateUrl: './learning-center-view.component.html',
  styleUrls: ['./learning-center-view.component.scss']
})
export class LearningCenterViewComponent implements OnInit {
  content_id: string;
  article: any;

  constructor(
    private webService : ManualOrderService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      
      this.content_id = params.get("id")
     
     
      this.getLearningCenterId(this.content_id)
      window.scroll({ 
        top: 0, 
        left: 0, 
      
      });
    })
    
  }

  async getLearningCenterId(id){
    
    try {
      
      let response = await this.webService.getLearningCenterId(id)
        this.article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }


}
