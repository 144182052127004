<app-frontend-top></app-frontend-top>

<div class="section section-home">

    <div class="long-fade-in">
        <div  class="padding-top-10p">
            

            <div class="row">
               
                <div class="medium-8 columns margin-bottom-1">
                    <span class="section-title color-yellow">
                       Learning Center

                    </span>


                </div>
               
            </div>
        </div>



    </div>
</div>

<div class="body-article padding-center">
  
    <div class="row">
        <div *ngIf="article" class="medium-7 columns medium-centered color-black">
            <div class="callout" style="border-bottom:1px dotted #eee">
                <div class="flex-container small-text align-center lato-regular margin-bottom-05">
                    <div class="flex-item margin-right-05 ">
                        <img style="height: 40px;width: 40px;" class="avatar-image" height="40" width="40" src="https://api.wirausahamudamandiri.id/data_file/1730228751_mandiri.png"
                        alt="image">
                    </div>
                    <div class="flex-item large-text bold">
                       Admin,  {{article.created_at | date}}
                    </div>
                </div>
                <div class="section-subtitle margin-bottom-1">
                    {{article.title}}
                </div>
    
                <div style="color: #666;" class="section-para lato-regular">
                    {{article.subtitle}}
                </div>
            </div>

            <div class="padding-center">
                <img style="width: 100%;" [src]="article.featured_image" alt="">
            </div>

           <div style="line-height: 2 !important;" class="lato-regular" [innerHTML]="article.article"></div>

            
        </div>
      
    </div>
    <app-learning-center-related></app-learning-center-related>
 
</div>

<app-section-footer></app-section-footer>