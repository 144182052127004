import { Component, OnInit } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';

@Component({
  selector: 'app-loading-component',
  templateUrl: './loading-component.component.html',
  styleUrls: ['./loading-component.component.scss']
})
export class LoadingComponentComponent implements OnInit {
  faSpinner=faSpinner
  isOpen: boolean;
  message: any;
  constructor(
    private appService : BusinessIntelligenceService,
  ) { }

  ngOnInit(): void {
    this.appService.loadingToastSubject.subscribe(
      (res)=>{
        this.isOpen = res
      }
    )
  }



}
