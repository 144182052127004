import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/userModule/login/login.component';
import { AuthGuard } from './services/auth-guard.service';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { HomepageComponent } from './frontend/homepage/homepage.component';
import { OnboardComponent } from './frontend/onboard/onboard.component';
import { SectionFormComponent } from './frontend/homepage/section-form/section-form.component';
import { RegistrationComponent } from './component/dashboard/registration/registration.component';
import { ParticipantComponent } from './component/dashboard/participant/participant.component';
import { LearningCenterComponent } from './frontend/learning-center/learning-center.component';
import { AlumniHubComponent } from './frontend/alumni-hub/alumni-hub.component';
import { LearningCenterViewComponent } from './frontend/learning-center/learning-center-view/learning-center-view.component';
import { LearningCenterBackendComponent } from './component/dashboard/learning-center-backend/learning-center-backend.component';


const routes: Routes = [

  {
    path: '',
    component: HomepageComponent,
    

  },

   
  
    {
      path: 'learning-center',children:[
        {
          path:'',
          component: LearningCenterComponent,
         
        },
       
        {
          path:'view/:id',
          component: LearningCenterViewComponent,
       

         
        }
      
      ]
      
    },

    {
      path: 'demo',children:[
        {
          path:'',
          component: OnboardComponent,
         
        },  
      
      ]
      
    },


    {
      path: 'alumni-hub',children:[
        {
          path:'',
          component: AlumniHubComponent,
         
        },  
      
      ]
      
    },

   

    {
      path: 'dashboard',children:[
        {
          path:'',
          component: DashboardComponent,
          canActivate: [AuthGuard]

         
        },

        {
          path: 'login',children:[
            {
              path:'',
              component: LoginComponent,
             
            },
          
          ]
          
        },



      {
        path: 'registration',children:[
          {
            path:'',
            component: RegistrationComponent,
            canActivate: [AuthGuard]

          
          },
        
        ],
        
      },


      {
        path: 'learning-center',children:[
          {
            path:'',
            component: LearningCenterBackendComponent,
            canActivate: [AuthGuard]

          
          },
        
        ],
        
      },


      {
        path: 'finalist',children:[
          {
            path:'',
            component: ParticipantComponent,
            canActivate: [AuthGuard]

          
          },
        
        ],
        
      },


      
      ]
      
    },
    
    
  

    // {
    //   path: 'user/user-role',children:[
    //     {
    //       path:'',
    //       component: UserRoleComponent,
    //       canActivate: [AuthGuard]

         
         
    //     },
      
    //   ]
      
    // },

    // {
    //   path: 'user/profile',children:[
    //     {
    //       path:'',
    //       component: ProfileComponent,
    //       canActivate: [AuthGuard]

         
    //     },
      
    //   ]
      
    // },

  




  


    








    
    
   
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
