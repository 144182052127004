<div class="section participant-gallery">


    <div class="padding-center">

        <div class="row">
            <div class="medium-12 columns">
                <!-- <div class="cursive-title color-black">Top 20</div> -->

                <div class="section-title color-black margin-bottom-1">

                    TERIMA KASIH UNTUK MENTOR DAN JUDGES
                </div>
                <div class="section-para color-black">
                    Terima kasih mentor dan judges yang sudah mendukung ekosistem startup
                    di Wirausaha Muda Mandiri 2024
                </div>

              
                <!-- <div class="about-nav flex-container">
                    <div *ngFor="let item of about_nav" class="flex-item">

                        <span (click)="selectCategory(item)" [ngClass]="{active : selectedCategory == item.key}" class="link-nav">
                            {{item.title}}
                        </span>
                    </div>


                </div> -->

            </div>

        </div>

       
        <div class="padding-center">
            <div class="row">
                <div class="medium-12 columns">
                    <div class="section-subtitle color-black margin-bottom-05">
                        Mentor
                    </div>
                    <div class="row small-up-2 medium-up-5 margin-bottom-1">

                        <div *ngFor="let item of mentor" class="columns color-black margin-bottom-2">
                            <div class="participant-card text-center">
                                <div class="image-container margin-bottom-05">
                                    <img class="image_cover"
                                        [src]="item.speaker_photo ? item.speaker_photo : 'https://placehold.co/200'"
                                        alt="">
                                  
                                </div>


                                <!-- <div class="tiny-text">
                                    {{ item.company_category | titlecase }}
                                </div> -->
                                <div class="small-text">
                                    {{ item.agenda | titlecase }}
                                </div>
                                <div class="title">
                                    {{ item.speaker_name | titlecase }}
                                </div>
                                <div class="subtitle">
                                    {{ item.speaker_role | titlecase }}
                                </div>
                               


                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>


    </div>



    <div class="padding-center">

        <div class="row">
            <div class="medium-12 columns">
                <div class="section-subtitle color-black margin-bottom-05">
                    Judges
                </div>

            </div>

        </div>

      
        <div class="padding-center">
            <div class="row">
                <div class="medium-12 columns">
                    <div class="row small-up-2 medium-up-5 margin-bottom-1">

                        <div *ngFor="let item of judge" class="columns color-black margin-bottom-2">
                            <div  class="participant-card text-center">
                                <div class="image-container margin-bottom-05">
                                    <img class="image_cover"
                                        [src]="item.speaker_photo ? item.speaker_photo : 'https://placehold.co/200'"
                                        alt="">
                               
                                </div>


                                <div class="small-text">
                                    {{ item.agenda | titlecase }}
                                </div>
                                <div class="title">
                                    {{ item.speaker_name | titlecase }}
                                </div>
                                <div class="subtitle">
                                    {{ item.speaker_role | titlecase }}
                                </div>
                               


                            </div>
                        </div>


                    </div>
                </div>
            </div>


        </div>


    </div>


   





</div>
