<app-body>
    <div class="page-container">

        <!-- <div class="callout">
            <div class="row">
                <div class="medium-12 columns">
                    <div class="section-title">
                        Registration
                    </div>
                </div>
            </div>
        </div> -->

        <div class="row margin-bottom-1">
            <div class="medium-12 columns">
                <div *ngIf="summary" class="callout padding-center border medium-grey-bg rounded">
                    <div class="flex-container align-center">
                        <div class="flex-item flex-1">
                            <div class="tiny-text color-grey">
                                Total Register
                            </div>
                            <div class="mega-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{summary.total}}
                            </div>
                        </div>
                        <div class="flex-item flex-05">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </div>
                        <div class="flex-item flex-1">
                            <div class="tiny-text color-grey">
                                Complete Personal Details
                            </div>
                            <div class="mega-text">
                                {{summary.personal_details}}
                                <span class="tiny-text color-grey">(
                                    {{this._getPercentage(summary.total,summary.personal_details)}} % )</span>

                            </div>
                        </div>
                        <div class="flex-item flex-05">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </div>
                        <div class="flex-item flex-1">
                            <div class="tiny-text color-grey">
                                Complete Business Details
                            </div>
                            <div class="mega-text">
                                {{summary.business_details}}
                                <span class="tiny-text color-grey">(
                                    {{this._getPercentage(summary.total,summary.business_details)}} % )</span>


                            </div>
                        </div>
                        <div class="flex-item flex-05">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </div>
                        <div class="flex-item flex-1">
                            <div class="tiny-text color-grey">
                                Complete Business Metric
                            </div>
                            <div class="mega-text">
                                {{summary.business_metric}}
                                <span class="tiny-text color-grey">(
                                    {{this._getPercentage(summary.total,summary.business_metric)}} % )</span>
                            </div>
                        </div>
                        <div class="flex-item flex-05">
                            <fa-icon [icon]="faChevronRight"></fa-icon>
                        </div>
                        <div class="flex-item flex-1">
                            <div class="tiny-text color-grey">
                                Complete Data Upload
                            </div>
                            <div class="mega-text">
                                {{summary.data_upload}} <span class="tiny-text color-grey">(
                                    {{this._getPercentage(summary.total,summary.data_upload)}} % )</span>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <div class="row large-up-3">


            <div class="columns margin-bottom-1">
                <app-card [is_expand]="true" lazyload (onShown)="getCompanyCategory()" title="Business Criteria">

                    <div *ngIf="company_category.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of company_category">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.company_category?item.company_category:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>

            </div>

            <div class="columns margin-bottom-1">
                <app-card [is_expand]="true" lazyload (onShown)="getRevenueModel()" title="Revenue Model">
                    <div *ngIf="revenue_model.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>

                    <div class="margin-bottom-2" *ngFor="let item of revenue_model">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.revenue_model?item.revenue_model:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>

            </div>

            <div class="columns margin-bottom-1">

                <app-card [is_expand]="true" lazyload (onShown)="getJobRole()" [is_expand]="true" title="Position">
                    <div *ngIf="job_role.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of job_role">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.company_job_role?item.company_job_role:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>






        </div>

        <div class="row large-up-3">




            <div class="columns margin-bottom-1">
                <app-card lazyload (onShown)="getGender()" title="Gender">
                    <div *ngIf="gender.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of gender">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.gender}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>

            <div class="columns margin-bottom-1">
                <app-card lazyload (onShown)="getFounder()" title="Founder Status">
                    <div *ngIf="is_founder.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of is_founder">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.is_founder== 1?'Founder':item.is_founder == 0?'Non-Founder':'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>

            </div>

            <div class="columns margin-bottom-1">

                <app-card lazyload (onShown)="getFulltime()" title="Working Status">
                    <div *ngIf="is_fulltime.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of is_fulltime">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.is_fulltime==1?'Fulltime':item.is_fulltime==0?'Partime':'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>

            <div class="columns margin-bottom-1">

                <app-card [is_expand]="true" lazyload (onShown)="getCity()" title="City">
                    <div *ngIf="company_city.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>
                    <div class="margin-bottom-2" *ngFor="let item of company_city">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.company_city?item.company_city:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>

            <div class="columns margin-bottom-1">

                <app-card [is_expand]="true" lazyload (onShown)="getProvince()" title="Province">
                    <div *ngIf="company_province.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>

                    <div class="margin-bottom-2" *ngFor="let item of company_province">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.company_province?item.company_province:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>

            <div class="columns margin-bottom-1">

                <app-card [is_expand]="true" lazyload (onShown)="getSurvey()" title="Survey">
                    <div *ngIf="survey.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>

                    <div class="margin-bottom-2" *ngFor="let item of survey">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.survey?item.survey:'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>

            <div class="columns margin-bottom-1">

                <app-card lazyload (onShown)="getBusinessStages()" title="Company Stages">
                    <div *ngIf="business_stages.length === 0">
                        <div class="loading margin-bottom-05" *ngFor="let item of [].constructor(6)">.</div>
                    </div>

                    <div class="margin-bottom-2" *ngFor="let item of business_stages">
                        <div class="flex-container align-center">
                            <div class="flex-item flex-2 margin-right-1">
                                {{item.company_step=='profit'?'Menghasilkan Keuntungan':item.company_step=='revenue'?'Menghasilkan Penjualan':item.company_step == 'idea'?'Tahap Ide':'Belum Memilih'}}
                            </div>

                            <div class="flex-item flex-05 small-text">
                                <fa-icon class="color-grey" [icon]="faUser"></fa-icon> {{item.total}}
                            </div>
                        </div>


                        <app-progress-component label="%" [value]="_getPercentage(summary?.total,item.total)">
                        </app-progress-component>


                    </div>
                </app-card>


            </div>





        </div>

    </div>

</app-body>