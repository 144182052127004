import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
  @Output() onClick = new EventEmitter()
  @Input() message :any
  isShow: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  confirm(data){
    if(data){
      this.onClick.emit()
      this.toggle()
    
    }else{
      this.toggle()
    }
 
  }

  toggle(){
    this.isShow = !this.isShow
  }
}
