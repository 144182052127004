import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-timeline',
  templateUrl: './section-timeline.component.html',
  styleUrls: ['./section-timeline.component.scss']
})
export class SectionTimelineComponent implements OnInit {

  timeline = [
    {
      'title' : 'Registrasi',
      'subtitle' : 'Daftarkan bisnismu ke Wirausaha Muda Mandiri sebagai langkah awal menuju kesuksesanmu!',
      'date' : 'SEP ',
      'month' : ' 2024'
    },
    {
      'title' : 'Roadshow',
      'subtitle' : 'Tim WMM hadir di beberapa kota bersama salah satu founder bisnis ternama yang siap menginspirasi!',
      'date' : 'SEP',
      'month' : '2024'
    },
    {
      'title' : 'Pengumuman TOP 40 ',
      'subtitle' : '40 peserta terbaik akan diumumkan di website WMM dan mendapat pelatihan "Pitching" sebelum diseleksi menjadi Finalis TOP 20.',
      'date' : 'NOV',
      'month' : '2024'
    },
    {
      'title' : 'Pengumuman Finalis TOP 20',
      'subtitle' : 'Selamat! Sebagai Finalis TOP 20, kamu akan berpartisipasi dalam sesi pembekalan dan kompetisi di Jakarta.',
      'date' : 'DEC',
      'month' : '2024'
    },
    {
      'title' : 'Kompetisi dan Pembekalan',
      'subtitle' : 'Finalis TOP 20 akan menerima pembekalan dari pakar bisnis dan bersaing untuk gelar "Best of the Best WMM 2024".',
      'date' : 'JAN',
      'month' : '2025'
    },
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
