<!-- <div *ngIf="article">
    {{article.title}}
</div> -->
<button class="button" (click)="updateLearningCenter()">Update</button>
<button (click)="fileUploadImage.click()"
class="button margin-right-05 tiny clear ">
Cover Photo
</button>

<input class="hide" type="file" accept=".jpg,.png,.jpeg"
(change)="onChange($event,'pitchdeck_url')" #fileUploadImage />


<div class="form">
    <input type="text" placeholder="Term" [(ngModel)]="this.article.term_id">
    <input type="text" placeholder="Add Title" [(ngModel)]="this.article.title">
    <input type="text" placeholder="Add Subtitle" [(ngModel)]="this.article.subtitle">
    <div class="white">
        <angular-editor  [(ngModel)]="this.article.article"></angular-editor>
    </div>
    
</div>
