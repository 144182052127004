<div  [ngClass]="{'card-container': is_expanded}">
    <div [ngClass]="{'clear_bg': is_clear, 'half': is_half}" class="card-content margin-bottom-1 medium-grey-bg ">
        <div class="card-header">
            <div class="flex-container align-center">
                <div *ngIf="title" class="flex-item flex-1">
                    <div class="title">
                        {{title}}
                    </div>
                </div>
                
                <div *ngIf="is_add" class="flex-item">
                    <button (click)="onAdd.emit(true)" class="button tiny clear active">
                        Add
                    </button>
                </div>
    
                <div *ngIf="is_expand" class="flex-item margin-left-02">
                    <button  *ngIf="!is_expanded"  (click)="toggleExpand()"  class="button tiny clear active">
                        <fa-icon [icon]="faExpandAlt"></fa-icon>
                    </button>
                    <button *ngIf="is_expanded" (click)="toggleExpand()"  class="button tiny clear active">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                </div>
            </div>
        </div>
        <div [ngClass]="{'is_expanded': is_expand,'is_fullheigth':is_fullheigth}" class="card-body">
            <ng-content></ng-content>

            

        </div>
        <div *ngIf="is_expand" class="shadow">
            
        </div>
    </div>

</div>