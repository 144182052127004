import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSort } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-sort',
  templateUrl: './table-sort.component.html',
  styleUrls: ['./table-sort.component.scss']
})
export class TableSortComponent implements OnInit {
  faSort=faSort
  @Input() data: any
  
  @Input() type :any
  @Input() key: any
  @Output() onChange = new EventEmitter()
  sortkey: any;
  constructor() { }

  ngOnInit(): void {
    this.sortkey =[]
   
  }


  sort(){
   
    this.sortkey[this.key] = !this.sortkey[this.key]
    var key = this.key
    var type = this.type
    if(this.sortkey[this.key]){
      this.data.sort(function(a, b){
        var nameA = type == 'number'? a[key] :  ( a[key]?a[key].toLowerCase() : '')
        var nameB = type == 'number'? b[key] : ( b[key]?b[key].toLowerCase() : '')
        if (nameA < nameB) //sort string ascending
         return -1;
        if (nameA > nameB)
         return 1;
        return 0; //default return value (no sorting)
  
       }
      );
  
    }else{
      this.data.sort(function(a, b){
        var nameA = type == 'number'? a[key] :  ( a[key]?a[key].toLowerCase() : '')
        var nameB = type == 'number'? b[key] : ( b[key]?b[key].toLowerCase() : '')
        if (nameA > nameB) //sort string ascending
         return -1;
        if (nameA < nameB)
         return 1;
        return 0; //default return value (no sorting)
  
       }
      );
  
    }
   

    this.onChange.emit(this.data)

    

}

}
