<app-frontend-top></app-frontend-top>

<div class="section section-home">

    <div class="long-fade-in">
        <div  class="padding-top-10p">
            

            <div class="row">
               
                <div class="medium-8 columns margin-bottom-1">
                    <span class="section-title color-yellow">
                       Learning Center

                    </span>


                </div>
               
            </div>
        </div>



    </div>
</div>

<div class="body-article padding-center">
    <div class="row">
        <div class="medium-8 columns">
            <div style="border-bottom:1px dotted #eee ;padding-bottom: 2rem;" (click)="_navigateTo(item.id)" *ngFor="let item of article" class="flex-container margin-bottom-2 align-center cursor color-black">
                <div class="flex-item show-for-medium margin-right-2">
                    <img width="300px" [src]="item.featured_image" alt="">
                </div>
                <div class="flex-item flex-1">
                    <img style="width: 100%;margin-bottom: 1rem;" class="show-for-small-only" [src]="item.featured_image" alt="">
                    <div class="flex-container small-text align-center lato-regular margin-bottom-05">
                        <div class="flex-item margin-right-05 ">
                            <img style="height: 35px;width: 35px;" class="avatar-image" height="40" width="40" src="https://api.wirausahamudamandiri.id/data_file/1730228751_mandiri.png"
                            alt="image">
                        </div>
                        <div class="flex-item">
                            {{item.author_id?item.author_id:'Admin'}}, {{item.created_at | date}}
                        </div>
                    </div>
                    <div class="section-subtitle margin-bottom-1">
                       {{item.title}}
                    </div>

                    <div style="color: #666 !important;"  class="huge-text lato-regular">
                        {{item.subtitle}}
                    </div>
                </div>
            </div>

           
        </div>
        <div class="medium-4 columns">
            <div class="section-title margin-bottom-1 color-black">
                Top Content
            </div>
            <div style="border-bottom:1px dotted #eee ;padding-bottom: 0.5rem;" (click)="_navigateTo(item.id)" *ngFor="let item of article ; let i = index" class="flex-container color-black cursor align-center margin-bottom-05">
                <div class="flex-item cursive-title color-yellow margin-right-2">
                  {{i+1}}
                </div>
                <div class="flex-item flex-1">
                    <div class="small-text align-center lato-regular margin-bottom-05">
                        {{item.author_id?item.author_id:'Admin'}}, {{item.created_at | date}}
                    </div>
                    <div  class="huge-text margin-bottom-1 oswald-regular">
                        {{item.title}}
                    </div>

                  
                </div>
            </div>
        </div>
    </div>
</div>

<app-section-footer></app-section-footer>