<div *ngIf="isOpen" class="notif-containers text-center long-fade-in">
 
    <div class="notif-content elevate">
        <div>
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
           
            
         
        </div>
    </div>
</div>

