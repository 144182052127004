import { Component, HostListener, OnInit } from '@angular/core';
import { faBars, faChevronRight, faList } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  isSticky: boolean;
  isShowCTA: boolean;
  regis_id: string;
  faBars=faBars
  faChevronRight=faChevronRight
  @HostListener('window:scroll', ['$event']) 
  doSomething(event) {
    this.isSticky = window.pageYOffset >= 20;

    this.isShowCTA = window.pageYOffset >= window.innerHeight;

  }

  constructor() { }

  ngOnInit(): void {
    this.regis_id = localStorage.getItem("session_id");
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

}
