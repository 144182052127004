import { Directive, AfterViewInit, ElementRef, Output, Inject } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[lazyload]'
})

export class LazyLoadDirective implements AfterViewInit{
  @Output() onShown = new EventEmitter()

  constructor(
    private el: ElementRef<HTMLImageElement>,
  ){}

  ngAfterViewInit() {
    this.shown() ? this.waitToShow() : this.sayShow();
  }

  private shown() {
    return window && 'IntersectionObserver' in window;
  }

  private waitToShow() {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.sayShow()
          //obs.unobserve(this.el.nativeElement);
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  sayShow(){
    this.onShown.emit('true')
  }
}
