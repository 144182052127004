import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ManualOrderService } from './manual-order/manual-order.service';



@Injectable({
  providedIn: 'root'
})


export class AuthGuard implements CanActivate {
  session_id: any;
  constructor(
   
    private webService : ManualOrderService,

    private router: Router
  ) {}
  async canActivate() {
    let response =  await this.webService.getLoginSession();
  
    if(response.data){
      return true
    }else{
       this.router.navigate( ['/dashboard/login'] );
       return false
    }
  }




  encript(data){
    return data.split("").reduce(function(a, b) {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  
}