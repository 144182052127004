<app-body>
    <div class="page-container">  

        <app-modal (onClose)="this.selected_article = null" *ngIf="this.selected_article" [isHalf]="true">
        <app-learning-center-backend-view  [id]="this.selected_article"></app-learning-center-backend-view>

        </app-modal>
        
        <div class="callout">
            <div class="section-title">
                Learning Center
            </div>
        </div>

        
        <div class="table-header">
            <div class="table-header-item flex-container">
               
                <div class="flex-item flex-1">
                    Title
                </div>

                <div class="flex-item flex-2">
                    Subtitle
                </div>

                <div class="flex-item flex-1">
                    Publish Date
                </div>
                
                <div class="flex-item flex-05 margin-right-05">
                    Type
                </div>

                <div class="flex-item flex-05 text-right">
                    Action
                </div>

            </div>
        </div>

        <div class="table-body">
            <div *ngFor="let item of article" class="table-body-item flex-container">
               
                <div class="flex-item flex-1">
                    {{item.title}}
                </div>

                <div class="flex-item flex-2">
                    {{item.subtitle}}
                </div>

                <div class="flex-item flex-1">
                    {{item.created_at | date}}
                </div>
                
                <div class="flex-item flex-05 margin-right-05">
                    {{item.type}}
                </div>

                <div class="flex-item flex-05 text-right">

                    <button (click)="this.selected_article = item.id" class="button tiny clear active">
                        View
                    </button>
                </div>

            </div>
        </div>
      


    </div>


</app-body>

