import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faAdjust, faHands, faMinusCircle, faMinusSquare, faPlusCircle, faPlusSquare, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
showModal: any;
  page: number;
  registration: any;
  selected_participant: any;
  faTrophy=faTrophy
  faPlusCircle=faPlusSquare
  faMinusSquare=faMinusSquare
  sortListItems = [
      
    
    {
     
      'name' : 'status',
      'label' : 'Status',
      'items' :[
        {
          'name' :'0',
          'label' :'Draft'
        },
        {
          'name' :'1',
          'label' :'Confirm'
        },
        
      ]

    },

    {
     
      'name' : 'is_rejected',
      'label' : 'Show Rejected',
      'items' :[
     
        {
          'name' :'1',
          'label' :'Yes'
        },
        
      ]

    },


    {
     
      'name' : 'company_category',
      'label' : 'Category',
      'items' :[
        {
          'name' :'Beauty & Wellness',
          'label' :'Beauty & Wellness'
        },
        {
          'name' :'Fashion',
          'label' :'Fashion'
        },
        {
          'name' :'Innovative Food & Beverages',
          'label' :'Innovative Food & Beverages'
        },
        {
          'name' :'Tech & Tech-Enabled Startups',
          'label' :'Tech & Tech-Enabled Startups'
        },
        
      ]

    },

    {
     
      'name' : 'is_finalist',
      'label' : 'Participant',
      'items' :[
        {
          'name' :'1',
          'label' :'Finalist'
        },
        {
          'name' :'0',
          'label' :'Non Finalist'
        },
        
      ]

    },

    {
     
      'name' : 'gender',
      'items' :[
        {
          'name' :'Pria',
          'label' :'Pria'
        },
        {
          'name' :'Wanita',
          'label' :'Wanita'
        },
        
      ]

    },

    {
     
      'name' : 'is_founder',
      'label' : 'Founder',
      'items' :[
        {
          'name' :'1',
          'label' :'Founder'
        },
        {
          'name' :'0',
          'label' :'Non-Founder'
        },
        
      ]

    },


    {
     
      'name' : 'is_fulltime',
      'label' : 'Working',
      'items' :[
        {
          'name' :'1',
          'label' :'Fulltime'
        },
        {
          'name' :'0',
          'label' :'Parttime'
        },
        
      ]

    },
    
    


    {
     
      'name' : 'progress',
      'label' : 'Progress',
      'items' :[
        {
          'name' :'25',
          'label' :'25%'
        },
        {
          'name' :'50',
          'label' :'50%'
        },
        {
          'name' :'75',
          'label' :'75%'
        },
        {
          'name' :'100',
          'label' :'100%'
        },
        
      ]

    },

    {
     
      'name' : 'company_city',
      'label' : 'Location',
      'items' :[
       
        
      ]

    },
   
  ]
  city_list: any[];
  city_filter: any;
  params: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private serializer: UrlSerializer,
  ) { }

  ngOnInit(): void {
    
   

    this.route.queryParamMap.subscribe(queryParams => {
     

      this.params = this.serializer.serialize(this.router.createUrlTree([''],
      { queryParams: this.route.snapshot.queryParams}))      

      this.getRegistration()
    })

  }

  async setAsFinalist(item,state){
    let form ={
      'is_finalist' : state,
    }
    try {
     
      let response = await this.webService.updateRegistration(item.id,form)
    
      if(response){
        if(state){
          this.appService.openToast(item.fullname +' '+ 'are set as finalist')
          item.is_finalist = state
        }else{
          this.appService.openToast(item.fullname +' '+ 'are remove from finalist')
          item.is_finalist = state
        }
      }
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
     
  
    }
  }

  async reject(item,state){
    let form ={
      'is_rejected' : state,
    }
    try {
     
      let response = await this.webService.updateRegistration(item.id,form)
    
      if(response){
        if(state){
          this.appService.openToast(item.fullname +' '+ 'are rejected')
          item.is_finalist = state
        }else{
          this.appService.openToast(item.fullname +' '+ 'are remove from rejected')
          item.is_finalist = state
        }
      }
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
     
  
    }
  }




  async getRegistration(){
    this.page = 1
    try {
      this.appService.showLoading()
      let response = await this.webService.getRegistration(this.params)
        this.registration = response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async updateTableAction(e){
   if(e.name == 'company_city'){
    try {
     
      let res = await this.webService.getCityList('10', e.keyword)

      if (res.data) {
        this.city_list = res.data.map(item => {

          return {
            name: item.city_name,
            label: item.city_name,

          };
        });

        e.items = this.city_list

      }

    } catch (error) {
      this.appService.openToast(error)
    } finally {
      this.appService.hideLoading()

    }
   }
    
  }



  selectParticipant(item){
    this.selected_participant = item
   console.log(item)

    this.selected_participant.revenue_2023 = item.revenue_2023.replace(/\D/g,'');
    this.selected_participant.revenue_2024 = item.revenue_2024.replace(/\D/g,'');
    this.selected_participant.revenue_2024_valuation = item.revenue_2024_valuation.replace(/\D/g,'');
    this.selected_participant.revenue_2025_valuation = item.revenue_2025_valuation.replace(/\D/g,'');
   
    if(item.product_details || item.product_details_long){
      this.selected_participant.product_details = JSON.parse(item.product_details?item.product_details:item.product_details_long)
    }
    if(item.product_margin || item.product_margin_long){
      this.selected_participant.product_margin = JSON.parse(item.product_margin?item.product_margin:item.product_margin_long)
    }

  }

  pickParticipant(item){
    
    console.log(item)
  }

  convertRev(item){
    if(item){
    //console.log(item.replace(/\D/g,''))
    return item.replace(/\D/g,'')
    }else{
      return 0
    }
  }

  removeCom(item){
    if(item){
     // item = item.replace(/[^a-zA-Z ]/g, "")
     item = item.replace(/[#+()$~%'"*?<>{}]/g,'');
      return item.replace(/,/g, '','(koma)')

    }
  }

  // removeCo2(item){
  //   if(item){
  //     item = item.replace(/[^a-zA-Z ]/g, "")
  //    item = item.replace(/[#+()$~%'"*?<>{}]/g,'');
  //     return item.replace(/,/g, '','(koma)')

  //   }
  // }
  
  export(){

    console.log(this.registration)

    let header = ['name','email','phone','gender','rekening','age','dob','create_date','last_update',
      'company_name','company_category','company_city','company_province','company_start_year','company_step','pitchdeck','business_photo','personal_photo_url','survey',
      'company_job_role','company_employee_size',
      'revenue_2023','revenue_2024','revenue_2024_valuation','revenue_2025_valuation'
    ]
    
    const rows = [
      header,
      ...this.registration.map(item => {
      //  return [item]
       return [this.removeCom(item.fullname),item.email,item.phone,item.gender,item.rekening_mandiri,item.age,item.dob,item.created_at,item.updated_at,
        this.removeCom(item.company_name),item.company_category,this.removeCom(item.company_city),this.removeCom(item.company_province),this.removeCom(item.company_start_year),item.company_step,this.removeCom(item.pitchdeck_url),this.removeCom(item.business_photo_url),this.removeCom(item.personal_photo_url),this.removeCom(item.survey),
        this.removeCom(item.company_job_role),this.removeCom(item.company_employee_size),
        this.convertRev(item.revenue_2023),this.convertRev(item.revenue_2024),this.convertRev(item.revenue_2024_valuation),this.convertRev(item.revenue_2025_valuation)

       ];
      })
    ];



  
   let csvContent = "data:text/csv;charset=utf-8,";
  
    rows.forEach(function(rowArray) {
        let row = rowArray.join(",");
        csvContent += row + "\r\n";
    });


   var encodedUri = encodeURI(csvContent);

    // var csvContent = "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent);

   window.open(encodedUri);

  //   var link = window.document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "member.csv");
  // link.click();


  }


}
