import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-component',
  templateUrl: './progress-component.component.html',
  styleUrls: ['./progress-component.component.scss']
})
export class ProgressComponentComponent implements OnInit {

  @Input() value? : any
  @Input() label? : any
  @Input() isHideLabel? : any

  constructor() { }

  ngOnInit(): void {
    
 
  }

}
