<div class="table-action margin-bottom-1">
    <div class="row collapse">
        <div class="medium-3 columns">
            <div class="search-container form">
                <div class="flex-container">
                    <div class="flex-item flex-1">
                        <div class="search-icon color-grey">
                            <fa-icon [icon]="faSearch"></fa-icon>
                        </div>
                        <div class="search-bar">
                            <input [placeholder]="placeholder?placeholder:'search'" [(ngModel)]="searchKey"
                                (keyup)="search()" type="text">
                        </div>
                    </div>
                    <div *ngIf="searchKey" class="flex-item margin-right-1">
                        <button (click)=" clearSearch()" class="clear-search">
                            <fa-icon [icon]="faTimes"></fa-icon>
                        </button>

                    </div>



                </div>


            </div>


        </div>
        <div class="medium-8 columns">

            <div *ngIf="sortListItems" class="parrent-menu-container margin-right-1">
                <ul tabindex="1" (blur)="closeMenu($event)" class="short-list-menu menu">
                    <li *ngFor="let item of sortListItems" class="parrent-item">
                        <div *ngIf="item.type !=='date'">
                            <div class="flex-container">
                                <div class="flex-item">
                                    <a [ngClass]="{selected_child: item.selected_child}" (click)="openMenu(item)">

                                        <span class="color-grey">
                                            <!-- <fa-icon [icon]="faFilter"></fa-icon> -->
                                            {{item.label?item.label:item.name | titlecase}}
                                        </span>

                                        <span *ngIf="item.selected_child">  {{item.selected_child | titlecase}}</span>

                                    </a>
                                </div>

                                <div *ngIf="!item.selected_child" class="flex-item">
                                    <a (click)="openMenu(item)" class="removeSelectedChild">
                                        <fa-icon [icon]="faChevronDown"></fa-icon>

                                    </a>
                                </div>

                                <div *ngIf="item.selected_child" class="flex-item">
                                    <a [ngClass]="{selected_child: item.selected_child}" class="removeSelectedChild"
                                        (click)="removeSelectedChild(item)">
                                        <fa-icon style="color: #F9E547 !important;" [icon]="faTimes"></fa-icon>

                                    </a>
                                </div>
                            </div>

                            <div *ngIf="item == selected_item" class="child-menu-container fade-in">
                                <div class="relative">
                                    <div class="child-menu-header border-bottom">
                                        <app-form-search (onBlur)="onSearchBlur($event)"
                                            (onSearch)="searchKeyword(selected_item,$event)"></app-form-search>

                                    </div>
                                    <div class="child-menu-content">
                                        <div *ngIf="selected_item.show_items;else other_content">
                                            <ul class="menu vertical">


                                                <li *ngFor="let child_item of selected_item.show_items"
                                                    class="child-item">
                                                    <a (click)="selectChild(selected_item,child_item)">
                                                        {{child_item.label | titlecase}}
                                                    </a>

                                                </li>
                                            </ul>
                                        </div>

                                        <ng-template #other_content>
                                            <ul class="menu vertical">


                                                <li *ngFor="let child_item of selected_item.items" class="child-item">
                                                    <a (click)="selectChild(selected_item,child_item)">
                                                        {{child_item.label | titlecase}}
                                                    </a>

                                                </li>
                                            </ul>

                                        </ng-template>
                                    </div>
                                </div>



                            </div>
                        </div>

                        <div *ngIf="item.type =='date'" class="form">
                            <div class="flex-container align-center">

                                <div class="flex-item">
                                    
                                    <a *ngIf="item.selected_child" class="selected_child">
                                        
                                        <fa-icon [icon]="faFilter"></fa-icon>
                                        {{item.name}} : 
                                        {{item.selected_child | titlecase}}
                                    </a>
                                    <input *ngIf="!item.selected_child" class="date" [(ngModel)]="item.value" [placeholder]="item.name" type="text"
                                        onfocus="(this.type='date');(this.showPicker())" (change)="selectDate(item)">
                                </div>
                                <div  class="flex-item">
                                    <a *ngIf="item.selected_child" class="removeSelectedChild"
                                        (click)="removeSelectedChild(item)">
                                        <fa-icon [icon]="faTimes"></fa-icon>

                                    </a>

                                    <a *ngIf="!item.selected_child" class="removeSelectedChild">
                                        <fa-icon [icon]="faChevronDown"></fa-icon>

                                    </a>
                                </div>
                            </div>


                        </div>





                    </li>




                </ul>




            </div>


        </div>
        <div class="medium-1 columns text-right">
            <div class="parrent-menu-container">
                <ul class="short-list-menu menu menu-align-right" style="position: relative">


                    <li *ngIf="showExport" class="parrent-item label-container">
                        <a (click)="export()" class="small">
                            <fa-icon [icon]="faFileExport"></fa-icon>
                        </a>
                        <div class="labels fade-in">
                            Export </div>


                    </li>
                    <!-- 
                    <li class="parrent-item label-container">
                        <a class="small">
                            <fa-icon [icon]="faList"></fa-icon>
                        </a>
                        <div class="labels fade-in">
                            List View
                        </div>


                    </li>
                    <li class="parrent-item label-container">
                        <a class="small">
                            <fa-icon [icon]="faSquare"></fa-icon>
                        </a>
                        <div class="labels fade-in">
                            Grid View
                        </div>



                    </li> -->
                </ul>
            </div>

            <ng-content></ng-content>



        </div>
    </div>

</div>