<div class="flex-container align-center form hover-show">

    <div class="flex-item flex-1 margin-right-05">
        <div class="cursor" (click)="toggleEdit()" *ngIf="!isEdit">
            <div *ngIf="input.value">
                <div *ngIf="!isHideLabel" class="large-text color-grey">{{input.label}}</div>
                <div *ngIf="!isProgress">
                    <span *ngIf="type == 'currency'">
                        {{input.value | currency : 'IDR'}}
                        
                    </span>
                    <span *ngIf="type == 'text'">
                        <span *ngIf="text_limit">

                            {{ input.value.length > text_limit ? ( input.value | slice:0:text_limit)+"..." : input.value}}
                        </span>
                        <span *ngIf="!text_limit">{{input.value }}</span>
                        
                       
                    </span>
                    <span *ngIf="type == 'date'">
                        {{input.value | date : 'dd/MM/yyyy' }} 
                        
                    </span>

                    <span *ngIf="type == 'number'">
                        {{input.value  }} 
                        
                    </span>
                    <span class="hover-show" *ngIf="input.value && isHideAction">
                        <span *ngIf="!isEdit" (click)="toggleEdit()"
                            class="button margin-bottom-0 tiny rounded  clear hide ">
                           <fa-icon [icon]="faEdit"></fa-icon>
                        </span>
                    </span>



                </div>
                <div *ngIf="isProgress" class="large-text">
                    <app-progress-component [value]="input.value"></app-progress-component>
                </div>


            </div>
            <div *ngIf="!input.value">

                <div class="large-text  color-grey">
                    <span class="active_link"> + Add {{input.label}}</span>

                </div>
            </div>

        </div>

        <div *ngIf="isEdit">
            <label *ngIf="!isHideLabel" for="">{{input.label}}</label>

            <input #search [placeholder]="placeholder?placeholder:'Add '+ input.label" (keydown.enter)="saveData()"
                [(ngModel)]="input.value" class="margin-bottom-0"  [type]="getType()">

        </div>


    </div>
    <div class="flex-item">

        <span *ngIf="!isHideAction">
            <button [disabled]="disabled" *ngIf="!isEdit" (click)="toggleEdit()"
                class="button margin-bottom-0 tiny clear  ">
                {{input.value?'Edit':'Add'}}
            </button>
        </span>



        <button (click)="saveData()" *ngIf="isEdit" class="button margin-bottom-0 tiny active clear ">
            <fa-icon [icon]="faCheckCircle"></fa-icon>
        </button>

    </div>
</div>