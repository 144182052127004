<!-- DIALOG -->
<div *ngIf="dialog" class="notif-containers text-center">
 
    <div class="notif-content elevate">
        <div>
            <h4>{{dialog.title}}</h4>
            <p class="large-text margin-bottom-1">{{dialog.message}}</p>
            <div class="flex-container">
                <div class="flex-item flex-1 margin-right-05">
                    <button (click)="confirmDialog()" class="button small rounded expanded">{{dialog.callBackButton?dialog.callBackButton:'Confirm'}}</button>
                </div>
                <div class="flex-item flex-1">
                    <button (click)="cancelDialog()" class="button small rounded white expanded">Cancel</button>
                </div>
            </div>
            
         
            
        </div>
    </div>
</div>


<!-- TOAST -->
<div *ngIf="toast" class="notification-container fade-in">
    <div class="notification-content elevate medium-grey-bg">
        <div class="flex-container">
          
            <div class="flex-item flex-2">
                <div class="large-text">
                    {{toast.message }}
                </div>
                
            </div>
            <div (click)="cancelToast()" class="cursor close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
            </div>
            <div *ngIf="toast.callBackButton" class="flex-item flex-1 text-right">
                
               <button  (click)="confirmToast()" class="button tiny white">Confirm</button>
            </div>
        </div>
    </div>
</div>