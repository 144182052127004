import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheckSquare, faChevronDown, faSearch, faSquare, faTimes, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-multiple-select',
  templateUrl: './form-multiple-select.component.html',
  styleUrls: ['./form-multiple-select.component.scss']
})
export class FormMultipleSelectComponent implements OnInit {
  faChevronDown = faChevronDown
  faCheckSquare=faCheckSquare
  faSquare=faSquare
  faSearch=faSearch

  faTimes=faTimes
  faTimesCircle=faTimesCircle
  searchKey: null;
  @Output() onSearch = new EventEmitter()
  @Output() onSelect = new EventEmitter()
  @Input() option: any
  @Input() label: any
  @Input() value: any
  @Input() selected_option: any
  showOption: boolean;
  show_option: any;

  
  constructor() { }

  ngOnInit(): void {
    this.show_option = this.option
    this.selected_option = []
  }

  search(e){
    console.log(e)

 this.show_option = this.option
  if(e){
    this.show_option = 
      this.option.filter(item => item.label.toLocaleLowerCase().includes(e.toLocaleLowerCase()));
     
    }

  }    

  clearSearch(){
    this.searchKey = null
    this.show_option = this.option

  }

  selectOption(item){
    //this.onSelect.emit(item)
    item.isSelect = !item.isSelect

   
    this.selected_option = 
    this.option.filter(d => 

      d.isSelect == true 

    )


    this.onSelect.emit(this.selected_option)
  }

  addOption(){
    console.log(this.selected_option)
    this.onSelect.emit(this.selected_option)
    this.showOption =false
  }
  toggleOption(){
    this.showOption = !this.showOption
  }
  onBlur(event){
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.showOption = false
    }
  }
  onSearchBlur(event){
    setTimeout(()=>{
      this.showOption = false
    },300)
   
  }

}
