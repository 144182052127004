import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  page_view: string;
  user_account: any;
  faCircle=faCircle
  faCircleNotch=faCircleNotch
  faCheckCircle=faCheckCircle
  


  loginForm = new FormGroup({
    email: new FormControl(null,{ 
      validators: [Validators.required, Validators.email, Validators.nullValidator]
    }),
    // password: new FormControl(null,{ 
    //   validators: [Validators.required, Validators.nullValidator]
    // }),
    password_hash: new FormControl(null),
   
  })


  

  sessions: any;
  login_view: string;
  register_view: string;
  city_list: any[];
  coba: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    
  ) { }

  ngOnInit(): void {
   
    this.coba = 0

    
    
    this.route.queryParamMap.subscribe(queryParams => {

      
    })
    
  }


   stringToHash(string) {
    return string.split('').reduce((hash, char) => {
        return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash;
    }, 0);
}




  public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    }
  }


  

  async loginAction(){
    
    let key = 'WMM2024'
    let hash = this.stringToHash(this.loginForm.get('email').value + this.loginForm.get('password_hash').value)
    let final_hash = key+this.stringToHash(key)+hash
    this.loginForm.get('password_hash').setValue(final_hash)
    try {
     console.log(this.loginForm.value)
      let res = await this.webService.userLogin(this.loginForm.value)
      if(res.data){
        this.loginForm.reset()
        this.router.navigate(
          ['/dashboard']
        );
      }

    } catch (error) {
      this.coba = this.coba +1
      if(this.coba == 3){
        this.router.navigate(
          ['/']
        );
      }
      this.loginForm.reset()
      this.appService.openToast('Wrong credentials')
    } finally {
      this.appService.hideLoading()

    }
   }
 






}