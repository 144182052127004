<div tabindex="-1" (blur)="onBlur($event)"  class="form relative">
   
    <div  class="flex-container align-center cursor input margin-bottom-0">
        <div class="flex-item flex-1 margin-right-05">
            <div *ngIf="selected_option.length !== 0" >

                <button (click)="selectOption(item)" class="button tiny clear active hollow  relative margin-right-05" *ngFor="let item of selected_option" >
                    {{item.label}}
                    <span class="close-button">
                        <fa-icon [icon]="faTimesCircle"></fa-icon>
                    </span>
                </button>
               
            </div>
            <div (click)="toggleOption()" *ngIf="selected_option.length == 0"  class="large-text color-grey">
                Select {{label?label:'Select option'}}
            </div>
           
        </div>
        <div (click)="toggleOption()" class="flex-item small-text">
            <button class="button clear tiny">
                <fa-icon [icon]="faChevronDown"></fa-icon>
            </button>
        
        </div>
    </div>
  



    <div *ngIf="showOption" class="option-container elevate form fade-in">
        <div class="search-input border-bottom  text-right">
            <app-form-search class="margin-bottom-0"   (onSearch)="search($event)"></app-form-search>

        </div>
        <div  class="search-container rounded padding-top-05">
        
            <div *ngIf="show_option" class="list-tile-container">
                <div (click)="selectOption(item)" *ngFor="let item of show_option" class="list-tile-item cursor">
                    <span *ngIf="item.isSelect"> <fa-icon [icon]="faCheckSquare"></fa-icon> </span>
                    <span *ngIf="!item.isSelect"> <fa-icon [icon]="faSquare"></fa-icon></span>
                    {{item.label}}
                </div>
              
            </div>
           
        </div>
        <div class="done-container border-top text-right" *ngIf="selected_option.length !== 0">
            <button (click)="addOption()" class="button tiny white ">
                Done
            </button>
        </div>

       
      
    </div>
</div>