<div class="form">
    <div class="search-container">
        <div class="flex-container">
            <div class="flex-item flex-1">
                <div class="search-icon color-grey">
                    <fa-icon [icon]="faSearch"></fa-icon>
                </div>
                <div class="search-bar">
                    <input style=" border: 1px solid #424c5f  !important;" class="margin-bottom-0" (blur)="blur()" [(ngModel)]="searchKey" (keyup)="search()" type="text">
                </div>
            </div>
            <div *ngIf="searchKey" class="flex-item">
                <button (click)="clearSearch()" class="clear-search">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </button>
    
            </div>
    
            
        </div>
    
    
    </div>
</div>