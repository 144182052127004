<div tabindex="100" (blur)="closeMenu()" (click)="toggleStatusMenu()" [ngClass]="class" class="status-label">


    <div *ngIf="label" class="labelContainer">
        <span [ngClass]="{scroll: label?.length>12}"> <fa-icon [icon]="faDotCircle"></fa-icon> {{label}} </span>
        
    </div>
    <div>
        <div *ngIf="!disabled">
            <div *ngIf="option">
                <div *ngIf="show_menu" class="status-menu elevate">
                    <ul class="menu vertical">
                        <li *ngFor="let item of option">
                            <a (click)="selectLabel(item)">

                                {{item.label}} <span *ngIf="item.label == label">
                                    <fa-icon [icon]="faCheck"></fa-icon>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>



</div>