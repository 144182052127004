<div *ngIf="showModal">
    <div class="overlays">
       

        <div  [ngClass]="{'modal-container-left': isLeft,'isHalf': isHalf }" class="modal-container-right">
            <div [ngClass]="{'border-bottom': isBorder }"  class="modal-header border-bottom">
                <div class="flex-container align-center">
                    <div  *ngIf="isHalf"  class="flex-item cursor margin-right-2">
                       <button (click)="closeModal()" class="button tiny clear active">
                        <fa-icon [icon]="faArrowsAlt" class="icon"></fa-icon>
                       </button>
                    </div>
                    <div class="flex-item flex-1">
                        <h3 class="margin-bottom-0">{{this.title}}</h3> 
                    </div>
                    <div class="flex-item">
                       

                        <ng-content select="[topbar]"></ng-content>
                    </div>
                    <div (click)="closeModal()" *ngIf="!isHalf"   class="flex-item cursor">
                        <fa-icon [icon]="faTimesCircle" class="icon"></fa-icon>
                    </div>
                </div>
                
               
            </div>
            <div class="flex-container">
                <div class="flex-item flex-1">
                    
                    <div class="modal-body">
                        <ng-content></ng-content>
                      
                    </div>
                </div>
                <div class="flex-item">
                    <ng-content select="[sidebar]"></ng-content>
                </div>
            </div>
          
           
            <div  class="modal-footer">
                <ng-content class="modal-footer" select="[footer]"></ng-content>
            </div>
        </div>
    </div>
    
</div>