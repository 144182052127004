import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tab-option',
  templateUrl: './tab-option.component.html',
  styleUrls: ['./tab-option.component.scss']
})
export class TabOptionComponent implements OnInit {
  @Input() tab_option: any
  @Output() onTab = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  selectOption(item){
    
    item.is_active = true
    this.onTab.emit(item)
  }

}
