import { Component, EventEmitter, Input, OnInit, Output, Renderer2, RendererFactory2,  } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-full',
  templateUrl: './modal-full.component.html',
  styleUrls: ['./modal-full.component.scss']
})
export class ModalFullComponent implements OnInit {
  @Input() title: any
  @Output() onClose = new EventEmitter()
  showModal: boolean;
  renderer: Renderer2;
  constructor(
    
    private rendererFactory: RendererFactory2,
   

  ) { 
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }
  faTimesCircle=faTimes

  ngOnInit(): void {
    this.showModal = true


//    this.renderer.setAttribute(document.body,'class', 'no-scroll');

  }
  closeModal(){
 //   this.renderer.removeAttribute(document.body, 'class')
    console.log('click log')
    this.showModal = false
    this.onClose.emit(false)
  }

}
