<app-top-navigation></app-top-navigation>
<div class="flex-container body-container">
    <div *ngIf="!hideNav" class="sidebar-container show-for-medium sidebar-content">
        <app-bisidebar></app-bisidebar>
    </div>
    <div [ngClass]="{'body-content-100': hideNav}" class="flex-item body-content">
       
        <ng-content></ng-content>

    </div>
</div>

