import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tooltips',
  templateUrl: './tooltips.component.html',
  styleUrls: ['./tooltips.component.scss']
})
export class TooltipsComponent implements OnInit {
  faInfoCircle=faInfoCircle
  @Input() text: any
  @Input() limit: any
  @Input() isNotif: any
  constructor() { }

  ngOnInit(): void {
    if(!this.limit){
      this.limit = 100
    }
  }

}
