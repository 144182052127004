import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ManualOrderService } from './services/manual-order/manual-order.service';





@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  deviceInfo: any;
  session_id: any;
  page_view: string;




  constructor(
 
    private webService : ManualOrderService,
    private router: Router,
    
    

  ) {

  }

  ngOnInit(): void {
  
    

    
  }



  encript(data){
    return data.split("").reduce(function(a, b) {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  
}
