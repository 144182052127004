


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { title } from 'process';

import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-section-mentor',
  templateUrl: './section-mentor.component.html',
  styleUrls: ['./section-mentor.component.scss']
})
export class SectionMentorComponent implements OnInit {



  mentor = [
    {
      "speaker_name": "Brian Imawan",
      "speaker_role": "CEO of JumpStart",
      "speaker_photo": "/assets/image/Brian Imawan, CEO of JumpStart 2.png",
      "company_logo": "https://drive.google.com/file/d/1ilMG52dBBz-SEe6uRcR7elEcC_pOs6gu/view?usp=drive_link",
      'agenda': 'Bootcamp',

    },
    {
      "speaker_name": "Antonius Bong",
      "speaker_role": "President & Co-Founder of Bobobox",
      "speaker_photo": "/assets/image/Antonius Bong, President & Co-Founder of Bobobox 2.png",
      "company_logo": "https://drive.google.com/file/d/1w4WrFhA96bMxteCY9RTsQNtRmGAlXfQH/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Dino Setiawan",
      "speaker_role": "CEO of AwanTunai",
      "speaker_photo": "/assets/image/Dino Setiawan, CEO of AwanTunai.jpg",
      "company_logo": "https://drive.google.com/file/d/1OBfdnwiFKyOrVcyVijxQi6226TMSO87a/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Yukka Harlanda",
      "speaker_role": "CEO of Brodo",
      "speaker_photo": "/assets/image/Yukka Harlanda, CEO of Brodo.jpg",
      "company_logo": "https://drive.google.com/file/d/1tm9lR4s2665UNxDnJi-EeV0987_FXiMl/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Lizzie Parra",
      "speaker_role": "CMO & Co-Founder of BLP Makeup",
      "speaker_photo": "/assets/image/Lizzie Parra, CMO & Co-Founder of BLP Makeup.jpeg",
      "company_logo": "https://drive.google.com/file/d/1nwzvBCxOUSg9Zypp510yvnjnq9aDdHEv/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Nadia Amalia",
      "speaker_role": "CEO & Co-Founder of Fintelite",
      "speaker_photo": "/assets/image/Nadia Amalia, CEO & Co-Founder of Fintelite 2.png",
      "company_logo": "https://drive.google.com/file/d/1ug1EFgT2Gm6ftQhHdAyhEoxL3kRs5VRu/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Rexi Christopher",
      "speaker_role": "Venture Partner of Init 6",
      "speaker_photo": "/assets/image/Rexi Christopher, Venture Partner of Init 6.jpeg",
      "company_logo": "https://drive.google.com/file/d/1sf22LzQ3uQ42wV0ycaafllt2YU7IaYge/view?usp=drive_link",
      'agenda': 'Bootcamp',
    },
    {
      "speaker_name": "Linda Anggrea",
      "speaker_role": "Co-Founder & Chief Executive Officer of Buttonscarves",
      "speaker_photo": "/assets/image/Linda Anggrea.png",
      "company_logo": "https://drive.google.com/file/d/1WEedCuj0yQi_FRgDWKN8hV3A1Xpe91X9/view?usp=drive_link",
      'agenda': 'Company Visit',
    },
    {
      "speaker_name": "Bryant Christanto",
      "speaker_role": "Co-Founder & Chief Executive Officer of Paxel",
      "speaker_photo": "/assets/image/Bryant Christanto.jpg",
      "company_logo": "https://drive.google.com/file/d/1S0IQCogbVFOz6UTWNLFxiDLl8Ita26Xz/view?usp=drive_link",
      'agenda': 'Company Visit',
    },
    {
      "speaker_name": "Howard Gani",
      "speaker_role": "Chief Commercial Officer of Paxel",
      "speaker_photo": "/assets/image/Howard Gani.jpg",
      "company_logo": "https://drive.google.com/file/d/1S0IQCogbVFOz6UTWNLFxiDLl8Ita26Xz/view?usp=drive_link",
      'agenda': 'Company Visit',
    }
  ]

  judge = [
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Rika Indraoktaviani",
      "speaker_role": "Chief Marketing Officer of Female Daily Network",
      "speaker_photo": "/assets/image/Rika Indraoktaviani.jpeg",
      "company_logo": "https://drive.google.com/file/d/1Oto4JK3dDQ-XrVvp0ezwp3b2H-LH9ynJ/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Melina Anlin",
      "speaker_role": "VP of Investment of AC Ventures",
      "speaker_photo": "/assets/image/Melina Anlin.png",
      "company_logo": "https://drive.google.com/file/d/11In31MdbbQ3YvmF6kIVGtByHpCUxNh8P/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Melaney Ricardo",
      "speaker_role": "Crusita",
      "speaker_photo": "/assets/image/Melaney Ricardo.jpeg",
      "company_logo": "https://drive.google.com/file/d/1w2JxS7i57GAHJFkjF32_UfiGhczW7_Wy/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Mifthahul Jannah",
      "speaker_role": "Co-Founder & Chief Product Officer of Modinity Group",
      "speaker_photo": "/assets/image/Mifthahul Jannah.png",
      "company_logo": "https://drive.google.com/file/d/17HXEEJNVuDrFp7VD99bmvlSuhemlxADK/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Samuel Simanjuntak",
      "speaker_role": "Investment Manager of Monk's Hill Ventures",
      "speaker_photo": "/assets/image/Samuel Simanjuntak.jpeg",
      "company_logo": "https://drive.google.com/file/d/1XxqCmKJbIwUBpRn0NN2h1cBqqD1qjjFj/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Raras Cynanthia",
      "speaker_role": "Chief Strategy Office of Kopi Kina",
      "speaker_photo": "/assets/image/Raras Cynanthia.png",
      "company_logo": "https://drive.google.com/file/d/1Vx-Kf1En936_aWNhQDSIA898vTWto5bw/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Amir Karimuddin",
      "speaker_role": "Managing Partner of DS/X Ventures",
      "speaker_photo": "/assets/image/Amir Karimuddin.png",
      "company_logo": "https://drive.google.com/file/d/1XdqsjNXf8E0AN3DQIJfsPOBZgkNhdJ3h/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Yosia Sugialam",
      "speaker_role": "Co-Founder & CEO of Paper.id",
      "speaker_photo": "/assets/image/Yosia Sugialam.jpeg",
      "company_logo": "https://drive.google.com/file/d/1Xesu7tbf2Vlcb14QY2z7mJFakjMC5_fr/view?usp=drive_link"
    },
    {
      'agenda': 'Top 4 Selection',
      "speaker_name": "Tiffany Priosoetanto-Masrin",
      "speaker_role": "Principal of Intudo Ventures",
      "speaker_photo": "/assets/image/Tiffany Priosoetanto-Masrin.png",
      "company_logo": "https://drive.google.com/file/d/11ERRB-ubdixq7y9qvKwFv1wFLS4bNTrD/view?usp=drive_link"
    },
    {
      'agenda': 'Best of the Best Selection',
      "speaker_name": "Joshua Agusta",
      "speaker_role": "Executive Director of Investment of Vertex Ventures SEA & India",
      "speaker_photo": "/assets/image/Joshua Agusta.png",
      "company_logo": "https://drive.google.com/file/d/1f67YLNZDlSNbM_-SCfKjQf-W2gug-cxz/view?usp=drive_link"
    },
    {
      'agenda': 'Best of the Best Selection',
      "speaker_name": "Angel Lukito",
      "speaker_role": "General Manager of Exsport",
      "speaker_photo": "/assets/image/Angel Lukito.png",
      "company_logo": "https://drive.google.com/file/d/17kllABi9L4qVhczOjC87Neek0HIRrrZO/view?usp=drive_link"
    },
    {
      'agenda': 'Best of the Best Selection',
      "speaker_name": "Catherine Sharon Gasnier",
      "speaker_role": "Founder of SADA Hybrid Beauty",
      "speaker_photo": "/assets/image/Catherine Sharon Gasnier.png",
      "company_logo": "https://drive.google.com/file/d/13yfEIRXpiRzlLfqiI1wqyhUh99ZISVsy/view?usp=drive_link"
    },
    {
      'agenda': 'Best of the Best Selection',
      "speaker_name": "Darmawan Junaidi",
      "speaker_role": "Direktur Utama of Bank Mandiri",
      "speaker_photo": "/assets/image/Darmawan Junaidi 2.png",
      "company_logo": "https://drive.google.com/file/d/1braWvGjS8yDsgIsI7SDhSoTVXKNYQSj5/view?usp=drive_link"
    }
  ]


  selectedCategory: any;
  participant: any;
  isLoading: boolean;
  selected_participant: any

  mobile_participant = {
    beauty: '',
    fashion: '',
    food: '',
    tech: ''
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: BusinessIntelligenceService,
    private webService: ManualOrderService,
    private serializer: UrlSerializer,
  ) { }

  ngOnInit(): void {


  }




}



