<app-bisidebar></app-bisidebar>

<div class="width-75">
    <app-top-navigation></app-top-navigation>
    <div class="page-container">
      
       
        <!-- TABLE SAMPLE -->
        <div *ngIf="user_account" class="callout fullheight padding-center rounded medium-grey-bg elevate form margin-bottom-05 table">

            <div class="title-container">
                <div class="flex-container align-center">
                    <div class="flex-item flex-1">
                        <h2>Profile</h2>
                     
                    </div>
                    <!-- <div class="flex-item flex-1">
                        <h2>Profile</h2>
                     
                    </div> -->
            
                </div>
            </div>

            <h3>Change Password</h3>

      
          

          
        </div>

    </div>
</div>

