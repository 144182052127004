<div class="section participant-gallery">


    <div class="padding-center show-for-medium">
        
        <div class="row">
            <div class="medium-12 columns">
                <div class="cursive-title color-white">Top 40</div>

                <div class="section-title color-white margin-bottom-1">

                    Pengumuman Semifinalis Top 40
                </div>


                <div class="about-nav flex-container">
                    <div *ngFor="let item of about_nav" class="flex-item">

                        <span (click)="selectCategory(item)" [ngClass]="{active : selectedCategory == item.key}" class="link-nav">
                            {{item.title}}
                        </span>
                    </div>


                </div>

            </div>

        </div>

        <div *ngIf="isLoading" class="padding-center">
            <div class="row medium-up-5">
                <div *ngFor="let item of [].constructor(10)" class="columns">
                    <div class="image_cover loading margin-bottom-2">

                    </div>
                </div>
            </div>
        </div>
        <div class="padding-center">

            <div class="fade-in" *ngIf="selectedCategory == 'Beauty%20%26%20Wellness'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'Fashion'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'Innovative%20Food%20%26%20Beverages'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="fade-in" *ngIf="selectedCategory == 'Tech%20%26%20Tech-Enabled%20Startups'">
                <div class="row medium-up-5 margin-bottom-1">
                    <div *ngFor="let item of participant" class="columns margin-bottom-2">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>

        </div>


    </div>

    <div class="padding-center show-for-small-only">
        <div class="row">
            <div class="medium-12 columns">
                <div class="cursive-title color-white">Top 40</div>

                <div class="section-title color-white margin-bottom-1">

                    Pengumuman Top 40 Semifinalis 
                </div>


               

            </div>

        </div>

        
        <div class="padding-centers">
           <div class="callout padding-center">
            <div class="section-subtitle"> Beauty & Wellness</div>
           </div>
            <div class="fade-in">
                <div class="flex-container scroll-container">
                    <div *ngFor="let item of mobile_participant.beauty" class="flex-item scroll-item-45">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="callout padding-center">
                <div class="section-subtitle"> Fashion</div>
               </div>
            <div class="fade-in">
                <div class="flex-container scroll-container">
                    <div *ngFor="let item of mobile_participant.fashion" class="flex-item scroll-item-45">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="callout padding-center">
                <div class="section-subtitle"> Innovative Food & Beverages</div>
               </div>
            <div class="fade-in">
                <div class="flex-container scroll-container">
                    <div *ngFor="let item of mobile_participant.food" class="flex-item scroll-item-45">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
            <div class="callout padding-center">
                <div class="section-subtitle"> Tech & Tech-Enabled Startups</div>
               </div>
            <div class="fade-in">
                <div class="flex-container scroll-container">
                    <div *ngFor="let item of mobile_participant.tech" class="flex-item scroll-item-45">
                        <div (click)="selected_participant = item" class="participant-card">
                            <div class="image">
                                <img class="image_cover" [src]="item.business_photo_url?item.business_photo_url:'https://placehold.co/200'" alt="">
                            </div>
                            <div class="title">
                                {{item.company_name  | titlecase}}
                            </div>
                            <div class="subtitle">
                                {{item.fullname | titlecase}}
                            </div>

                        </div>


                    </div>
                </div>
            </div>
           
        </div>


    </div>




</div>

<app-modal (onClose)="selected_participant = null" *ngIf="selected_participant">
   
    <img class="image_cover_2 margin-bottom-2" [src]="selected_participant.business_photo_url?selected_participant.business_photo_url:'https://placehold.co/200'" alt="">

    <div class="participant-card">
      
       
        <div class="flex-container margin-bottom-2 align-center">
            <div class="flex-item flex-1">
                <div class="cursive-title color-white" style="color: #8AD31E;">Top 40</div>
                <div class="title">
                    {{selected_participant.company_name  | titlecase}}
                </div>
                <div class="subtitle">
                    {{selected_participant.fullname | titlecase}}
                </div>
            </div>
            <div class="flex-item">
                    <img class="avatar-image" [src]="selected_participant.personal_photo_url?selected_participant.personal_photo_url:'https://placehold.co/200'" alt="">
                
            </div>
        </div>

        <div class="subtitle">
            {{selected_participant.company_description_long}}
        </div>
       

    </div>
</app-modal>