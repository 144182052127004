

<app-frontend-top></app-frontend-top>

<div class="section section-home">
    





    <div class="long-fade-in">
        <div  class="padding-top-10p">
            <div class="row margin-bottom-1">
                <div class="medium-7 columns">
                    <div class="headline color-yellow">

                        <div>SAMPAI JUMPA DI WIRAUSAHA MUDA MANDIRI BERIKUTNYA
                            </div>
                        <!-- <img height="170" width="170" src="/assets/logo/2024-yellow.svg" alt="image"> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <!-- <div class="medium-4 columns margin-bottom-1">
                    <span class="section-title color-yellow">16.09.24 - 24.01.25</span>

                   
                </div> -->
                <div class="medium-8 columns margin-bottom-1">
                    <span class="section-title color-yellow">
                        Wirausaha Muda Mandiri 2024!
                    </span>


                </div>
                <div class="medium-4 columns text-right">
                    <!-- <button [routerLink]="'/registration'" *ngIf="!regis_id" class="main-button">
                        <span> Daftar wmm</span>
                    </button>
                    <button [routerLink]="'/registration'" *ngIf="regis_id" class="main-button">
                        <span>Lanjut Registrasi <fa-icon [icon]="faChevronRight"></fa-icon> </span>
                    </button> -->

                    <!-- <button class="main-button">
                        <span>  Registration WIll Open Soon</span>
                    </button>  -->

                  
                </div>
            </div>
        </div>



    </div>
</div>


<app-section-winner></app-section-winner>

<app-section-participant></app-section-participant>

<!-- 
<div #target>
    <app-section-about></app-section-about>
</div> -->


<app-section-gallery></app-section-gallery>

<app-section-mentor></app-section-mentor>
<!-- 
<app-section-timeline></app-section-timeline> -->



<app-section-media></app-section-media>

<app-section-rumah-bumn></app-section-rumah-bumn>

<div #help>
    <app-section-footer></app-section-footer>
</div>


