<div class="participant-gallery">
    <div class="slider-wrapper padding-center">
        <button class="slider-btn left" (click)="scrollLeft()" [disabled]="currentIndex === 0">
            <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button>
    
        <div class="slider-track" [style.transform]="'translateX(' + (-currentIndex * 100) + '%)'">
            <div *ngFor="let item of categories" class="slider-container">
                <div class="slider-content">
    
    
    
                    <div *ngIf="item.winner">
                        <div class="section-subtitle margin-bottom-1 text-center">
                            BEST OF THE BEST WMM 2024
                        </div>
                        <div class="row">
                            <div class="medium-9 columns medium-centered">
    
                                <div *ngFor="let item of item.items">
                                    <div class="row">
                                        <div class="medium-3 columns">
                                            <div class="participant-card text-center">
                                                <div class="image-container">
                                                    <img class="image_cover"
                                                        [src]="item.personal_photo_url ? item.personal_photo_url : 'https://placehold.co/200'"
                                                        alt="">
                                                    <div class="image">
                                                        <img class="avatar-image"
                                                            style="width: 50px;height: 50px;background-color: white;"
                                                            [src]="item.business_photo_url ? item.business_photo_url : 'https://placehold.co/200'"
                                                            alt="">
                                                    </div>
                                                </div>
                    
                    
                                                <div class="cursive-title color-white"> {{ item.rank | titlecase }} <span>{{item.rank == 1?'st':'nd'}}</span> Winner</div>
                                                <div class="title">
                                                    {{ item.fullname | titlecase }}
                                                </div>
                                                <div class="subtitle">
                                                    {{ item.company_name | titlecase }}
                                                </div>
                    
                    
                                            </div>
                                        </div>
                                        <div class="medium-9 columns">
                                            <div class="margin-left-1 padding-top-1 subtitle">
                                                {{ item.company_description_long  }}
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
    
                    </div>
                    <div *ngIf="!item.winner">
                        <div class="section-subtitle margin-bottom-1 uppercase text-center">
                            Top 3 {{item.title}}
                        </div>
                        <div class="row">
                            <div class="medium-8 columns medium-centered">
                                <div *ngIf="!item.winner" class="row medium-up-3">
                                    <div class="columns" *ngFor="let item of item.items">
                                        <div class="participant-card text-center">
                                            <div class="image-container margin-bottom-1">
                                                <img class="image_cover"
                                                    [src]="item.personal_photo_url ? item.personal_photo_url : 'https://placehold.co/200'"
                                                    alt="">
                                                <div class="image">
                                                    <img class="avatar-image"
                                                        style="width: 50px;height: 50px;background-color: white;"
                                                        [src]="item.business_photo_url ? item.business_photo_url : 'https://placehold.co/200'"
                                                        alt="">
                                                </div>
                                            </div>
    
    
                                            
                                              <div class="cursive-title color-white"> {{ item.rank | titlecase }} <span>{{item.rank == 1?'st':'nd'}}</span> Winner</div>
                                            <div class="title">
                                                {{ item.fullname | titlecase }}
                                            </div>
                                            <div class="subtitle">
                                                {{ item.company_name | titlecase }}
                                            </div>
    
    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    
        <button class="slider-btn right" (click)="scrollRight()" [disabled]="currentIndex === categories.length - 1">
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </button>
    </div>
</div>