

<div *ngIf="showInsta" style="border-bottom:1px dotted #eee ;padding-bottom: 2rem;" class="padding-center white-bg">

    <div class="row">
        <div class="medium-12 columns">
            <div class="section-title margin-bottom-1 color-black">
                JOIN OUR INSTAGRAM TO #INSPIREINNOVATEIMPACT
            </div>
        </div>
    </div>

    <div class="carosell-wrapper">
        <div [ngStyle]="{width: windowWith+'px', transform: 'translate3d(-'+carosellItemClone+'px, 0px, 0px)'}"
            class="carosell-container">

            <div style="padding: 2px;" [ngStyle]="{width: carosellItem+'px'}" class="carosell-item"
                *ngFor="let item of article; let i=index">
                <a [href]="item.subtitle">
                    <img width="200" height="300" style="width: 100%;" [src]="item.featured_image" alt="">

                </a>


            </div>
        </div>
        <div class="carosell-navigation">
            <a style=" background: white;
        color: #333;" class="carosell-navigation-left" (click)="scrollRight()">
                <fa-icon [icon]="faChevronLeft"></fa-icon>
            </a>
            <a style=" background: white;
        color: #333;" class="carosell-navigation-right" (click)="scrollLeft()">
                <fa-icon [icon]="faChevronRight"></fa-icon>
            </a>
        </div>

    </div>

</div>