<div class="fade-in" *ngIf="showModal">
    <div class="modal-container">
        <div class="zoom-in modal-content">
            <div class="modal-header">
                <div class="flex-container">
                    <div class="flex-item flex-1">
                        <h3>{{this.title}}</h3>
                    </div>
                    <div (click)="closeModal()"  class="flex-item cursor">
                        <fa-icon [icon]="faTimesCircle" class="icon"></fa-icon>
                    </div>
                </div>
                
               
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    
   
</div>