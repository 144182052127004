import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';

import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center',
  templateUrl: './learning-center.component.html',
  styleUrls: ['./learning-center.component.scss']
})
export class LearningCenterComponent implements OnInit {
  params: any;
  article: any;
  related_article: any;
  about_nav = [
    {
      'title' : 'Leadership',
      'key' :'3',
    },

    {
      'title' : 'Pitching and Presentation',
      'key' :'4',
    },

    {
      'title' : 'Marketing',
      'key' : '2',
    },
    

    {
      'title' : 'Business Strategy',
      'key' : '1',
      'is_active' : true,
    },
   
  
    {
      'title' : 'Finance',
      'key' :'5',
    },
    
   
  ]
  selectedCategory: any;

  constructor(
    private webService : ManualOrderService,
    
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
     
      this.params = this.serializer.serialize(this.router.createUrlTree([''],
        { queryParams: this.route.snapshot.queryParams}))   
        
        this.getLearningCenter('1')
        this.getRelatedLearningCenter()
     
    })
  }

  
  async getLearningCenter(cat){
    this.selectedCategory = cat
    try {
      
      let response = await this.webService.getLearningCenter('?type=post&term_id='+cat)
        this.article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }


  async getRelatedLearningCenter(){
    
    try {
      
      let response = await this.webService.getLearningCenter('?type=post')
        this.related_article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }


  _navigateTo(id) {
    this.router.navigate(
      ['/learning-center/view/'+id]
    );
  }

  selectCategory(item){
    console.log(item)
    this.selectedCategory = item.key
   this.getLearningCenter(this.selectedCategory)
  }




}
