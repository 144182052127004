<app-body>
    <div class="page-container">


        <!-- TABLE SAMPLE -->
        <div class="">
            <app-tab-option [tab_option]="tab_option"></app-tab-option>
            <div class="title-container">
                <div class="flex-container align-center">
                    <div class="flex-item flex-1">
                        <h1>User Management</h1>
                    </div>
                    <div class="flex-item flex-1 text-right">
                        <ul class="menu menu-align-right">
                            <li> <button (click)="toggleModal('registerFormModal')" class="button small">Create New
                                    Account</button></li>
                        </ul>
                    </div>
                </div>
            </div>

            <app-tab-menu [tab_menu_list]="tab_menu_list"></app-tab-menu>

            <app-table-action [sortListItems]="sortListItems"></app-table-action>
          
        
            <div class="fade-in" *ngIf="tab_view == 'account'">
                <div class="table-header">
                    <div class="flex-container table-header-item">


                        <div class="flex-item flex-2">
                            User
                        </div>



                        <div class="flex-item flex-1">
                            Role
                        </div>

                        <div class="flex-item flex-1">
                            Group
                        </div>


                        <div class="flex-item flex-1 ">
                            Menu Access
                        </div>


                        <div class="flex-item flex-05">
                            Status
                        </div>





                    </div>
                </div>

                <div *ngIf="user_account_list" class="table-body">
                    <div  *ngFor="let item of user_account_list" class="flex-container table-body-item align-center">


                        <div class="flex-item flex-2">
                            <div class="flex-container align-center">
                                <div class="flex-item">
                                    <div *ngIf="!item.profile_photo_url" class="avatar-placeholder">
                                        {{transform(item)}}
                                    </div>
                                    <img *ngIf="item.profile_photo_url" class="avatar-image"
                                        src="https://placehold.co/200x200" alt="">
                                </div>
                                <div  class="flex-item">
                                    <div>{{item.first_name}}</div>
                                    <div  *ngIf="item.role_id !== 1" class="small-text color-grey">{{item.username}}</div>
                                </div>
                            </div>
                        </div>





                        <div class="flex-item flex-1">
                            {{item.role?.role_name}}
                        </div>

                        <div class="flex-item flex-1">
                            {{item.group?.group_name}}
                        </div>


                        <div class="flex-item flex-1">
                            <div *ngIf="item.role_id !== 1">
                                <button (click)="setMenuAccess(item)" class="button tiny clear active margin-right-02">
                                    + Menu Access
                                </button>
    
                                <button (click)="changePassword(item)" class="button tiny clear active">
                                    <fa-icon [icon]="faKey"></fa-icon>
                                </button>
                            </div>
                           
                        </div>


                        <!-- <div class="flex-item flex-05">
                            <label  *ngIf="item.role_id !== 1" class="switch">
                                <input type="checkbox" [checked]="item.status" (change)="updateStatus(item)">
                                <span class="slider round"></span>
                            </label>


                        </div> -->





                    </div>

                </div>
                <app-placeholder *ngIf="!user_account_list"></app-placeholder>
            </div>

            <div class="fade-in" *ngIf="tab_view == 'group'">
                <div class="table-header">
                    <div class="flex-container table-header-item">


                        <div class="flex-item flex-2">
                            Group Name
                        </div>

                        <div class="flex-item flex-2">
                            Team Member
                        </div>





                        <div class="flex-item flex-2 text-right">

                        </div>
                    </div>
                </div>

                <div class="table-body">
                    <div *ngFor="let item of user_group" class="flex-container table-body-item">

                        <div class="flex-item flex-2">
                            {{item.group_name}}
                        </div>

                        <div class="flex-item flex-2">
                            {{item.accounts.length}}
                        </div>






                        <div class="flex-item flex-2 text-right">

                        </div>
                    </div>

                </div>
                <app-placeholder *ngIf="!user_group"></app-placeholder>
            </div>


       


        </div>

    </div>




</app-body>