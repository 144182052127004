import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { validPhoneNumber } from 'src/app/forms/validation';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss']
})
export class UserAccountComponent implements OnInit {

  tab_option = [
    {
      label : '2004',
      value : '',
      key : '',
      is_active : true,

    },
   
  ]


  sortListItems = [
      
   
    {
     
      'name' : 'tier',
      'items' :[
        {
          'name' :'nano',
          'label' :'nano'
        },
        {
          'name' :'micro',
          'label' :'micro'
        },
        {
          'name' :'macro',
          'label' :'macro',

        },
        {
          'name' :'mega',
          'label' :'mega',

        }
      ]

    }
  ]

  show: boolean;
  dataItems: any;
  isLoading: boolean;
  faKey=faKey

  registerForm = new FormGroup({
   
    email: new FormControl(null,{ 
      validators: [Validators.required, Validators.email , Validators.nullValidator]
    }),
    first_name: new FormControl(null,{ 
      validators: [Validators.required, Validators.nullValidator]
    }),
    username: new FormControl(null,{ 
      
    }),
    last_name: new FormControl(null,{ 
      validators: [Validators.required, Validators.nullValidator]
    }),
   
    role_id: new FormControl(null,{ 
      validators: [Validators.required, Validators.nullValidator]
    }),

    status: new FormControl(true),

    group_id: new FormControl(null,{ 
      validators: [Validators.required, Validators.nullValidator]
    }),

  
    password_hash: new FormControl(null,{ 
    
    }),
   
  
  })

  changePasswordForm = new FormGroup({
   
   
    password: new FormControl(null,{ 
      validators: [Validators.required , Validators.nullValidator]
    }),


    confirm_password: new FormControl(null,{ 
      validators: [Validators.required , Validators.nullValidator]
    }),
   
  
  })

  user_role_list: any;
  active_modal: any;
  menu_list: any;
  selected_user_account: any;
  page_id: string;
  tab_view: string;
  user_account_list: any;
  user_role: any;
  user_group: any;
  menu_access: any;
  selected_menu_ids: any[];
  tab_menu_list: { id: string; label: string; class: any; }[];

  
  user_account: any;

  constructor(
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private router : Router,
    private route: ActivatedRoute,
  ) { }


  
  ngOnInit(): void {
  //  console.log(this.encript('EdenGOLIVE2024.'))



   
     
     
    
    console.log(this.menu_access)
   
    

  
      
  }

 transform(item) {

      var first_name =item.first_name.charAt(0);
      var last_name =item.last_name.charAt(0);
      return first_name + last_name
  }



    


  selectItem(id){
   console.log(id)
    if(this.selected_menu_ids.includes(id)){
      this.selected_menu_ids = this.selected_menu_ids.filter(function(item) {
        return item !== id
    })
    }else{
      this.selected_menu_ids.push(id)
    }
  }

  checkMenu(id){
    if(this.selected_menu_ids.includes(id)){
      return true
    }else{
      return false
    }
  }


  validatePassword(){
    if(this.changePasswordForm.get('password').value){
      if(this.changePasswordForm.get('password').value == this.changePasswordForm.get('confirm_password').value){
        return true
      }
    }
   
  }




  
  encript(data){
    return data.split("").reduce(function(a, b) {
      a = ((a << 5) - a) + b.charCodeAt(0);
      return a & a;
    }, 0);
  }

  setComponentData(){
    this.tab_menu_list = [
      {
        'id' : 'account',
        'label' : 'Account',
       
        'class' : null,
      },
      {
        'id' : 'role',
        'label' : 'Role',
      
        'class' : null,
      },
      {
        'id' : 'group',
        'label' : 'Group',
       
        'class' : null,
      },
    
   
     
    ]
  }




  
  // async getUserAccount(){
    
  //   try {
  //     this.isLoading = true
  //     //this.appService.showLoading()
  //       let res = await this.webService.getUserAccount()
  //       console.log(res.data)
  //       console.log(this.user_account.role_id)
  //       var a = this.user_account.role_id

  //     this.user_account_list = res.data
  //       // this.user_account_list = res.data.filter(function (el) {
  //       //   return el.group_id 
  //       // });

  //       if(a !== 1){
  //         this.user_account_list = this.user_account_list.filter(function (el) {
  //           return el.role_id == a
  //         });
  //       }

        

  //       console.log(this.user_account_list)
      
    
    
  //    } catch (e) {
       
  //      this.appService.openToast(e)
   
       
  //      console.log(e)
  //    } finally {
  //     this.isLoading = false
  //      //this.appService.hideLoading()
  //    }
  
  // }




  


  // async addUserAccount(){
   
  //  this.registerForm.get('username').patchValue(this.registerForm.get('email').value)
  //  this.registerForm.get('status').patchValue(1)
  //    this.registerForm.get('password_hash').patchValue(this.encript(this.registerForm.get('password_hash').value))
    
  //  console.log(this.registerForm.value)
  //   try {
  //     this.isLoading = true
  //     this.appService.showLoading()
  //       let res = await this.webService.addUserAccount(this.registerForm.value)
  //       console.log(res.data)
  //       this.getUserAccount()
  //       this.appService.openToast(res.message_code)

      
    
    
  //    } catch (e) {
       
  //      this.appService.openToast(e)
   
       
  //      console.log(e)
  //    } finally {
  //       this.isLoading = false
  //       this.toggleModal('registerFormModal')
  //       this.registerForm.reset()
  //       this.appService.hideLoading()
  //    }
  
  // }

  _navigateToTabView(tab_view) {
    this.router.navigate(
      [],
      { queryParams: { tab_view: tab_view},
      queryParamsHandling: 'merge' }
    );
  }

  
  setMenuAccess(item){
    console.log(item)
    this.selected_user_account = item

    
    if(this.selected_user_account.menu_access){
      this.selected_menu_ids = JSON.parse(this.selected_user_account.menu_access)
    }else{
      this.selected_menu_ids=[]
    }

    if(this.user_account.id == item.id){
      this.toggleModal('setAccessMenuModal')


    }
    else if(this.user_account.role_id >= item.role_id){
      this.appService.openToast('Not Authorized')


    }else{
      this.toggleModal('setAccessMenuModal')

    }
    

  }


  changePassword(item){
    console.log(this.user_account.id)
    console.log(item.id)

    this.selected_user_account = item
    if(this.user_account.id == item.id){
      this.toggleModal('setPassword')

    }
    else if(this.user_account.role_id >= item.role_id){
      this.appService.openToast('Not Authorized')
    }else{
      this.toggleModal('setPassword')
    }
    

    
  }

  toggleModal(modal_name){
    if(this.active_modal){
      this.active_modal = false
    }
    else{
      this.active_modal = modal_name
    }
    
  }
 
}
