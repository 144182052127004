<div class="section section-gallery">


    <div class="padding-center">
        <div class="row">
            <div class="medium-5 columns">
                <div class="section-title color-yellow">

                  Gallery Rumah BUMN
                </div>

                <div class="section-para color-white margin-bottom-2">
                    Yuk intip keseruan program rumah BUMN
                </div>

            </div>
            <!-- <div class="medium-7 columns text-right">
                <button class="main-button">
                    <span>Gallery</span>
                </button>
            </div> -->
        </div>

        <div class="carosell-wrapper">
            <div [ngStyle]="{width: windowWith+'px', transform: 'translate3d(-'+carosellItemClone+'px, 0px, 0px)'}"
                class="carosell-container">

                <div style="padding: 2px;" [ngStyle]="{width: carosellItem+'px'}" class="carosell-item"
                    *ngFor="let item of banners; let i=index">
                    <img width="200" height="300" style="width: 100%;" [src]="item.img_url" alt="">


                </div>
            </div>
            <div class="carosell-navigation">
                <a style=" background: white;
            color: #333;" class="carosell-navigation-left" (click)="scrollRight()">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                </a>
                <a style=" background: white;
            color: #333;" class="carosell-navigation-right" (click)="scrollLeft()">
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </a>
            </div>

        </div>
      

        
    </div>



</div>