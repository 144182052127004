import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-frontend-top',
  templateUrl: './frontend-top.component.html',
  styleUrls: ['./frontend-top.component.scss']
})
export class FrontendTopComponent implements OnInit {
  isSticky: boolean;
  faBars=faBars
  showMenu: boolean;
  @HostListener('window:scroll', ['$event']) 
  doSomething(event) {
    this.isSticky = window.pageYOffset >= 20;


  }

  constructor(
    private router : Router,
  ) { }

  ngOnInit(): void {
  }

  toggleMenu(){
    this.showMenu = !this.showMenu
  }

  _navigateTo(id) {
    this.router.navigate(
      [id]
    );

    this.toggleMenu()
  }

}


