import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faCheckCircle, faEdit, faPen, faUserEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements OnInit {
  isEdit: boolean;
  faCheckCircle=faCheckCircle
  @Input() label : any
  @Input() disabled? : any
  @Input() placeholder? : any
  @Input() text_limit? : any
  @Input() type : any
  @Input() isProgress? : boolean
  @Input() value : any
  @Input() id : any
  @Input() isHideAction? : any
  @Input() isHideLabel? : any
  input: { label: string; value: any; id: any; };
  @Output() onClose = new EventEmitter()
  @ViewChild('search') searchElement: ElementRef;
  faEdit=faPen
  constructor() { }

  ngOnInit(): void {
    
    if(!this.type){
      this.type = 'text'
    }
    
    this.input = {
      'label' : this.label,
      'value' : this.value,
      'id' : this.id
    }
  }

  getType(){
    if(this.type == 'currency'){
      return 'number'
    }
    else{
      return this.type
    }
  }
  toggleEdit(){
    if(!this.disabled){
      this.isEdit = !this.isEdit
    }



    if(this.isEdit){
      setTimeout(()=>{ // this will make the execution after the above boolean has changed
        this.searchElement.nativeElement.focus();
      },0);  
  
    }


  }




  public numbersOnlyValidator(event: any) {
    const pattern = /^[0-9\-]*$/;
    if (!pattern.test(event.target.value)) {
      event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    }
  }

  saveData(){
    this.toggleEdit()
    this.onClose.emit(this.input)
  }
}
