import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tab-menu',
  templateUrl: './tab-menu.component.html',
  styleUrls: ['./tab-menu.component.scss']
})
export class TabMenuComponent implements OnInit {
  @Input() tab_menu_list:any
  @Output() onTab = new EventEmitter()
  tab_view: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  
    this.route.queryParamMap.subscribe(queryParams => {
      this.tab_view = queryParams.get("tab_view")
    
      if(!this.tab_view){
        this._navigateToTabView(this.tab_menu_list[0].id)
      }
    })
    
  }

  _navigateToTabView(id) {
 
    this.router.navigate(
      [],
      { queryParams: { tab_view: id},
      queryParamsHandling: 'merge' }
    );
   
  }

}
