import { Component, ElementRef, EventEmitter, Input, IterableDiffers, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {faList, faCheckCircle, faCircle, faChevronUp, faSquare, faChevronRight,faRedo,faChevronDown,faTimes, faFilter, faFileExport,faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-table-action',
  templateUrl: './table-action.component.html',
  styleUrls: ['./table-action.component.scss']
})
export class TableActionComponent implements OnInit {
  
  faChevronRight=faChevronRight
  faChevronDown=faChevronDown
  faCircle=faCircle
  faCheckCircle=faCheckCircle
  faChevronUp=faChevronUp
  faFilter=faFilter
  faTimes=faTimes
  faRedo=faRedo
  faSearch=faSearch
  faList=faList
  faSquare=faSquare
  faFileExport=faFileExport
  @Input() showExport?: boolean

  @Input() sortListItems : any
  @Output() onExport = new EventEmitter()
  @Output() onSearch = new EventEmitter()

  
  @Input() placeholder: any
  menu_id: any;

  megaMenu: boolean;
  searchKey: string;
  menu_name: any;
  selected_item: any;
  iterableDiffer: any;
date: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private iterableDiffers: IterableDiffers
  ) {
    this.iterableDiffer = iterableDiffers.find([]).create(null);
   }

  ngOnInit(): void {
    this.megaMenu = false
    this.route.queryParamMap.subscribe(queryParams => {
      this.searchKey = queryParams.get("search")
      
    })

    this._checkActiveFilter()

  }
  

  ngDoCheck() {
    let changes = this.iterableDiffer.diff(this.sortListItems);
    if (changes) {
      this._checkActiveFilter()

    }
}
  

  export(){
    this.onExport.emit(true)
  }
  _checkActiveFilter(){
    let active_params = this.route.snapshot.queryParams
    console.log(active_params)
    if(active_params){
      if(this.sortListItems){
        this.sortListItems.forEach(item => {
         console.log(active_params[item.key])
          var name = active_params[item.name]
          var key = active_params[item.key]
         
         
          if(key){
            item.selected_child = active_params[item.key]
 
           }

          else if(name){
           item.selected_child = this._getLabelByName(item.items,name)

          }
          else{
            item.selected_child = null
          }
        });
      }
    }

  }

  onChange(e){
    console.log(e)
    console.log(this.date)
  }
  _getLabelByName(items,name){
    console.log(items)
    console.log(name)
    // if(items){
      var  filteredResult = items.find((e) => e.key?e.key:e.name == name);
      if(filteredResult){
        return filteredResult.label
      }else{
        return name
      }
   
 
   
  }
  openMenu(item){
    
    this.menu_name = item.name
    this.selected_item = item

    item.show_items = this.selected_item.items
   
  }
  closeMenu(event){
   

  if (!event.currentTarget.contains(event.relatedTarget)) {
    this.selected_item = null
  }
  }

  onSearchBlur(event){
   
    setTimeout(()=>{
      this.menu_name = null
      this.selected_item = null
    },300)
   
   
  }

  searchKeyword(item,e){
    item.keyword = e
    var tem = item.items
    if(e){
      var a = 
        item.items.filter(item => item.label.toLocaleLowerCase().includes(e.toLocaleLowerCase()));
      

        item.show_items = a
      }else{
        item.show_items = tem
      }

      this.onSearch.emit(item)

      
      

    
  }

  selectChild(item,child_item){
   
    let key = item.name
    let value = child_item.name
    item.selected_child = child_item.label
   

    this.selected_item = null

    this.router.navigate(
      [],
      { queryParams: { [key] :value},
      queryParamsHandling: 'merge' }
    );


  }
test(ob){
  ob.showPicker()
  ob.type='date'
}
  selectDate(item){
    console.log(item)
    // console.log(child_item.name)
    let key = item.key
    // let value = child_item.name
     item.selected_child = item.value
    // console.log(item)

    // this.selected_item = null

    this.router.navigate(
      [],
      { queryParams: { [key] :item.value},
      queryParamsHandling: 'merge' }
    );


  }
  removeSelectedChild(item){
    
    item.selected_child = null


    if(item.key){
    var key = item.key

    }else{
    var key = item.name

    }
  

    this.router.navigate(
      [],
      { queryParams: { [key] : null},
      queryParamsHandling: 'merge' }
    );
  
  }
  clearSearch(){
    this.searchKey = null
    this.router.navigate(
      []
    );
  }

  selectMegaMenuChild(item,child_item){
    console.log(item)
    console.log(child_item)
    child_item.click = !child_item.click
  }

  search(){
   if(this.searchKey !== null){
    this.router.navigate(
      [],
      { queryParams: { search: this.searchKey},
      queryParamsHandling: 'merge' }
    );
   }else{
    this.clearSearch()
   }
   
  }


  toggleMegamenu(){
    this.megaMenu = !this.megaMenu
  }
}
