import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-section-gallery',
  templateUrl: './section-gallery.component.html',
  styleUrls: ['./section-gallery.component.scss']
})
export class SectionGalleryComponent implements OnInit {
  carosellItemClone: any;
  buffer: any;
  start: any;
  carosellItem: any;
  total_slide: number;
  banners: { img_url: string; }[];
  windowWith: number;
  faChevronLeft = faChevronLeft
  faChevronRight = faChevronRight
  alumni: any
  constructor() { }

  ngOnInit(): void {
    this.alumni = [
      {
        'brand': 'Crustea',
        'name': 'Roikhanatun Nafiah',
        'name_detail': 'Alumni WMM',
        'description': 'Event Computex Taipei pada Juni 2024 jadi ajang untuk perkenalkan inovasi teknologi di bidang perikanan yang ramah lingkungan dan Bank Mandiri jadi bagian yang selalu mendukung alumni WMM terus bertumbuh.',
        'logo': '/assets/image/crustea_logo 1.png',
        'profile_photo': '/assets/image/crustea_profile.jpg',
        'cover': '/assets/image/crustea_cover_1.png',
        'is_selected': true
      },
      {
        'brand': 'Bell',
        'name': 'Arka Irfani',
        'name_detail': 'Alumni WMM',
        'description': 'Juara WMM 2023 ini bersama Bank Mandiri perkenalkan besarnya potensi M-Tex sebagai alternatif bahan fashion yang ramah lingkungan di The Milan Furniture Fair, Italy.',
        'logo': '/assets/image/bell_logo 1.png',
        'profile_photo': '/assets/image/bell_profile.jpg',
        'cover': '/assets/image/bell_cover_1.png',
        'is_selected': false
      },

      {
        'brand': 'Medividi',
        'name': 'Sarah Istiqomah Widiaputri',
        'name_detail': 'Alumni WMM',
        'description': 'Bank Mandiri mendukung peluncuran Sister Star Sister Shine produk hasil kolaborasi alumni WMM 2023 yang mengeksplorasi ikatan sisterhood, hobi dan diubah jadi perhiasan',
        'logo': '/assets/image/medividi_logo 1.png',
        'profile_photo': '/assets/image/medividi_profile.jpg',
        'cover': '/assets/image/medividi_cover_1.png',
        'is_selected': false
      },

    ]
    this.banners = [

      {
        'img_url': '/assets/image/WMM D5-7296.jpg'
      },
      {
        'img_url': '/assets/image/WMM D6-9997.jpg'
      },


      {
        'img_url': '/assets/image/Bootcamp D2-3742.jpg'
      },
      {
        'img_url': '/assets/image/Bootcamp D3-4372.jpg'
      },





      {
        'img_url': '/assets/image/WMM D4-5503.jpg'
      },
      {
        'img_url': '/assets/image/WMM D4-5693.jpg'
      },

      {
        'img_url': '/assets/image/WMM D4-6165.jpg'
      },
      {
        'img_url': '/assets/image/WMM D5-00151.jpg'
      },
      {
        'img_url': '/assets/image/WMM D5-6530.jpg'
      },




    ]

    var h = window.innerHeight;

    console.log(window.innerWidth)
    if (window.innerWidth > 450) {
      this.carosellItem = window.innerWidth / 4.5
      this.carosellItemClone = window.innerWidth / 3.5
    } else {
      this.carosellItem = window.innerWidth / 1.5
      this.carosellItemClone = window.innerWidth / 1.5
    }






    this.total_slide = 7
    this.windowWith = (window.innerWidth) * this.total_slide;
    this.buffer = this.carosellItem * (this.total_slide / 1.5)
    this.start = this.carosellItemClone
  }


  scrollLeft() {
    //console.log(this.buffer)



    this.carosellItemClone = this.carosellItemClone + this.carosellItem
    if (this.carosellItemClone >= this.buffer) {
      this.carosellItemClone = this.start
    }
    // console.log( this.carosellItemClone)
  }


  scrollRight() {



    this.carosellItemClone = this.carosellItemClone - this.carosellItem
    if (this.carosellItemClone < this.start) {
      this.carosellItemClone = (this.carosellItem * (this.total_slide - 3)) + window.innerWidth / 4.5
    }
    //  console.log( this.carosellItemClone)
  }

  navToRight(item, i) {
    item.is_selected = false
    let next = i + 1
    let first = 0
    let last = 2


    if (next > 2) {
      next = first
    } if (next < 0) {
      next = last
    }

    console.log(next)
    this.alumni[next].is_selected = true
  }
  navToLeft(item, i) {
    item.is_selected = false
    let next = i - 1
    let first = 0
    let last = 2


    if (next > 3) {
      next = first
    } if (next < 0) {
      next = last
    }

    console.log(next)
    this.alumni[next].is_selected = true
  }

}
