

<div [ngClass]="{isSticky: isSticky}" class="top-navigation show-for-medium">
    <div class="row">
        <div class="medium-12 columns">
            <div class="flex-container align-center">
                

                <div class="flex-item margin-right-2 cursor">
                    <img routerLink="/" class="show-for-medium" height="180" width="180" src="/assets/logo/bumn-logo-white.svg"
                        alt="image">
                </div>

                <div class="flex-item flex-1">
                    <div class="main-nav flex-container">
                        <div class="flex-item">
                            <span routerLink="/" class="cursor link-nav">Tentang Wmm2024</span>
                        </div>
                        <!-- <div class="flex-item">
                            <span class="link-nav">Alumni hub</span>
                        </div> -->
                        <div class="flex-item">
                            <span routerLink="/learning-center/" class="cursor link-nav">Learning Center</span>
                        </div>
                        <!-- <div class="flex-item">
                            <span class="link-nav"  (click)="scroll(help)">Bantuan</span>
                        </div> -->
                    </div>
                </div>




                <div class="show-for-medium flex-item text-right margin-right-2">
                    <img class="margin-bottom-05" height="35" width="35" src="/assets/logo/logo_hut_mandiri.png"
                    alt="image">
                 
                </div>

                <div class="show-for-medium flex-item text-right margin-right-2">
                    <img class="margin-bottom-05" height="130" width="130" src="/assets/logo/mandiri-logo-white.svg"
                        alt="image">

                </div>
                <!-- <div *ngIf="isShowCTA" class="show-for-medium flex-item text-right">
                    <button [routerLink]="'/registration'" *ngIf="!regis_id" class="main-button">
                        <span> Daftar wmm</span>
                    </button>
                    <button [routerLink]="'/registration'" *ngIf="regis_id" class="main-button">
                        <span>Lanjut Registrasi <fa-icon [icon]="faChevronRight"></fa-icon> </span>
                    </button>
                    
                </div> -->

            </div>
        </div>
    </div>
</div>


<div  [ngClass]="{isSticky: isSticky}"  class="top-navigation   show-for-small-only">
    <div class="row">
        <div class="medium-12 columns">
            <div class="flex-container align-center">
                <!-- <div class="flex-item margin-right-1">
                   <fa-icon [icon]="faBars"></fa-icon>
                </div> -->

                <div (click)="toggleMenu()" class="flex-item margin-right-2 cursor">
                    <fa-icon [icon]="faBars"></fa-icon>
                  </div>
                <div class="flex-item margin-right-05 flex-1">
                    <img height="100" width="100" src="/assets/logo/bumn-logo-white.svg" alt="image">
                </div>
                <div class=" flex-item text-right margin-right-1">
                    <img class="margin-bottom-05" height="30" width="30" src="/assets/logo/logo_hut_mandiri.png"
                    alt="image">
                 
                </div>
                <div class="flex-item">
                    <img height="90" width="90" src="/assets/logo/mandiri-logo-white.svg"
                        alt="image">
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal *ngIf="showMenu" [isHalf]="true">
    <div class="list-tile-container">
        <div (click)="_navigateTo('/')"  class="list-tile-item">
            Home
        </div>
        <div (click)="_navigateTo('/')" class="list-tile-item">
            Terntang WMM
        </div>
        <div (click)="_navigateTo('/learning-center')" class="list-tile-item">
            Learning Center
        </div>
    </div>
</app-modal>