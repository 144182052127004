<app-body>
    <div class="page-container">

        <div class="callout">
            <div class="section-title">
                Finalist
            </div>
        </div>

        <div *ngFor="let item of participant"
            class="callout cursor padding-center-1 rounded medium-grey-bg flex-container margin-bottom-05">
            <div (click)="selectParticipant(item)" class="flex-item margin-right-1">
                <img class="avatar-image"
                    [src]="item.personal_photo_url?item.personal_photo_url:'https://placehold.co/400x400'" alt="">

            </div>
            <div (click)="selectParticipant(item)" class="flex-item flex-15">
                {{item.fullname}}
                <div class="small-text color-grey">
                    {{item.company_job_role}} at {{item.company_name}}
                </div>
            </div>
            <div (click)="selectParticipant(item)" class="flex-item flex-1">

                <div class="small-text color-grey">
                    Business
                </div>
                <div class="large-text">
                    {{item.company_category}}
                </div>
            </div>
            <div (click)="selectParticipant(item)" class="flex-item flex-1">

                <div class="small-text color-grey">
                    Rank
                </div>
                <div class="large-text">
                    <fa-icon [icon]="faTrophy"></fa-icon> {{item.rank?item.rank:'Unrank'}}
                </div>
            </div>
            <div class="flex-item flex-1 text-right">
<!-- <button (click)="remove(item)" class="button">remove</button> -->
                <app-status-label (onSelect)="setRank(item,$event)" [option]="platform_list"
                    [label]="item.rank?item.rank:'unrank'"></app-status-label>
            </div>
        </div>






    </div>

    <app-modal (onClose)="selected_participant = null" *ngIf="selected_participant"
        [title]="selected_participant.fullname + ' ' + selected_participant.id" [isBorder]="true" [isHalf]="true">
        <div topbar>

        </div>


        <div class="callout padding-center rounded medium-grey-bg margin-bottom-1">
            <h4 class="margin-bottom-1">Business Information</h4>

            <div class="flex-container">
                <div class="flex-item margin-right-1">
                    <a target="_blank" [href]="selected_participant.business_photo_url">
                    <img width="120" height="120" class="cover-photo" [src]="selected_participant.business_photo_url?selected_participant.business_photo_url:'https://placehold.co/400'" alt="">

                    </a>

                    <input class="hide" type="file" accept=".jpg,.png,.jpeg"
                    (change)="onChange($event,'business_photo_url')" #photobusiness />
                    <button (click)="photobusiness.click()" class="button tiny clear ">
                      
                        {{this.selected_participant?.business_photo_url?'Re-upload':'Upload'}}
                    </button>
                </div>

                <div class="flex-item margin-right-1">
                    <a target="_blank" [href]="selected_participant.logo_url">
                    <img width="120" height="120" class="cover-photo" [src]="selected_participant.logo_url?selected_participant.logo_url:'https://placehold.co/400'" alt="">

                    </a>

                    <input class="hide" type="file" accept=".jpg,.png,.jpeg"
                    (change)="onChange($event,'logo_url')" #logo />
                    <button (click)="logo.click()" class="button tiny clear ">
                      
                        {{this.selected_participant?.logo_url?'Re-upload':'Upload'}}
                    </button>
                </div>
                <div class="flex-item flex-1">
                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Company Name
                        </div>
                        <div class="flex-item large-text flex-2">
                          
                            <app-form-input (onClose)="updateData($event)" [value]="selected_participant.company_name" [id]="'company_name'"></app-form-input>

                        </div>
                    </div>

                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Lokasi
                        </div>
                        <div class="flex-item large-text flex-2">
                            {{selected_participant.company_province}},
                            {{selected_participant.company_city}}
                        </div>
                    </div>

                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Description
                        </div>
                        <div class="flex-item large-text flex-2">
                      

                            <app-form-input (onClose)="updateData($event)" [value]="selected_participant.company_description_long" [id]="'company_description_long'"></app-form-input>
                        </div>
                    </div>

                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Sector
                        </div>
                        <div class="flex-item large-text flex-2">
                            {{selected_participant.company_category}}
                        </div>
                    </div>

                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Tahun Didirikan
                        </div>
                        <div class="flex-item large-text flex-2">
                            {{selected_participant.company_start_year}}
                        </div>
                    </div>
                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Jumlah Karyawan
                        </div>
                        <div class="flex-item large-text flex-2">
                            {{selected_participant.company_employee_size}}
                        </div>
                    </div>
                    <div class="flex-container margin-bottom-1">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Website / Sosmed
                        </div>
                        <div class="flex-item large-text flex-2">
                            {{selected_participant.company_website}}
                        </div>
                    </div>

                </div>
            </div>





        </div>



        <div class=" margin-bottom-1">


            <div class="callout padding-center rounded medium-grey-bg margin-bottom-1">
                <h4 class="margin-bottom-1">Business Valuation</h4>
                <div class="flex-container small-text margin-bottom-1">
                    <div class="flex-item flex-1 margin-right-05">
                        <div class="small-text color-grey margin-bottom-05"> 2023 Revenue</div>
                        <div class="huge-text"> {{selected_participant.revenue_2023}} </div>
                    </div>
                    <div class="flex-item flex-1 margin-right-05">
                        <div class="small-text color-grey margin-bottom-05"> 2024 Ongoing Revenue</div>
                        <div class="huge-text"> {{selected_participant.revenue_2024}} </div>
                    </div>
                    <div class="flex-item flex-1 margin-right-05">
                        <div class="small-text color-grey margin-bottom-05"> 2024 Valuation</div>
                        <div class="huge-text"> {{selected_participant.revenue_2024_valuation}} </div>
                    </div>
                    <div class="flex-item flex-1 margin-right-05">
                        <div class="small-text color-grey margin-bottom-05"> 2025 Valuation</div>
                        <div class="huge-text"> {{selected_participant.revenue_2025_valuation}} </div>
                    </div>

                </div>

            </div>

            <div class="callout padding-center medium-grey-bg rounded margin-bottom-1">
                <h4>Product Details</h4>
                <div class="flex-container small-text color-grey margin-bottom-05">
                    <div class="flex-item flex-1 margin-right-05">
                        Nama Produk
                    </div>
                    <div class="flex-item flex-2">
                        Details
                    </div>
                </div>
                <div *ngFor="let item of selected_participant.product_details"
                    class="flex-container large-text margin-bottom-1">
                    <div class="flex-item flex-1 margin-right-05">
                        {{item.item}}
                    </div>
                    <div class="flex-item flex-2">
                        {{item.details}}
                    </div>
                </div>
            </div>

            <div class="callout padding-center rounded medium-grey-bg">
                <h4>Profit</h4>
                <div class="flex-container small-text color-grey margin-bottom-05">
                    <div class="flex-item flex-2 margin-right-05">
                        Nama Produk
                    </div>
                    <div class="flex-item flex-1">
                        Price
                    </div>
                    <div class="flex-item flex-1">
                        Margin
                    </div>
                </div>
                <div *ngFor="let item of selected_participant.product_margin"
                    class="flex-container large-text margin-bottom-1">
                    <div class="flex-item flex-2 margin-right-05">
                        {{item.item}}
                    </div>
                    <div class="flex-item flex-1">
                        {{item.price}}
                    </div>
                    <div class="flex-item flex-1">
                        {{item.margin}} %
                    </div>
                </div>
            </div>





        </div>


        <div sidebar>
            <div class="timeline-container medium-grey-bg callout padding-center">
                <h4>Personal Information</h4>

                <div class="callout">
                    <div class="flex-container">
                        <div class="flex-item margin-right-1">
                           
                            <a target="_blank" [href]="selected_participant.personal_photo_url">
                                <img width="40" height="40" class="avatar-image" [src]="selected_participant.personal_photo_url?selected_participant.personal_photo_url:'https://placehold.co/400'"
                                alt="">
                            </a>
                            <input class="hide" type="file" accept=".jpg,.png,.jpeg"
                            (change)="onChange($event,'personal_photo_url')" #photoprofile />
                            <button (click)="photoprofile.click()" class="button tiny clear ">
                                
                                {{this.selected_participant?.personal_photo_url?'Re-upload':'Upload'}}
                            </button>
    
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.fullname}}
                            <app-form-input (onClose)="updateData($event)" [value]="selected_participant.fullname" [id]="'fullname'"></app-form-input>

                            <div class="color-grey small-text">
                                {{selected_participant.company_job_role}} |
                                {{selected_participant.dob | date}} | {{selected_participant.rank}}</div>
                        </div>
                    </div>
                </div>
                <div class="list-tile-container margin-bottom-2">


                    <div class="list-tile-item flex-container">
                        <div class="flex-item large-text flex-1 color-grey margin-right-05">
                            Pitchdeck
                        </div>
                        <div class="flex-item large-text flex-1">
                            <a target="_blank" [href]="selected_participant.pitchdeck_url"
                                class="button tiny clear  hollow rounded ">
                                Download

                            </a>
                        </div>
                    </div>


                   
                    <div class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Email
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.email}}
                        </div>
                    </div>
                    <div class="list-tile-item flex-container align-center">
                        <div class="flex-item flex-1 color-grey">
                            Phone
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.phone}}
                            <button class="button tiny clear hollow margin-left-05">Chat</button>
                        </div>
                    </div>

                    <div class="list-tile-item flex-container align-center">
                        <div class="flex-item flex-1 color-grey">
                            Rekening
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.rekening_mandiri}}

                        </div>
                    </div>



                    <div class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Gender
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.gender}}

                        </div>
                    </div>

                    <div class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Founder Status
                        </div>
                        <div class="flex-item flex-1">

                            {{selected_participant.is_founder == true?'Founder':'Non founder'}}

                        </div>
                    </div>


                    <!-- <div class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Position
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.company_job_role}}


                        </div>
                    </div> -->

                    <div class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Work Status
                        </div>
                        <div class="flex-item flex-1">
                            {{selected_participant.is_fulltime == true?'Fulltime':'Partime'}}

                        </div>
                    </div>
                </div>

                <h4>Social Media</h4>
                <div class="list-tile-container">


                    <div *ngIf="selected_participant.linked_in_url" class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Linked
                        </div>
                        <div class="flex-item flex-1">

                            <a [href]="selected_participant.linked_in_url"
                                class="button tiny clear  rounded hollow margin-left-05">View</a>
                        </div>
                    </div>
                    <div *ngIf="selected_participant.linked_in_url" class="list-tile-item flex-container">
                        <div class="flex-item flex-1 color-grey">
                            Instagram
                        </div>
                        <div class="flex-item flex-1">


                            <a [href]="selected_participant.instagram_url"
                                class="button tiny clear hollow margin-left-05 rounded">View</a>
                        </div>
                    </div>

                </div>


            </div>
        </div>


    </app-modal>

</app-body>