<div class="row">
    <div class="medium-12 columns">
       <div class="padding-center">
        <div class="section-title margin-bottom-2 color-black">
            Related Content
        </div>
        <div class="row medium-up-2">
            <div  (click)="_navigateTo(item.id)" *ngFor="let item of article ; let i = index" class="columns">
                <div class="flex-container margin-bottom-2 align-center cursor color-black">
                    <div class="flex-item show-for-medium margin-right-2">
                         <img width="200px" [src]="item.featured_image" alt="">
                    </div>
                    <div class="flex-item flex-1">
                        <img style="width: 100%;margin-bottom: 1rem;" class="show-for-small-only" [src]="item.featured_image" alt="">

                        <div class="flex-container small-text align-center lato-regular margin-bottom-05">
                            <div class="flex-item margin-right-05 ">
                                <img style="height: 40px;width: 40px;" class="avatar-image" height="40" width="40"
                                    src="https://api.wirausahamudamandiri.id/data_file/1730228751_mandiri.png" alt="image">
                            </div>
                            <div class="flex-item">
                                {{item.author_id?item.author_id:'Admin'}}, {{item.created_at | date}}

                            </div>
                        </div>
                        <div class="section-subtitle margin-bottom-1">
                            {{item.title}}
                        </div>


                    </div>
                </div>
            </div>
          

        </div>
       </div>
    </div>
</div>