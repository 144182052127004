<div class="tab-option-container">
    <div class="flex-container">
        <div *ngFor="let item of tab_option" class="flex-item">
            <div (click)="selectOption(item)" [ngClass]="{active: item.is_active}" class="tab-option-button">
                {{item.label}}
            </div>
        </div>
       
    </div>
</div>

