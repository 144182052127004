import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCheck, faChevronDown, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss']
})
export class StatusLabelComponent implements OnInit {
  faChevronDown = faChevronDown
  faDotCircle=faCircle
  faCheck=faCheck
  @Input() label_list:any
  @Input() disabled?:any
  @Input() option:any
  @Input() label:any
  @Input() class:any
  @Output() onSelect = new EventEmitter()
  show_menu: boolean;
 
  constructor() { }

  ngOnInit(): void {
    if(this.label){
    this.label =  this.label.replace('_', " ");

    }
  }

  toggleStatusMenu(){
    this.show_menu = !this.show_menu
  }

  selectLabel(item){
  
    this.label = item.label
    this.class = item.class
    this.onSelect.emit(item)
  }
  closeMenu(){
    console.log('s')
    this.show_menu = false
  }
}
