<div class="section participant-gallery">


    <div class="padding-center show-for-medium">

        <div class="row">
            <div class="medium-12 columns">
                <!-- <div class="cursive-title color-white">Top 20</div> -->

                <div class="section-title color-white margin-bottom-1">

                    Finalis WMM 2024
                </div>


                <!-- <div class="about-nav flex-container">
                    <div *ngFor="let item of about_nav" class="flex-item">

                        <span (click)="selectCategory(item)" [ngClass]="{active : selectedCategory == item.key}" class="link-nav">
                            {{item.title}}
                        </span>
                    </div>


                </div> -->

            </div>

        </div>

        <div *ngIf="isLoading" class="padding-center">
            <div class="row medium-up-5">
                <div *ngFor="let item of [].constructor(10)" class="columns">
                    <div class="image_cover loading margin-bottom-2">

                    </div>
                </div>
            </div>
        </div>
        <div class="padding-center">
            <div class="row">
                <div class="medium-12 columns">
                    <div
                        *ngFor="let category of ['Beauty & Wellness', 'Fashion','Innovative Food & Beverages','Tech & Tech-Enabled Startups']">
                        <div class="section-subtitle margin-bottom-1">
                            {{category}}
                        </div>
                        <div class="row medium-up-5 margin-bottom-1">

                            <div *ngFor="let item of participant[category]" class="columns margin-bottom-2">
                                <div (click)="selected_participant = item" class="participant-card text-center">
                                    <div class="image-container">
                                        <img class="image_cover"
                                            [src]="item.personal_photo_url ? item.personal_photo_url : 'https://placehold.co/200'"
                                            alt="">
                                        <div class="image">
                                            <img class="avatar-image"
                                                style="width: 50px;height: 50px;background-color: white;"
                                                [src]="item.logo_url ? item.logo_url : 'https://placehold.co/200'"
                                                alt="">
                                        </div>
                                    </div>


                                    <!-- <div class="tiny-text">
                                        {{ item.company_category | titlecase }}
                                    </div> -->
                                    <div class="title">
                                        {{ item.fullname | titlecase }}
                                    </div>
                                    <div class="subtitle">
                                        {{ item.company_name | titlecase }}
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>


        </div>


    </div>


    <div class="padding-center show-for-small-only">

        <div class="row">
            <div class="medium-12 columns">
                <!-- <div class="cursive-title color-white">Top 20</div> -->

                <div class="section-title color-white">

                    Finalis WMM 2024
                </div>


                <!-- <div class="about-nav flex-container">
                    <div *ngFor="let item of about_nav" class="flex-item">

                        <span (click)="selectCategory(item)" [ngClass]="{active : selectedCategory == item.key}" class="link-nav">
                            {{item.title}}
                        </span>
                    </div>


                </div> -->

            </div>

        </div>


        <div class="padding-center">
            <div
                *ngFor="let category of ['Beauty & Wellness', 'Fashion','Innovative Food & Beverages','Tech & Tech-Enabled Startups']">
                <div class="callout padding-center ">
                    <div class="section-subtitle margin-bottom-05">
                        {{category}}
                    </div>
                </div>

                
                <div class="flex-container scroll-container show-for-small-only">

                    <div *ngFor="let item of participant[category]" class="flex-item"
                        style="flex: 0 0 45%; padding-left: 1rem; margin-right: 2px;">
                        <div (click)="selected_participant = item" class="participant-card text-center">
                            <div class="image-container">
                                <img class="image_cover"
                                    [src]="item.personal_photo_url ? item.personal_photo_url : 'https://placehold.co/200'"
                                    alt="">
                                <div class="image">
                                    <img class="avatar-image" style="width: 50px;height: 50px;background-color: white;"
                                        [src]="item.business_photo_url ? item.business_photo_url : 'https://placehold.co/200'"
                                        alt="">
                                </div>
                            </div>


                            <!-- <div class="tiny-text">
                                        {{ item.company_category | titlecase }}
                                    </div> -->
                            <div class="title">
                                {{ item.fullname | titlecase }}
                            </div>
                            <div class="subtitle">
                                {{ item.company_name | titlecase }}
                            </div>


                        </div>
                    </div>


                </div>
            </div>

        </div>


    </div>





</div>

<app-modal (onClose)="selected_participant = null" *ngIf="selected_participant">

    <img class="image_cover_2 margin-bottom-2"
        [src]="selected_participant.business_photo_url?selected_participant.business_photo_url:'https://placehold.co/200'"
        alt="">

    <div class="participant-card">


        <div class="flex-container margin-bottom-2 align-center">
            <div class="flex-item flex-1">
                <div class="cursive-title color-white" style="color: #8AD31E;">Top 20</div>
                <div class="title">
                    {{selected_participant.company_name  | titlecase}}
                </div>
                <div class="subtitle">
                    {{selected_participant.fullname | titlecase}}
                </div>
            </div>
            <div class="flex-item">
                <img class="avatar-image"
                    [src]="selected_participant.personal_photo_url?selected_participant.personal_photo_url:'https://placehold.co/200'"
                    alt="">

            </div>
        </div>

        <div class="subtitle">
            {{selected_participant.company_description_long}}
        </div>


    </div>
</app-modal>