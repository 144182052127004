import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class ManualOrderService {
  public userSessionSubject = new BehaviorSubject(null)
  public authorSessionSubject = new BehaviorSubject(null)

  public clientSessionSubject = new BehaviorSubject(null)

  apiURL = "https://api.wirausahamudamandiri.id/api"
  account_id: any
  customer: any
  client_id: any;
  author_id: any;
  constructor(
    private http: HttpClient
  ) { }






  //CREATOR START




  async getRegistration(query?) {

    let res = await fetch(this.apiURL + '/v1/registration' + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }


  async getLearningCenterId(id) {

    let res = await fetch(this.apiURL + '/v1/learning-center/id/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }

  async getLearningCenter(query?) {

    let res = await fetch(this.apiURL + '/v1/learning-center' + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }



  async getDashboard(id?) {

    let res = await fetch(this.apiURL + '/v1/dashboard/id/' + id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }

  async validateData(query?) {

    let res = await fetch(this.apiURL + '/v1/registration/validate' + query, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }




  async updateRegistration(id, formvalue) {

    let res = await fetch(this.apiURL + '/v1/registration/' + id + '/update', {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
      body: JSON.stringify(formvalue)
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }
  }

  async getCityList(page, name) {

    let res = await fetch('https://wia.id/wia-module/api/getcity/page/' + page + '/id/' + name, {
      method: "GET"
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }

  }


  async updateLearningCenter(formvalue, id) {



    let res = await fetch(this.apiURL + '/v1/learning-center/' + id + '/update', {
      method: "PATCH",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },

      body: JSON.stringify(formvalue)
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }


  }


  async addLearningCenter(formvalue) {



    let res = await fetch(this.apiURL + '/v1/learning-center/add', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },

      body: JSON.stringify(formvalue)
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }


  }


  async deleteLearningCenter(id) {



    let res = await fetch(this.apiURL + '/v1/learning-center/id/' + id + '/delete', {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },


    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }


  }





  async addRegistration(formvalue) {



    let res = await fetch(this.apiURL + '/v1/registration/add', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },

      body: JSON.stringify(formvalue)
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }


  }



  async getLoginSession() {


    let session = localStorage.getItem("device_session");
    let form = {
      'id': session
    }
    let res = await fetch(this.apiURL + '/v1/user/session/', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },
      body: JSON.stringify(form)


    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }


  }



  async userLogin(formvalue) {



    let res = await fetch(this.apiURL + '/v1/user/login', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },

      body: JSON.stringify(formvalue)
    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      localStorage.setItem("device_session", data.data.password_hash);
      return data
    }


  }




  async getRegistrationId(id) {

    let res = await fetch(this.apiURL + '/v1/registration/id/' + id, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer xxxwmm2024xxx',
      },

    })
    let data = await res.json()
    if (data.status_code != 200) throw data.message_code
    else {
      return data
    }
  }

  async uploadPhoto(formvalue, id) {




    let res = await fetch('https://api.wirausahamudamandiri.id/api/v1/upload/' + id, {
      method: "POST",
      headers: {

        'Authorization': 'Bearer xxxwmm2024xxx',
      },
      body: formvalue //JSON.stringify(formvalue)

    })

    let data = await res.json()
    return data

  }







}
