// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIBaseURL:'https://wia.id/wia-module',
  firebaseConf:{
    apiKey: "AIzaSyAf7EgVletnUxvVbQcmVASMl3NF0pQ1yJQ",
    authDomain: "wia-experience.firebaseapp.com",
    databaseURL: "https://wia-experience.firebaseio.com",
    projectId: "wia-experience",
    storageBucket: "wia-experience.appspot.com",
    messagingSenderId: "868476634905",
    appId: "1:868476634905:web:c716564a6a06190204a788"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
