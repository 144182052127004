<div tabindex="-1" (blur)="onBlur($event)"  class="form relative">
   
   <div [ngClass]="{'input' : !isClean}" class=" margin-bottom-0">
    <div (click)="toggleOption()" class="flex-container align-center cursor">
        <div class="flex-item flex-1 margin-right-05">
            
            <div *ngIf="selected_option">
                {{selected_option | titlecase}}
            </div>
            <div class="color-grey" *ngIf="!selected_option">
                {{'Select ' + label}}
            </div>
           
        </div>
        <div class="flex-item small-text">
            <button class="button clear tiny">
                <fa-icon [icon]="faChevronDown"></fa-icon>
            </button>
        
        </div>
    </div>
   </div>
  

    <div *ngIf="showOption" class="option-container  elevate form fade-in">
       <div class="search-bar border-bottom">
        <app-form-search  (onBlur)="onSearchBlur($event)"  (onSearch)="search($event)"></app-form-search>

       </div> 
        <div  class="search-container">
          
            <div *ngIf="option" class="list-tile-container">
                <div (click)="selectOption(item)" *ngFor="let item of option" class="list-tile-item cursor">
    
                    {{item.label | titlecase}}
                </div>
              
            </div>
        </div>
      
    </div>
</div>