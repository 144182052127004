import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule} from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BISidebarComponent } from './component/shareModule/bisidebar/bisidebar.component';
import { LoadingComponentComponent } from './component/shareModule/loading-component/loading-component.component';
import { ModalComponent } from './component/shareModule/modal/modal.component';
import { ProgressComponentComponent } from './component/shareModule/progress-component/progress-component.component';
import { ToastComponentComponent } from './component/shareModule/toast-component/toast-component.component';
import { TopNavigationComponent } from './component/shareModule/top-navigation/top-navigation.component';
import { LoginComponent } from './component/userModule/login/login.component';
import { ProfileComponent } from './component/userModule/profile/profile.component';
import { UserAccountComponent } from './component/userModule/user-account/user-account.component';
import { BodyComponent } from './component/shareModule/body/body.component';
import { BodyFullComponent } from './component/shareModule/body-full/body-full.component';
import { PlaceholderComponent } from './component/shareModule/placeholder/placeholder.component';
import { TableActionComponent } from './component/shareModule/table-action/table-action.component';
import { ModalFullComponent } from './component/shareModule/modal-full/modal-full.component';
import { ModalSheetsComponent } from './component/shareModule/modal-sheets/modal-sheets.component';
import { StatusLabelComponent } from './component/shareModule/status-label/status-label.component';
import { TabMenuComponent } from './component/shareModule/tab-menu/tab-menu.component';
import { FormInputComponent } from './component/shareModule/form-input/form-input.component';
import { FormSelectComponent } from './component/shareModule/form-select/form-select.component';
import { FormSearchComponent } from './component/shareModule/form-search/form-search.component';
import { StatusLabelPerformanceComponent } from './component/shareModule/status-label-performance/status-label-performance.component';
import { FormMultipleSelectComponent } from './component/shareModule/form-multiple-select/form-multiple-select.component';
import { TableSortComponent } from './component/shareModule/table-sort/table-sort.component';
import { TooltipsComponent } from './component/shareModule/tooltips/tooltips.component';
import { CardComponent } from './component/shareModule/card/card.component';
import { LazyLoadDirective } from './component/shareModule/lazy-load.directive';
import { ConfirmDialogComponent } from './component/shareModule/confirm-dialog/confirm-dialog.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { TabOptionComponent } from './component/shareModule/tab-option/tab-option.component';
import { HomepageComponent } from './frontend/homepage/homepage.component';
import { OnboardComponent } from './frontend/onboard/onboard.component';
import { SectionAboutComponent } from './frontend/homepage/section-about/section-about.component';
import { SectionGalleryComponent } from './frontend/homepage/section-gallery/section-gallery.component';
import { SectionTimelineComponent } from './frontend/homepage/section-timeline/section-timeline.component';
import { SectionFooterComponent } from './frontend/homepage/section-footer/section-footer.component';
import { SectionParticipantComponent } from './frontend/homepage/section-participant/section-participant.component';
import { SectionMediaComponent } from './frontend/homepage/section-media/section-media.component';
import { SectionFormComponent } from './frontend/homepage/section-form/section-form.component';
import { SeparatorDirective } from './component/shareModule/separator.directive';
import { ParticipantComponent } from './component/dashboard/participant/participant.component';
import { RegistrationComponent } from './component/dashboard/registration/registration.component';
import { LearningCenterComponent } from './frontend/learning-center/learning-center.component';
import { AlumniHubComponent } from './frontend/alumni-hub/alumni-hub.component';
import { FrontendTopComponent } from './frontend/homepage/frontend-top/frontend-top.component';
import { LearningCenterViewComponent } from './frontend/learning-center/learning-center-view/learning-center-view.component';
import { LearningCenterRelatedComponent } from './frontend/learning-center/learning-center-related/learning-center-related.component';
import { LearningCenterBackendComponent } from './component/dashboard/learning-center-backend/learning-center-backend.component';
import { LearningCenterBackendViewComponent } from './component/dashboard/learning-center-backend/learning-center-backend-view/learning-center-backend-view.component';
import { LearningCenterInstagramComponent } from './frontend/learning-center/learning-center-instagram/learning-center-instagram.component';
import { SectionMentorComponent } from './frontend/homepage/section-mentor/section-mentor.component';
import { SectionRumahBumnComponent } from './frontend/homepage/section-rumah-bumn/section-rumah-bumn.component';
import { SectionWinnerComponent } from './frontend/homepage/section-winner/section-winner.component';

@NgModule({
  declarations: [
    AppComponent,
    BISidebarComponent,
    TopNavigationComponent,
    ModalComponent,
    ProgressComponentComponent,
    ToastComponentComponent,
    LoadingComponentComponent,
    ProfileComponent,
    LoginComponent,
    UserAccountComponent,
    BodyComponent,
    BodyFullComponent,
    PlaceholderComponent,
    TableActionComponent,
    ModalFullComponent,
    ModalSheetsComponent,
    StatusLabelComponent,
    TabMenuComponent,
    FormInputComponent,
    FormSelectComponent,
    FormSearchComponent,
    StatusLabelPerformanceComponent,
    FormMultipleSelectComponent,
    TableSortComponent,
    TooltipsComponent,
    CardComponent,
    LazyLoadDirective,
    ConfirmDialogComponent,
    DashboardComponent,
    TabOptionComponent,
    HomepageComponent,
    OnboardComponent,
    SectionAboutComponent,
    SectionGalleryComponent,
    SectionTimelineComponent,
    SectionFooterComponent,
    SectionParticipantComponent,
    SectionMediaComponent,
    SectionFormComponent,
    SeparatorDirective,
    ParticipantComponent,
    RegistrationComponent,
    LearningCenterComponent,
    AlumniHubComponent,
    FrontendTopComponent,
    LearningCenterViewComponent,
    LearningCenterRelatedComponent,
    LearningCenterBackendComponent,
    LearningCenterBackendViewComponent,
    LearningCenterInstagramComponent,
    SectionMentorComponent,
    SectionRumahBumnComponent,
    SectionWinnerComponent
    
  

  ],

  imports: [
    AngularEditorModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,

    
  ],
  providers: [
   
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
