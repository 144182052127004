import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal-sheets',
  templateUrl: './modal-sheets.component.html',
  styleUrls: ['./modal-sheets.component.scss']
})
export class ModalSheetsComponent implements OnInit {
  showModal: boolean;
  @Input() title: any
  @Output() onClose = new EventEmitter()
  faTimesCircle=faTimes
  constructor() { }

  ngOnInit(): void {
    this.showModal = true
  }
  closeModal(){
    console.log('click log')
    this.showModal = false
    this.onClose.emit(false)
  }
}
