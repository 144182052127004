<div class=" section-form">

    <div class="row collapse">
        <div class="medium-5 columns long-fade-in">

            <div  [formGroup]="loginForm" class="callout white fullheight padding-center  medium-grey-bg   form">

                <div class="padding-middles">
                    <div class="login">
                        <div class="section-title margin-bottom-1">
                            DASHBOARD
                        </div>
                        <form class="form margin-bottom-2" [formGroup]="loginForm">
                           <div class="large-text">
                            Email
                           </div>
                            <input formControlName="email" type="text" name="" id="">



                            <div class="large-text">
                                Password
                               </div>
                            <input formControlName="password_hash" type="password">


                        </form>

                        <button [disabled]="!loginForm.valid" (click)="loginAction()"
                            class="button expanded">Login</button>



                    </div>

                </div>
            </div>



        </div>
        <div class="medium-7 show-for-medium columns long-fade-in">

            <div class="padding-middles">
                <div class="headline color-yellow">

                    <div>WIRAUSAHA<br>
                        MUDA<br>
                        MANDIRI</div>
                    <img height="130" width="130" src="/assets/logo/2024-yellow.svg" alt="image">
                </div>

            </div>


        </div>

      
    </div>
</div>