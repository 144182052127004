import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';

import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center-instagram',
  templateUrl: './learning-center-instagram.component.html',
  styleUrls: ['./learning-center-instagram.component.scss']
})
export class LearningCenterInstagramComponent implements OnInit {
  article: any;

  constructor(
    private webService : ManualOrderService,
    
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.getLearningCenter()
  }

  async getLearningCenter(){
    
    try {
      
      let response = await this.webService.getLearningCenter('?type=instagram')
        this.article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }

}
