import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { faPoll, faPollH, faTags, faTrophy, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';


@Injectable({
  providedIn: 'root'
})

export class BusinessIntelligenceService {
  apiURL = "https://wia.id/wia-module/Api/"


  public loadingToastSubject = new BehaviorSubject(null)

  dialogEvent: EventEmitter<any> = new EventEmitter()
  toastEvent: EventEmitter<any> = new EventEmitter()
  navigation_menu: any
  persona: { id: number; label: string; name: string; }[];


  constructor(
    private http: HttpClient,
    private router: Router,
  ) { 
    
    this.navigation_menu = [
     
      {
        "id": "0",
        "menu_id": "0",
        "title": "Dashboard",
        "navigation": "/dashboard",
        "icon": faPoll,
        "global_nav": true,
        "has_child": false,
      },
      // {
      //   "id": "1",
      //   "menu_id": "1",
      //   "title": "User Management",
      //   "navigation": "/dashboard/user-account",
      //   "icon": "faUsers",
      //   "global_nav": true,
      //   "has_child": false,
        
      // },

      {
        "id": "1",
        "menu_id": "1",
        "title": "Registration",
        "navigation": "/dashboard/registration",
        "icon": faTags,
        "global_nav": true,
        "has_child": false,
        
      },

      {
        "id": "1",
        "menu_id": "1",
        "title": "Finalist",
        "navigation": "/dashboard/finalist",
        "icon": faTrophy,
        "global_nav": true,
        "has_child": false,
        
      },
     
      // {
      //   "id": "2",
      //   "menu_id": "2",
      //   "title": "Creator",
      //   "navigation": "/creator/",
      //   "icon": "faUserCircle",
      //   "has_child": true,
      //   "child": [
      //     {
      //       "id": "8",
      //       "menu_id": "8",
      //       "title": "Creator",
      //       "navigation": "/creator/creator",
      //       "icon": "title",
      //       "has_child": false
      //     },
      //     {
      //       "id": "6",
      //       "menu_id": "6",
      //       "title": "Campaign",
      //       "navigation": "/creator/campaign",
      //       "icon": "title",
      //       "has_child": false
      //     },
        
          
      //     {
      //       "id": "9",
      //       "menu_id": "9",
      //       "title": "Contract",
      //       "navigation": "/creator/contract",
      //       "icon": "title",
      //       "has_child": false
      //     }
      //   ]
      // },
     
     
    ]
  }

  //Auth

  validateMenuAccess(PAGE_ID){
    var user_session = JSON.parse(localStorage.getItem("user_session"));
    if(user_session){
      return true
    }else{
      return false
    }
    
  }


  async showLoading(isBar?: boolean){
    this.loadingToastSubject.next(true)
  }

  async hideLoading(isBar?: boolean){
    this.loadingToastSubject.next(false)
  }

  

  openDialog(title,message:string, callbackButton?: string, callbackFn?: VoidFunction){
    let data = {
      'title' : title,
     'message' : message,
     'callbackButton' : callbackButton,
     'callbackFn' : callbackFn,
    }
   
    this.dialogEvent.emit(data)
    
  }

  
  openToast(message:string, callbackButton?: string, callbackFn?: VoidFunction){
    let data = {
     'message' : message,
     'callbackButton' : callbackButton,
     'callbackFn' : callbackFn,
    }
   
    this.toastEvent.emit(data)
    
  }













}
