<div style="border-bottom:1px dotted #eee ;padding-bottom: 2rem;" class="padding-center white-bg">

    <div class="row">
        <div class="medium-12 columns">
            <div class="section-title margin-bottom-1 color-black">
                JOIN OUR INSTAGRAM TO #INSPIREINNOVATEIMPACT
            </div>
        </div>
    </div>
    <div class="row small-up-2 medium-up-5">
        <div *ngFor="let item of article" class="columns">
            <div class="flex-containers margin-bottom-2 align-center cursor color-black">

                <div class="flex-item flex-1s">
                    <a [href]="item.subtitle">
                        <img class="cover-image margin-bottom-05" [src]="item.featured_image" alt="">
                    </a>


                    <!-- <div class="section-subtitle margin-bottom-1">

                        {{item.title | titlecase}}


                    </div> -->


                    <a [href]="item.subtitle" style="color: #666 !important;" class="small-text lato-regular">
                        Read on Instagram

                    </a>
                </div>
            </div>
        </div>
    </div>
</div>