import { Component, EventEmitter, Input, OnInit, Output, RendererFactory2 } from '@angular/core';
import { faExpandAlt, faTimes, faWindowMinimize } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  faExpandAlt=faExpandAlt
  faWindowMinimize=faWindowMinimize
  faTimes=faTimes
  @Input() title: any
  @Input() is_clear: any
  @Input() is_add: any
  @Input() is_expand: any
  @Input() is_half: any
  @Input() is_fullheigth: any
  @Output() onAdd = new EventEmitter()
  
  is_expanded: any;
  renderer: any;


  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
   }

  ngOnInit(): void {
  }

  toggleExpand(){
    this.is_expanded = !this.is_expanded
    console.log(this.is_expanded)

    if(this.is_expanded){
    this.renderer.setAttribute(document.body,'class', 'no-scroll');

    }
    else{
      this.renderer.removeAttribute(document.body, 'class')

    }
      }

}
