import {
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  EmailValidator,
  AbstractControl
} from '@angular/forms';
import {
  ActivatedRoute,
  Router,
  UrlSerializer
} from '@angular/router';
import {
  faBookmark,
  faBuilding,
  faCalendarAlt,
  faCalendarWeek,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircle,
  faFile,
  faFilePdf,
  faGlobe,
  faInfoCircle,
  faLocationArrow,
  faSign,
  faTimesCircle,
  faTrashAlt,
  faUpload,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
  formError,
  validPhoneNumber
} from 'src/app/forms/validation';

import {
  BusinessIntelligenceService
} from 'src/app/services/business-intelligence/business-intelligence.service';
import {
  ManualOrderService
} from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.scss']
})
export class SectionFormComponent implements OnInit {
  faTrashAlt = faTrashAlt
  faChevronLeft = faChevronLeft
  faUpload = faUpload
  faInfoCircle = faInfoCircle
  faCheckCircle = faCheckCircle
  faCircle = faCircle
  faFilePdf = faFilePdf
  faChevronRight = faChevronRight
  faGlobe = faGlobe
  faBuilding = faBuilding
  faLocationArrow = faLocationArrow
  faSign = faSign
  faBookmark = faBookmark
  faFile = faFile
  faTimesCircle=faTimesCircle


  faCalendarWeek = faCalendarAlt
  faUsers = faUsers
  menu = [{
      'title': 'Personal Information',
      'step': 1,
      'time': '< 1 min'
    },
    {
      'title': 'Business Information',
      'step': 2,
      'time': '1 - 2 min'
    },
    {
      'title': 'Business Metric',
      'step': 3,
      'time': '2 - 3 min'
    },
    {
      'title': 'Data Upload & Confirmation',
      'step': 4,
      'time': '< 1 min'
    }
  ]

  gender = [

    {
      value: 'Pria',
      label: 'Pria',
      selected: null
    },
    {
      value: 'Wanita',
      label: 'Wanita',
      selected: null

    },

  ]

  is_founder = [

    {
      value: 1,
      label: 'Iya'
    },
    {
      value: 0,
      label: 'Tidak',

    },




  ]



  is_fulltime = [

    {
      value: 1,
      label: 'Iya'
    },
    {
      value: 0,
      label: 'Tidak, saya bekerja part-time',

    },




  ]


  revenue_model = [

    {
      value: 'Penjualan Langsung',
      label: 'Penjualan Langsung'
    },
    {
      value: 'Langganan',
      label: 'Langganan'
    },
    {
      value: 'Iklan',
      label: 'Iklan'
    },
    {
      value: 'Komisi',
      label: 'Komisi'
    },
    {
      value: 'Lainnya',
      label: 'Lainnya'
    },




  ]


  company_job_role = [

    {
      value: 'CEO',
      label: 'CEO'
    },
    {
      value: 'COO',
      label: 'COO'
    },

    {
      value: 'CTO',
      label: 'CTO'
    },
    {
      value: 'CFO',
      label: 'CFO'
    },
    {
      value: 'CMO',
      label: 'CMO'
    },
    {
      value: 'CCO',
      label: 'CCO'
    },
    {
      value: 'CPO',
      label: 'CPO'
    },

    {
      value: 'Lainnya',
      label: 'Lainnya'
    },




  ]


  company_category = [

    {
      value: 'Beauty & Wellness',
      label: 'Beauty & Wellness',
      'subtitle' : 'Produk-produk yang berfungsi untuk perawatan diri dan menjaga kecantikan, serta membuat rutinitas perawatan diri harian menjadi menyenangkan dan efektif.',

      'items' : [
        {
          'title' : 'Skincare',
          'subtitle' : 'Cleanser, Mist, Lotion, Serum'
        },
        {
          'title' : 'Body Care',
          'subtitle' : 'Body Lotion, Body Scrub, Body Oil'
        },
        {
          'title' : 'Hair Care',
          'subtitle' : 'Shampoo, Conditioner, Hair Treatments'
        },
        {
          'title' : 'Cosmetics',
          'subtitle' : 'Makeup, Skincare-Makeup Hybrid Products'
        },
        {
          'title' : 'Scents',
          'subtitle' : 'Perfume, Essential Oil, Diffuser, Scented Candle, Linen Spray, Room Spray'
        },
        {
          'title' : 'Health & Wellbeing',
          'subtitle' : 'Fitness Centers, Health and Wellness Clubs, Meditation Centers, Beauty/hair/skin clinics'
        }
      ]
    },
    {
      value: 'Fashion',
      label: 'Fashion',
      'subtitle' : 'Pilihan pakaian dan aksesori yang modis dengan gaya terkini hingga desain klasik yang cocok untuk berbagai kesempatan, serta teknologi produksi fashion.',

      'items' : [
        {
          'title' : 'Apparel',
          'subtitle' : 'Everyday Wear, Formal Wear, Activewear, Underwear'
        },
        {
          'title' : 'Accessories',
          'subtitle' : 'Jewelry, Bags, Footwear, Hats, Belts, Scarves'
        },
        {
          'title' : 'Footwear',
          'subtitle' : 'Casual Shoes, Formal Shoes, Sports Shoes'
        },
        {
          'title' : 'Fashion Manufacturing',
          'subtitle' : 'Textile Production, Raw Material Sourcing, Bespoke Tailoring'
        },
       
      ]

    },
    {
      value: 'Innovative Food & Beverages',
      label: 'Innovative Food & Beverages',
      'subtitle' : 'Produk makanan dan minuman yang unik dan kreatif, menyajikan rasa dan pengalaman baru yang menggugah selera dan memanjakan lidah.',

      'items' : [
        {
          'title' : 'Innovative Foods',
          'subtitle' : 'New Food Concepts, Artisanal Products, Healthy Foods'
        },
        {
          'title' : 'Beverages',
          'subtitle' : 'Unique Drinks, Craft Beverages, Functional Drinks (e.g. health-boosting drinks)'
        },
        {
          'title' : 'Snacks',
          'subtitle' : 'Unique or Innovative Snack Products'
        },
       
       
      ]

    },
    {
      value: 'Tech & Tech-Enabled Startups',
      label: 'Tech & Tech-Enabled Startups',
      'subtitle' : 'Inovasi teknologi yang mengubah cara kita bekerja dan hidup, berfokus pada solusi pintar dan alat digital yang memudahkan kehidupan sehari-hari.',

      'items' : [
        {
          'title' : 'Agritech',
          'subtitle' : 'Agriculture, Farming, Aquatech Innovations'
        },
        {
          'title' : 'Edutech',
          'subtitle' : 'Educational Tools, Learning Platforms'
        },
        {
          'title' : 'Logistic-Tech',
          'subtitle' : 'Supply Chain and Delivery Innovations, Inventory Management'
        },

        {
          'title' : 'Enterprise Software and Solutions',
          'subtitle' : 'Business Management Tools, Productivity Software'
        },

        {
          'title' : 'Healthtech',
          'subtitle' : 'Healthcare Innovations, Telemedicine Solutions'
        },

        {
          'title' : 'Smart City',
          'subtitle' : 'Urban Solutions, Smart Infrastructure'
        },

        {
          'title' : 'Climate-Tech',
          'subtitle' : 'Environmental Solutions, Sustainability Technologies'
        },
        {
          'title' : 'Financial Services',
          'subtitle' : 'Fintech Innovations, Digital Payment Solutions'
        },
       
       
      ]

    },



  ]
  showPhoneNumber:any

  company_step = [

    {
      value: 'idea',
      label: 'Masih berupa ide',
      desc : 'Bisnis Anda masih dalam tahap pengembangan ide dan belum dilaksanakan secara nyata'
    },
    {
      value: 'revenue',
      label: 'Sudah menghasilkan penjualan',
      desc : 'Bisnis Anda sudah beroperasi dan telah menghasilkan penjualan'
    },
    {
      value: 'profit',
      label: 'Sudah menghasilkan keuntungan',
      desc : 'Bisnis Anda sudah beroperasi dengan baik dan telah menghasilkan keuntungan'

    },
   


  ]


  survey = [

    {
      value: 'Bank Mandiri Instagram',
      label: 'Bank Mandiri Instagram',
    },
    {
      value: 'Bank Mandiri LinkedIn',
      label: 'Bank Mandiri LinkedIn',
    },
    {
      value: 'Bank Mandiri Network',
      label: 'Bank Mandiri Network',
    },
    {
      value: 'Endeavor Indonesia LinkedIn',
      label: 'Endeavor Indonesia LinkedIn',
    },
    {
      value: 'Endeavor Indonesia Network',
      label: 'Endeavor Indonesia Network',
    },
    {
      value: 'Program Community Partner',
      label: 'Program Community Partner',
    },
    {
      value: 'Website',
      label: 'Website',
    },
    {
      value: 'Referal',
      label: 'Referal (Kolega, Teman, Dll)',
    },
    {
      value: 'Lainnya',
      label: 'Lainnya',
    },
    
   


  ]




  step: string;
  regis_id: any;



  personalInformationForm = new FormGroup({
    fullname: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator],
       updateOn: 'blur'
    }),
    dob: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    email: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    phone: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    gender: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    longexperience: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),


    linked_in_url: new FormControl(null),
    instagram_url: new FormControl(null),
    is_complete_step_1: new FormControl(null),



  })

  dataUploadForm = new FormGroup({

    rekening_mandiri: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator, Validators.minLength(10) , validPhoneNumber]
    }),
    pitchdeck_url: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator, ]
    }),
    survey: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator, ]
    }),

    business_photo_url: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator, ]
    }),

    personal_photo_url: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator, ]
    }),
    

    is_confirm: new FormControl(null),
    status: new FormControl(null),
    is_complete_step_4: new FormControl(null)

  })


  businessInformationForm = new FormGroup({
    company_name: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_description_long: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_start_year: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_employee_size: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_website: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_province: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_city: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    company_category: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    is_founder: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),

    company_job_role: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),

    company_step: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),

    is_fulltime: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    is_complete_step_2: new FormControl(null),




  })


  businessMetricForm = new FormGroup({
    revenue_model: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    product_details_long: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    product_margin_long: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    revenue_2023: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    revenue_2024: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    revenue_2024_valuation: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    revenue_2025_valuation: new FormControl(null, {
      validators: [Validators.required, Validators.nullValidator]
    }),
    is_complete_step_3: new FormControl(null),




  })

  product_details_long = [{
      'item': '',
      'details': ''
    },

  ]
  product_margin_long = [{
    'item': '',
    'price': '',
    'margin': ''
  }]
  registration: any;
  city_list: any[];
  is_email_valid: boolean;
  is_registered: boolean;
  loginModal: any;
  registered_email: any;
  registered_phone: any;
  session_email: string;

  @ViewChild('myname') input; 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: BusinessIntelligenceService,
    private webService: ManualOrderService,
    private serializer: UrlSerializer,
  ) {}

  ngOnInit(): void {




    this.route.queryParamMap.subscribe(queryParams => {
      this.is_email_valid = true
      this.regis_id = localStorage.getItem("session_id");
      this.session_email = localStorage.getItem("session_email");

      window.scroll({ 
        top: 0, 
        left: 0, 
      
      });

      this.step = queryParams.get("step")
      if (!this.step) {
        this.step = '1'
      }
      if (this.regis_id) {
        this.getRegistrationId(this.regis_id)
      }
      if (this.step) {
        if (!this.regis_id) {
          this.navTo(1)
        }
      }



    })

  }

  async validateRekening() {
    return true
  }


  async validateEmail(e) {
    let email = e.target.value
    let query = '?email=' + email

    let validate_format = email.toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )

    if (email.length > 0) {
      if (validate_format) {

        try {

          let response = await this.webService.validateData(query)
          // console.log(response)
          if (response.data) {
            this.is_email_valid = false
            this.is_registered = true
            this.registered_email = e.target.value
            e.target.value = null
            this.appService.openToast('Email sudah terdaftar')

          } else {
            this.is_email_valid = true
           
            this.personalInformationForm.get('email').setValue(e.target.value)
          }

        } catch (e) {

          // this.appService.openToast(e)

          console.log(e)
        } finally {


        }
      } else {
        this.is_email_valid = false
        this.appService.openToast('Format email salah')
      }
    }


  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  async resumeForm(){
   
    let query = '?phone='+this.registered_phone+'&email='+this.registered_email
    try {

      let response = await this.webService.validateData(query)
    
      if (response.data) {
        localStorage.setItem("session_id", response.data.id);
        localStorage.setItem("session_email", response.data.email);

        this.regis_id = response.data.id
        this.session_email = response.data.email


        this.getRegistrationId(response.data.id)
        this.registered_email = null
        this.registered_phone = null
        this.is_registered = false
        this.appService.openToast('Konfirmasi berhasil, silahkan melanjutkan pengisian data Anda')
        
      } else {
        this.appService.openToast('Nomor handphone tidak sesuai')
        
      }

    } catch (e) {

      this.appService.openToast(e)

      console.log(e)
    } finally {


    }

  }






  addMoreProduct(product_details) {
    product_details.push({
      'item': '',
      'details': ''
    }, )
  }
  addMoreMargin(product_details) {
    product_details.push({
      'item': '',
      'price': '',
      'margin': ''
    })
  }
  async getRegistrationId(id) {



    try {
    
      let response = await this.webService.getRegistrationId(id)
       console.log(response.data)
       
      if (response.data) {
        if(this.session_email == response.data?.email){
          this.registration = response.data
          if (this.registration.status == true) {
            this.navTo(5)
          }
          this._validatePage()
  
  
  
  
          this.personalInformationForm.patchValue({
            email: response.data.email,
            fullname: response.data.fullname,
            phone: response.data.phone,
            gender: response.data.gender,
            longexperience: response.data.longexperience,
            dob: response.data.dob,
            linked_in_url: response.data.linked_in_url,
            instagram_url: response.data.instagram_url,
          });
  
          this.businessInformationForm.patchValue({
            company_name: response.data.company_name,
            company_description_long: response.data.company_description_long,
            company_start_year: response.data.company_start_year,
            company_employee_size: response.data.company_employee_size,
            company_website: response.data.company_website,
            company_location: response.data.company_location,
            company_category: response.data.company_category,
  
            company_city: response.data.company_city,
            company_province: response.data.company_province,
  
            is_founder: response.data.is_founder,
            company_job_role: response.data.company_job_role,
            is_fulltime: response.data.is_fulltime,
  
            company_step: response.data.company_step,
  
  
          });
  
          this.dataUploadForm.patchValue({
            pitchdeck_url: response.data.pitchdeck_url,
            business_photo_url: response.data.business_photo_url,
            personal_photo_url: response.data.personal_photo_url,
            rekening_mandiri: response.data.rekening_mandiri,
            survey: response.data.survey,
  
          });
  
          this.businessMetricForm.patchValue({
            product_details_long: response.data.product_details_long,
            product_margin_long: response.data.product_margin_long,
            revenue_model: response.data.revenue_model,
            revenue_2023: response.data.revenue_2023,
            revenue_2024: response.data.revenue_2024,
            revenue_2024_valuation: response.data.revenue_2024_valuation,
            revenue_2025_valuation: response.data.revenue_2025_valuation,
          });
  
          if (this.registration.product_details_long) {
            this.product_details_long = JSON.parse(this.registration.product_details_long)
          }
          if (this.registration.product_margin_long) {
            this.product_margin_long = JSON.parse(this.registration.product_margin_long)
          }
  
          this.setActiveOption(this.gender, this.registration.gender)
          this.setActiveOption(this.is_founder, this.registration.is_founder)
          this.setActiveOption(this.is_fulltime, this.registration.is_fulltime)
          this.setActiveOption(this.revenue_model, this.registration.revenue_model)
          this.setActiveOption(this.company_job_role, this.registration.company_job_role)
          this.setActiveOption(this.company_category, this.registration.company_category)
          this.setActiveOption(this.company_step, this.registration.company_step)
          this.setActiveOption(this.survey, this.registration.survey)
        }
        else {
          localStorage.removeItem("session_id");
          localStorage.removeItem("session_email");
        }

      } else {
        localStorage.removeItem("session_id");
      }

    } catch (e) {

      // this.appService.openToast(e)

      console.log(e)
    } finally {
    

    }
  }

  _validatePage() {
    // if(this.step = '2'){
    //   if(this.registration.is_complete_step_1 == false){
    //     this.navTo(1)
    //   }
    // }
    // if(this.step = '3'){
    //   if(this.registration.is_complete_step_2 == false){
    //     this.navTo(1)
    //   }
    // }
  }

  
  isRequiredField() {
    console.log('fsd')
   console.log( this.personalInformationForm.controls.fullname.pristine)
}


  setActiveOption(array, selected) {
    // array.map(item => {
    //   item.selected = null
    // });

    
     array.map(item => {
      item.selected = selected
    });
  }

  async onChange(event: any, id) {
    const file: File = event.target.files[0];


    if (file) {

      if (event.target.files[0].size < 10000000) {
        const formData = new FormData();

        formData.append('file', file);



        try {
          this.appService.showLoading()
          let res = await this.webService.uploadPhoto(formData, 'id')
          console.log(res)
          if (res.data) {
            let form = {
              'id': id,
              'value': res.data
            }
            


            this.registration[id] = res.data

            this.dataUploadForm.get(id).setValue(res.data)

            this.updateData(form)

          }

        } catch (error) {
          //this.appService.openToast(error)
        } finally {

          this.appService.hideLoading()

        }
      }else{
        this.appService.openToast('Ukuran file terlalu besar. Batas ukuran adalah 10 Mb')
      }



    }
  }

  selectCity(e) {
    console.log(e)
    this.businessInformationForm.get('company_city').setValue(e.city_name)
    this.businessInformationForm.get('company_province').setValue(e.province)
  }
  async searchCity($event) {

    this.city_list = []
    try {
      this.appService.showLoading()
      let res = await this.webService.getCityList('10', $event)

      if (res.data) {
        this.city_list = res.data.map(item => {

          return {
            id: item.city_id,
            label: item.city_name,
            city_name: item.city_name,
            province: item.province
          };
        });

      }

    } catch (error) {
      this.appService.openToast(error)
    } finally {
      this.appService.hideLoading()

    }


  }


  async updateData(data) {


    let form = {}
    form[data.id] = data.value


    try {

      let response = await this.webService.updateRegistration(this.regis_id, form)
      // console.log(response)

    } catch (e) {

     // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.openToast('Berhasil di update')

    }
  }


  async createNewRegistration(state) {
    console.log('create new acconunt')
    console.log(this.personalInformationForm.value)
  //  this.personalInformationForm.get('fullname').touched
    this.personalInformationForm.markAllAsTouched();
    if(this.personalInformationForm.valid){
      try {
     
        this.personalInformationForm.get('is_complete_step_1').setValue(true)
  
        let response = await this.webService.addRegistration(this.personalInformationForm.value)
        // console.log(response)
        if (response.data) {
          localStorage.setItem("session_id", response.data.id);
          localStorage.setItem("session_email", response.data.email);
  
          if (state) {
          
            this.navTo(2)
          } else {
            this.appService.openToast('Berhasil di update')
            this.regis_id = response.data.id
          }
  
  
        }
  
  
      } catch (e) {
        location.reload
        //this.appService.openToast(e)
        console.warn(e)
        console.log(e)
      } finally {
  
  
      }
    }else{
      this.appService.openToast('Data belum lengkap, periksa kembali data yang Anda masukan')

    }
   



  }

  async updatePersonalInfo(state) {
    console.log('update Info')
    console.log(this.personalInformationForm.value)
    if(this.regis_id){
      try {
        this.appService.showLoading()
        this.personalInformationForm.get('is_complete_step_1').setValue(true)
        let response = await this.webService.updateRegistration(this.regis_id, this.personalInformationForm.value)
        // console.log(response)
        if (state) {
          this.navTo(2)
        } else {
          this.appService.openToast('Berhasil di update')
        }
  
  
      } catch (e) {
        location.reload
        // this.appService.openToast(e)
        console.warn(e)
        console.log(e)
      } finally {
  
        this.appService.hideLoading()
      }
    }else{
      location.reload
    }
   

  }

  async updateFinalStep() {
    this.dataUploadForm.markAllAsTouched()
    if(this.dataUploadForm.valid){
      
      try {
        this.appService.showLoading()
        this.dataUploadForm.get('is_confirm').setValue(true)
        this.dataUploadForm.get('status').setValue(true)
        this.dataUploadForm.get('is_complete_step_4').setValue(true)


        let response = await this.webService.updateRegistration(this.regis_id, this.dataUploadForm.value)
        // console.log(response)


        this.getRegistrationId(this.regis_id)
        this.navTo(5)

      } catch (e) {

        // this.appService.openToast(e)

        console.log(e)
      } finally {

        this.appService.hideLoading()
      }
    }else{
      this.appService.openToast('Data belum lengkap, periksa kembali data yang Anda masukan')

    }

  }

  async updateRegistration() {

    this.appService.showLoading()
    try {

      let form = {
        'is_confirm': false,
        'status': false
      }
      let response = await this.webService.updateRegistration(this.regis_id, form)

      if (response.data) {
        this.getRegistrationId(this.regis_id)
        this.navTo(1)
      }




    } catch (e) {

      // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.hideLoading()

    }
  }


  async updateBusinessInfo(state) {

    if(state){
      this.businessInformationForm.markAllAsTouched()
     if(this.businessInformationForm.valid){
        try {

          this.appService.showLoading()
          this.businessInformationForm.get('is_complete_step_2').setValue(true)
    
          let response = await this.webService.updateRegistration(this.regis_id, this.businessInformationForm.value)
          if (state) {
            // console.log(response)
            this.navTo(3)
          } else {
            this.appService.openToast('Berhasil disimpan, Anda dapat melanjutkan kembali dengan mengakses halaman ini')
          }
        } catch (e) {
    
          // this.appService.openToast(e)
    
          console.log(e)
        } finally {
          this.appService.hideLoading()
    
        }
      }else{
        this.appService.openToast('Data belum lengkap, periksa kembali data yang Anda masukan')


      }
    }else{
      try {
        this.appService.showLoading()
        this.businessInformationForm.get('is_complete_step_2').setValue(true)
  
        let response = await this.webService.updateRegistration(this.regis_id, this.businessInformationForm.value)
        if (state) {
          // console.log(response)
          this.navTo(3)
        } else {
          this.appService.openToast('Berhasil disimpan, Anda dapat melanjutkan kembali dengan mengakses halaman ini')
        }
      } catch (e) {
  
        // this.appService.openToast(e)
  
        console.log(e)
      } finally {
        this.appService.hideLoading()
  
      }
    }
    
   

   
  }

  _checkProductDetails() {
    const isTrue = !Object.values(this.product_details_long).some(x => (x.item === '' || x.details === '') || x.item === null || x.details === null);

    if (isTrue) {
      this.businessMetricForm.get('product_details_long').setValue(JSON.stringify(this.product_details_long))

    } else {
      this.businessMetricForm.get('product_details_long').setValue(null)

    }



  }

  _checkProductMargin() {
  
    const isTrue = !Object.values(this.product_margin_long).some(x => (x.item === '' || x.price === '' || x.margin === '') || x.item === null || x.price === null || x.margin === null);

    if (isTrue) {
      this.businessMetricForm.get('product_margin_long').setValue(JSON.stringify(this.product_margin_long))

    } else {
      this.businessMetricForm.get('product_margin_long').setValue(null)

    }
    

  }

  removeProductDetails(array, i) {
    array.splice(i, 1);
  }

  async updateMetricInfo(state) {
    console.log(this.businessMetricForm.value)

    if(state){
      this.businessMetricForm.markAllAsTouched()
       if(this.businessMetricForm.valid){
        try {
          this.appService.showLoading()
          this.businessMetricForm.get('is_complete_step_3').setValue(true)

          let response = await this.webService.updateRegistration(this.regis_id, this.businessMetricForm.value)
      
          if (state) {

            this.navTo(4)
          } else {
            this.appService.openToast('Berhasil disimpan, Anda dapat melanjutkan kembali dengan mengakses halaman ini')

          }
        } catch (e) {

          // this.appService.openToast(e)

          console.log(e)
        } finally {
          this.appService.hideLoading()

        }
       }else{
        this.appService.openToast('Data belum lengkap, periksa kembali data yang Anda masukan')

       }
    }else{

    try {
      this.appService.showLoading()
      this.businessMetricForm.get('is_complete_step_3').setValue(true)

      let response = await this.webService.updateRegistration(this.regis_id, this.businessMetricForm.value)
  
      if (state) {

        this.navTo(4)
      } else {
        this.appService.openToast('Berhasil disimpan, Anda dapat melanjutkan kembali dengan mengakses halaman ini')

      }
    } catch (e) {

      // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.hideLoading()

    }
    }
  }






  public numbersOnlyValidator(event: any) {
    event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    event.target.value = event.target.value.replace('-', "");
    event.target.value = event.target.value.replace(/^0+/, '');
    

   
  }

  public emailValidator(event: any) {
    event.target.value = event.target.value.replace(/[^0-9\-]/g, "");
    event.target.value = event.target.value.replace('-', "");
  }


  navTo(step) {
    this.router.navigate(
      [], {
        queryParams: {
          'step': step
        }
      }
    );


  

  }

  saveForm(step) {
 

    let next_step = parseFloat(step) + 1
    if (next_step <= 5) {
      this.navTo(next_step)

    }
  }

  selectBusinessOption(item, array, id) {


    array.map(item => {
      item.selected = null
    });

    item.selected = item.value
    this.businessInformationForm.get(id).setValue(item.value)


  }

  selectdataUploadForm(item, array, id) {
 
    array.map(item => {
      item.selected = null
    });

    item.selected = item.value
    this.dataUploadForm.get(id).setValue(item.value)


  }

  selectOption(item, array, id) {


    array.map(item => {
      item.selected = null
    });

    item.selected = item.value
    this.personalInformationForm.get(id).setValue(item.value)


  }

  selectMetricOption(item, array, id) {

    array.map(item => {
      item.selected = null
    });

    item.selected = item.value
    this.businessMetricForm.get(id).setValue(item.value)


  }

  addCustomDataBusinessForm(e, id) {
    this.businessInformationForm.get(id).setValue(e.target.value)
    console.log(e.target.value)
  }


  addCustomData(e, id) {
    this.businessMetricForm.get(id).setValue(e.target.value)
    console.log(e.target.value)
  }
  addCustomDataUpload(e, id) {
    this.dataUploadForm.get(id).setValue(e.target.value)
    console.log(e.target.value)
  }



}