import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';

import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center',
  templateUrl: './learning-center.component.html',
  styleUrls: ['./learning-center.component.scss']
})
export class LearningCenterComponent implements OnInit {
  params: any;
  article: any;

  constructor(
    private webService : ManualOrderService,
    
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router : Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
     
      this.params = this.serializer.serialize(this.router.createUrlTree([''],
        { queryParams: this.route.snapshot.queryParams}))   
        
        this.getLearningCenter()
        this.getRelatedLearningCenter()
     
    })
  }

  async getLearningCenter(){
    
    try {
      
      let response = await this.webService.getLearningCenter(this.params)
        this.article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }


  async getRelatedLearningCenter(){
    
    try {
      
      let response = await this.webService.getLearningCenter(this.params)
        this.article = response.data
      
      console.log(response)
    } catch (e) {
      
      
      
      console.log(e)
    } finally {
    
    }
  }


  _navigateTo(id) {
    this.router.navigate(
      ['/learning-center/view/'+id]
    );
  }

}
