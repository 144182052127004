import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faChevronRight, faUser } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  faChevronRight=faChevronRight


  company_category: any;
  faUser=faUser
  summary: any;
  revenue_model: any;
  job_role: any;
  gender: any;
  is_founder: any;
  is_fulltime: any;
  company_city: any;
  company_province: any;
  survey: any;
  business_stages: any[];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private serializer: UrlSerializer,
  ) { }

  ngOnInit(): void {
    this.getSummary()

   this.company_category = []
   this.revenue_model = []
   this.job_role=[]
    this.gender=[]
    this.is_founder =[]
    this.is_fulltime =[]
    this.company_city =[]
    this.company_province =[]
this.survey = []
this.business_stages =[]

    // this.getCompanyCategory()
    // this.getRevenueModel()
    // this.getJobRole()
    // this.getGender()
    // this.getFounder()
    // this.getFulltime()
    // this.getCity()
    // this.getProvince()
  }

  async getBusinessStages(){
    
    try {
    
      let response = await this.webService.getDashboard('company_step')
      this.business_stages =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }


  async getSurvey(){
    
    try {
    
      let response = await this.webService.getDashboard('survey')
      this.survey =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getProvince(){
    
    try {
    
      let response = await this.webService.getDashboard('company_province')
      this.company_province =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getCity(){
    
    try {
    
      let response = await this.webService.getDashboard('company_city')
      this.company_city =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getFulltime(){
    
    try {
    
      let response = await this.webService.getDashboard('is_fulltime')
      this.is_fulltime =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getFounder(){
    
    try {
    
      let response = await this.webService.getDashboard('is_founder')
      this.is_founder =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getGender(){
    
    try {
    
      let response = await this.webService.getDashboard('gender')
      this.gender =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }


  async getJobRole(){
    
    try {
    
      let response = await this.webService.getDashboard('company_job_role')
      this.job_role =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }


  async getRevenueModel(){
    
    try {
    
      let response = await this.webService.getDashboard('revenue_model')
      this.revenue_model =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }



  _getPercentage(total,count){
    return Math.round((count/total)*100)
  }

  async getSummary(){
    
    try {
    
      let response = await this.webService.getDashboard('summary')
      this.summary =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  async getCompanyCategory(){
    
    try {
    
      let response = await this.webService.getDashboard('company_category')
      this.company_category =response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }


}
