import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-participant',
  templateUrl: './participant.component.html',
  styleUrls: ['./participant.component.scss']
})
export class ParticipantComponent implements OnInit {
showModal: any;
  page: number;
  participant: any;
  selected_participant: any;
  params: string;
  faTrophy=faTrophy

  platform_list = [
    {
      'id':'rank',
      'label': 'Unrank',
      'value': ''
    },
    {
      'id':'rank',
      'label': 'Top 300',
      'value': 'Top 300'
    },
    {
      'id':'rank',
      'label': 'Top 40',
      'value': 'Top 40'
    },
    {
      'id':'rank',
      'label': 'Top 20',
      'value': 'Top 20'
    }
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private serializer: UrlSerializer,
    
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {
     

      this.params = this.serializer.serialize(this.router.createUrlTree([''],
      { queryParams: this.route.snapshot.queryParams}))      

      this.getRegistration()
    })
  }


  async setRank(item,e){
    console.log(e)
    let form ={
      'rank' : e.value,
    }
    try {
     
      let response = await this.webService.updateRegistration(item.id,form)
      if(response){
        item.rank = e.value
        this.appService.openToast('Rank Updated!')
      }
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
     
  
    }
  }

  async onChange(event: any, id) {
    const file: File = event.target.files[0];


    if (file) {

      if (event.target.files[0].size < 10000000) {
        const formData = new FormData();

        formData.append('file', file);



        try {
          this.appService.showLoading()
          let res = await this.webService.uploadPhoto(formData, 'id')
          console.log(res)
          if (res.data) {
            let form = {
              'id': id,
              'value': res.data
            }
            


          

            this.updateData(form)

          }

        } catch (error) {
          //this.appService.openToast(error)
        } finally {

          this.appService.hideLoading()

        }
      }else{
        this.appService.openToast('Ukuran file terlalu besar. Batas ukuran adalah 10 Mb')
      }



    }
  }

  async updateData(data) {


    let form = {}
    form[data.id] = data.value


    try {

      let response = await this.webService.updateRegistration(this.selected_participant.id, form)
      // console.log(response)

    } catch (e) {

     // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.openToast('Berhasil di update')

    }
  }


  async getRegistration(){
    
    try {
      this.appService.showLoading()
      this.params = this.params+'&is_finalist=1'
      let response = await this.webService.getRegistration(this.params)
        this.participant = response.data
      
      console.log(response)
    } catch (e) {
      
      this.appService.openToast(e)
      
      console.log(e)
    } finally {
      this.appService.hideLoading()
  
    }
  }

  selectParticipant(item){
    this.selected_participant = item

    this.selected_participant.product_details = JSON.parse(item.product_details)
    this.selected_participant.product_margin = JSON.parse(item.product_margin)

  }

}
