
import { faChevronRight,faAddressBook, faHome,faThList,faTimes, faLayerGroup, faBullhorn,faMagic, faNetworkWired, faUserTie, faUserCircle, faUsers, faWallet, faHandSparkles, faVideo } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCalendar, faCalendarAlt, faChevronDown, faChevronLeft, faEdit, faMapMarked, faMapMarker, faUserEdit, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';


@Component({
  selector: 'app-bisidebar',
  templateUrl: './bisidebar.component.html',
  styleUrls: ['./bisidebar.component.scss']
})
export class BISidebarComponent implements OnInit {
  faChevronRight=faChevronRight
  faThList = faThList
  faWallet =faWallet
  faTimes=faTimes
  faHome= faHome
  navigation_menu: any;
  sidebar: boolean;
  deviceInfo: any;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  active_menu: any;
  active_nav: any;
  account_menu_access: any;
  isMaster: boolean;
 
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    

    
  ) { }

  async ngOnInit() {
    this.route.queryParamMap.subscribe(queryParams => {
      this.active_nav = queryParams.get("active_nav")
    
    })
    this.navigation_menu = this.appService.navigation_menu 

    this.webService.userSessionSubject.subscribe(
      (res)=>{
        if(res){
          this.account_menu_access = JSON.parse(res.menu_access)
        //   console.log(this.account_menu_access)
       
          if(res.role_id == 1){
            this.isMaster = true
          }
        }
       
      }
    )
   
  }

  checkMenu(id){
    // if(this.isMaster){
    //   return true
    // }else{
    //   if(this.account_menu_access?.includes(id)){
    //     return true
    //   }
    //   else{
    //     return false
    //   }
    // }
    return true
   
   
  }



  toggleMenu(item){
    this.active_menu = item
  }
  toggleSidebar(){
    console.log('clid')
    this.sidebar = !this.sidebar
  }

  pageNavigate(menu){
    if(menu.has_child){
      this.router.navigate(
        [],
        { queryParams: { active_nav: menu.menu_id},
        }
      );
    }
    else{
      this.router.navigate(
        [menu.navigation],
        { queryParams: { active_nav: menu.menu_id},
       }
      );
    }
   
   
  }


}
