import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user_account: any;

  constructor(
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private router : Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    //this.user_account = this.webService.getUserSession()
  }

}
