import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faCheckCircle, faInbox, faBell, faHome, faHamburger, faPowerOff, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss']
})
export class TopNavigationComponent implements OnInit {
  faInbox=faInbox
  faSignOutAlt=faSignOutAlt
  faHamburger=faHamburger
  faTasks=faCheckCircle
  faCheckCircle=faCheckCircle
  faPowerOff=faPowerOff
  faHome=faHome
  faBell=faBell
  user_account: any;
  notification: any;
  showModal: any;
  inital_name: any;
  notification_count: any;
  constructor(
    private appService : BusinessIntelligenceService,
    private webService : ManualOrderService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.webService.userSessionSubject.subscribe(
      (res)=>{
       
         console.log(res)
        if(res){
          this.user_account = res
      
         
          this.inital_name = this.user_account.first_name.charAt(0) + this.user_account.last_name.charAt(0)
        }
      }
    )

  
    
    //  this.playSound('https://assets.mixkit.co/active_storage/sfx/2870/2870-preview.mp3')
  }






  playSound(url) {
    const audio = new Audio(url);
    audio.play();
  }

  openModal(modal){
    this.showModal = modal
  }
  closeModal(){
    this.showModal = null
  }

  
  async userLogout(){

    
      try {
        localStorage.removeItem("device_session");
        
       
        this.router.navigate(
          ['/dashboard/login']
        );

         
       
       } catch (e) {
         
         this.appService.openToast(e)
     
         
         console.log(e)
       } finally {
        this.user_account = null
        this.appService.openToast('Logout Success')
     
       }
    }
        
      



  

}
