import { FormControl, FormGroup } from '@angular/forms'

export const formError={
    "phone_length": "Panjang minimal nomor telpon adalah 10 digit"
}

export function validPhoneNumber(e: FormControl): any{
    
    let testStr = e.value || ''
    if(testStr.length < 10) return {'phone_length': true}
    else return null
    
}


export function validateRekening(e: FormControl): any{
    
    let testStr = e.value || ''
    if(testStr.length < 12) return {'phone_length': true}
    else return null
    
}

export function phoneFormatter(e){
    let str = [...(e||'')]
    return str.filter(i => !isNaN(i)).join('')
}