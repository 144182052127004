<div class="cta-section padding-center">
    <div class="padding-top-1 margin-bottom-1">
        <div class="row">
            <!-- <div class="medium-3 columns">
                <div class="section-title color-yellow">
    
                    <div class="section-title color-yellow">
    
                        16.09.24 - 24.01.25
        
                    </div>
                </div>
            </div> -->
            <div class="medium-9 columns">
                <div class="section-title color-yellow">
                    Saatnya #INSPIREINNOVATEIMPACT
                </div>
            </div>
            <div class="medium-3 columns">
                <!-- <button [routerLink]="'/registration'" class="main-button">
                    <span> Daftar wmm</span>
                </button> -->

                <!-- <button class="main-button">
                    <span> Registration WIll Open Soon</span>
                </button> -->
            </div>
        </div>
    </div>
</div>

<div class="section section-timeline padding-center color-black">


    <div class="padding-center">
        <div class="row">
            <div class="medium-6 columns margin-bottom-1">
                <div class="section-title color-white">
                    BUTUH BANTUAN? <br>
                    SILAHKAN HUBUNGI KAMI
                </div>

                
            </div>
            <div class="medium-6 columns">
                <div class="row medium-up-2 margin-bottom-2 ">
                    <div class="columns margin-bottom-1">
                        <div class="cta-card">
                            <div class="subtitle">
                                MANDIRI CALL
                            </div>
                            <div class="title">
                             <img width="17" height="17" src="/assets/logo/phone-solid (1).svg" alt="">   14000
                            </div>

                        </div>
                    </div>
                    <div class="columns margin-bottom-1">
                        <div class="cta-card">
                            <div class="subtitle">
                                INSTAGRAM
                            </div>
                            <div class="title color-black" style="color: black  !important;">
                                <a class="color-black" target="_blank" href="https://www.instagram.com/wrausahamandiri/">
                                    <img width="17" height="17" src="/assets/logo/instagram-brands-solid (3).svg" alt="">  wrausahamandiri
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="columns">
                        <div class="cta-card">
                            <div class="subtitle">
                                EMAIL
                            </div>
                            <div class="title color-black" style="color: black  !important;">
                               
                                <img width="17" height="17" src="/assets/logo/envelope-regular.svg" alt="">  wirausahamudamandiri.id&#64;gmail.com
                            </div>

                        </div>
                    </div>



                </div>

               
            </div>


        </div>





    </div>



</div>