import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-section-media',
  templateUrl: './section-media.component.html',
  styleUrls: ['./section-media.component.scss']
})
export class SectionMediaComponent implements OnInit {
  media = [
    {
      'title' : ' Kompas.com',
      'logo' : '/assets/logo/mandiri-logo-white.svg',
      'subtitle' : 'Sabtu, 20 Januari 2023',
      'headline' : 'Bank Mandiri Gelar Wirausaha Muda Mandiri 2023, Ayo Daftar Sekarang!',
      'image_url' : '/assets/image/DEV01625.jpg',
      'link' :'https://money.kompas.com/read/2023/09/27/174628926/bank-mandiri-gelar-wirausaha-muda-mandiri-2023-ayo-daftar-sekarang',

    },

    {
      'title' : ' CNBC',
      'logo' : '/assets/logo/logo_cnbc_putih.png',
      'subtitle' : 'Jumat, 19 Januari 2024',
      'headline' : 'Yuk Meriahkan Wirausaha Muda Mandiri, Cek Rangkaian Acaranya',
      'image_url' : '/assets/image/DEV03137.jpg',
      'link' :'https://www.cnbcindonesia.com/entrepreneur/20240119101232-25-507191/yuk-meriahkan-wirausaha-muda-mandiri-cek-rangkaian-acaranya',

    },

    {
      'title' : 'Bisnis.com',
      'logo' : '/assets/logo/mandiri-logo-white.svg',
      'subtitle' : 'Jumat, 19 Januari 2024',
      'link' :'https://finansial.bisnis.com/read/20240119/55/1733678/wirausaha-muda-mandiri-2023-sebanyak-24-finalis-beradu-skill-di-surabaya',
      'headline' : 'Wirausaha Muda Mandiri 2023, Sebanyak 24 Finalis Beradu Skill di Surabaya',
      'image_url' : '/assets/image/DEV03026.jpg',
    },

    {
      'title' : ' Republika',
      'logo' : '/assets/logo/Logo_Republika.png',
      'subtitle' : 'Sabtu, 20 Januari 2023',
      'link' :'https://ekonomi.republika.co.id/berita/s74qd8463/cetak-pebisnis-baru-bank-mandiri-gelar-wirausaha-muda-mandiri-2023',

      'headline' : ' Bank Mandiri Rilis Nama Juara Wirausaha Muda Mandiri 2023',
      'image_url' : '/assets/image/republika.jpeg',
    },


   
    
   
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
