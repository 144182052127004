<div class="section section-media">


    <div class="padding-center">
        <div class="row">
            <div class="medium-12 columns">


                <div class="section-title color-white">

                    Media & Publikasi
                </div>




            </div>

        </div>

        <div class="padding-center">
            <div class="row medium-up-4 show-for-medium">
                <div *ngFor="let item of media" class="columns">
                    <div class="media-card">
                        <div class="image">
                            <img [src]="item.image_url" alt="">
                        </div>
                        <!-- <div class="logo">
                            <img class="margin-bottom-05" height="70" width="70" [src]="item.logo" alt="image">

                        </div> -->
                        <div class="bottom">
                            <div class="media-headline">
                                <a target="_blank" style="color: white  !important" [href]="item.link">
                                    {{item.headline}}
                                  </a> 
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>
                    </div>


                </div>


            </div>

            <div style="margin-left: 1rem;" class="flex-container scroll-container show-for-small-only">
                <div *ngFor="let item of media" class="flex-item" style="flex: 0 0 45%; margin-right: 2px;">
                    
                    <div class="media-card">
                        <div class="image">
                            <img [src]="item.image_url" alt="">
                        </div>
                        <!-- <div class="logo">
                            <img class="margin-bottom-05" height="70" width="70" [src]="item.logo" alt="image">

                        </div> -->
                        
                        <div class="bottom">
                            <div class="media-headline">
                                <a target="_blank" style="color: white  !important" [href]="item.link">
                                    {{item.headline}}
                                  </a> 
                            </div>
                            <div class="title">
                                {{item.title}}
                            </div>
                            <div class="subtitle">
                                {{item.subtitle}}
                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>




    </div>



</div>