import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center-backend',
  templateUrl: './learning-center-backend.component.html',
  styleUrls: ['./learning-center-backend.component.scss']
})
export class LearningCenterBackendComponent implements OnInit {
  article: any;
  params: string;
  selected_article: any

  htmlContent = '';

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private webService: ManualOrderService,

    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(queryParams => {

      this.params = this.serializer.serialize(this.router.createUrlTree([''],
        { queryParams: this.route.snapshot.queryParams }))

      this.getLearningCenter()


    })
  }

  async getLearningCenter() {

    try {

      let response = await this.webService.getLearningCenter(this.params)
      this.article = response.data

      console.log(response)
    } catch (e) {



      console.log(e)
    } finally {

    }
  }

  async addLearningCenter() {

    try {

      let response = await this.webService.addLearningCenter(this.params)

      console.log(response)
    } catch (e) {



      console.log(e)
    } finally {
      this.ngOnInit()
    }
  }




  async deleteLearningCenter(id) {

    try {

      let response = await this.webService.deleteLearningCenter(id)

      console.log(response)
    } catch (e) {



      console.log(e)
    } finally {
      this.ngOnInit()
    }
  }



}
