<div *ngIf="showModal">
    <div class="modal-sheets-container">
        <div class="callout padding-center modal-sheets-content elevate page-enter-from-bottom">
            <div class="modal-header margin-bottom-1">
                <div class="flex-container align-center">
                    <div class="flex-item flex-1">
                        <h3>{{this.title}}</h3>
                    </div>
                    <div class="flex-item ">
                        <a (click)="closeModal()" class="close white">
                            <fa-icon [icon]="faTimesCircle" class="icon"></fa-icon>
                        </a>
                    </div>
                </div>
               
              
            </div>
            <div class="modal-body">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
    
   
</div>