<div class="tab-menu-container">
    <ul class="menu tab-bar-menu">
        <li [ngClass]="{active_tab: tab_view == item.id, 'action' : item.type === 'step2' }" *ngFor="let item of tab_menu_list">
            <a (click)="_navigateToTabView(item.id)">
            {{item.label}}
            <span *ngIf="item.count" class="count pulse">
                {{item.count}}
            </span>
            </a>
        </li>

        <ng-content></ng-content>


    </ul>
</div>