<div class="section section-timeline">


    <div class="padding-center">
        <div class="row">
            <div class="medium-6 medium-centered columns">
                <div class="text-center">



                    <div class="section-title color-black" style="line-height: 33px;">
                        WMM <img height="100" width="100" src="/assets/logo/2024-black.svg" alt="image"> <br>
                        Now is the time
                    </div>

                </div>

                <div class="padding-center">
                    <div class="timeline-container">
                        <div *ngFor="let item of timeline"
                            class="timeline-content flex-container color-black align-center">
                            <div class="flex-item flex-05 margin-right-2">
                                <div class="date">
                                    {{item.date}}
                                </div>
                                <div class="month">
                                    {{item.month}}
                                </div>
                            </div>
                            <div class="flex-item flex-3">
                                <div class="title">
                                    {{item.title}}
                                </div>
                                <div class="subtitle">
                                    {{item.subtitle}}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>

        </div>


    </div>



</div>