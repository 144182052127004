import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faArrowRight, faArrowsAlt, faArrowsAltH, faComment,faLongArrowAltRight,faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  showModal: boolean;
  @Input() isHalf?: boolean
  @Input() isBorder?: boolean
  @Input() title: any
  @Input() isLeft: any
  @Output() onClose = new EventEmitter()
  constructor() { }
  faTimesCircle=faTimes
  faArrowsAlt=faLongArrowAltRight
  ngOnInit(): void {
    this.showModal = true
  }

  closeModal(){
    console.log('click log')
    this.showModal = false
    this.onClose.emit(false)
  }
}
