<div class="section section-about relative show-for-medium">


    <div class="long-fade-in">
        <div class="padding-top-3 show-for-medium">
            <div class="row">
                <div class="medium-12 columns">
                    <div class="flex-container align-center">
                        <div class="flex-item flex-1 margin-right-2">
                            <div *ngIf="!show_sidebar" class="cursive-title color-white">Apa itu</div>

                            <div *ngIf="show_sidebar" class="section-title color-white">



                                {{active_program}}
                            </div>



                        </div>
                        <div class="flex-item flex-1 show-for-medium">
                            <div class="about-nav flex-container">
                                <div *ngFor="let item of about_nav" class="flex-item">

                                    <span [ngClass]="{active : item.title == active_program}"
                                        (click)="selectProgram(item)" class="link-nav">
                                        <span *ngIf="item.title == active_program" class="node"></span> {{item.title}}
                                    </span>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'medium-6':  !show_sidebar, 'medium-4' : show_sidebar }" class="columns">
                    <div class="relative">

                        <div *ngIf="!show_sidebar" style="z-index: 9999999;position: absolute;">
                            <div class="section-title color-white">

                                WIRAUSAHA MUDA MANDIRI ?


                            </div>
                            <div class="section-para color-white">
                                Wirausaha Muda Mandiri (WMM) adalah salah satu program utama Bank Mandiri yang telah
                                dilaksanakan sejak tahun 2007, dan telah mencetak alumni lebih dari 750 wirausaha.
                                Program ini bertujuan untuk membantu pemerintah dalam menumbuhkembangan kewirausahaan di
                                Indonesia khususnya di kalangan generasi muda.
                            </div>
                        </div>

                    </div>


                    <img [ngClass]="{'small':  show_sidebar }" class="person-image small"
                        src="/assets/logo/person-logo.png" alt="image">

                </div>
                <div [ngClass]="{'medium-6':  !show_sidebar, 'medium-8' : show_sidebar }" class="columns">

                    <div class="padding-top-3 show-for-medium">
                        <div *ngIf="active_program == 'Aktivitas'" class="right-content-container">
                            <div class="section-subtitle margin-bottom-2 margin-left-1">
                                Apa saja aktivitasnya?
                            </div>

                            <div class="flex-container">
                                <div class="flex-item flex-1 activity left">

                                    <div class="flex-container content align-center">
                                        <div class="flex-item">
                                            <div class="flex-item margin-right-05">
                                                <img height="50" width="50" src="/assets/logo/mentoring.svg"
                                                    alt="image">
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <div class="activity-tile">
                                                <div class="title">
                                                    Sesi Mentoring
                                                </div>
                                                <div class="subtitle">
                                                    Kesempatan dimentori langsung oleh pemilik bisnis sukses lewat
                                                    kegiatan FGD dan workshop
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-item">
                                            <span class="pulse chat">

                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex-1"></div>

                            </div>
                            <div class="flex-container">
                                <div class="flex-1"></div>
                                <div class="flex-item flex-1 activity right align-center">


                                    <div class="flex-container content align-center">
                                        <div class="flex-item">
                                            <span class="pulse chat">

                                            </span>
                                        </div>
                                        <div class="flex-item">
                                            <div class="flex-item margin-right-05">
                                                <img height="50" width="50" src="/assets/logo/company.svg" alt="image">
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <div class="activity-tile">
                                                <div class="title">
                                                    Company Visit
                                                </div>
                                                <div class="subtitle">
                                                    Kunjungan langsung ke perusahaan dan bisa langsung berdikusi dengan
                                                    C-Level dan founder
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="flex-container">

                                <div class="flex-item flex-1 activity left align-center">

                                    <div class="flex-container content align-center">
                                        <div class="flex-item">
                                            <div class="flex-item margin-right-05">
                                                <img height="50" width="50" src="/assets/logo/workshop.svg" alt="image">
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <div class="activity-tile">
                                                <div class="title">
                                                    Workshop
                                                </div>
                                                <div class="subtitle">
                                                    Belajar tentang pemasaran, storytelling, operasional, dan keuangan
                                                    serta praktek langsung dengan pakarnya

                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-item">
                                            <span class="pulse chat">

                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex-1"></div>

                            </div>
                            <div class="flex-container align-center">
                                <div class="flex-1"></div>
                                <div class="flex-item flex-1 activity right align-center">


                                    <div class="flex-container content align-center">
                                        <div class="flex-item">
                                            <span class="pulse chat">

                                            </span>
                                        </div>
                                        <div class="flex-item">
                                            <div class="flex-item margin-right-05">
                                                <img height="50" width="50" src="/assets/logo/speed.svg" alt="image">
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <div class="activity-tile">
                                                <div class="title">
                                                    Speed Dating
                                                </div>
                                                <div class="subtitle">
                                                    Kenalkan ide kamu ke investor dan dapatkan peluang investasi dengan
                                                    bangun kemitraan di Speed Dating.

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="flex-container">
                                <div class="flex-item flex-1 activity left">

                                    <div class="flex-container content align-center">
                                        <div class="flex-item">
                                            <div class="flex-item margin-right-05">
                                                <img height="50" width="50" src="/assets/logo/networking.svg"
                                                    alt="image">
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <div class="activity-tile">
                                                <div class="title">
                                                    Networking
                                                </div>
                                                <div class="subtitle">
                                                    Kesempatan berjejaring dengan founder, mentor, dan speaker ternama
                                                    untuk dukungan dan info bisnis.

                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex-item">
                                            <span class="pulse chat">

                                            </span>
                                        </div>
                                    </div>

                                </div>
                                <div class="flex-1"></div>

                            </div>




                        </div>
                    </div>





                    <div style="padding-left:4rem">
                        <div *ngIf="active_program == 'Kriteria Peserta'"
                            class="participant long-fade-in page-enter-from-right">




                            <div class="callout padding-center">
                                <div class="section-subtitle">
                                    Kriteria Calon Peserta
                                </div>
                            </div>
                            <div class="callout padding-center">


                                <div *ngFor="let item of participant ; let i = index"
                                    class="padding-bottom-1 flex-container align-center">
                                    <div class="flex-item margin-right-2">
                                        <span class="cursive-subtitle color-yellow">
                                            {{i+1}}
                                        </span>
                                    </div>
                                    <div class="flex-item flex-1">
                                        <div [innerHTML]="item.title" class="section-para" style="color: white !important;">
                                            <!-- {{item.title}} -->
                                        </div>
                                    </div>
                                </div>

                                <div class="text-right white small-text">
                                    Ketentuan pitch deck akan diatur dalam alur pendaftaran
                                </div>


                            </div>

                        </div>

                        <div *ngIf="active_program == 'Kriteria Bisnis'"
                            class="business long-fade-in page-enter-from-right">


                            <div class="callout padding-center">
                                <div class="section-subtitle">
                                    Kriteria Kategori Bisnis
                                </div>
                            </div>
                            <div class="callout padding-center margin-bottom-2">


                                <div *ngFor="let item of business ; let i = index" class="accordion-container">
                                    <div (click)="selectBusiness(item)" class="title white">

                                        <div class="flex-container">
                                            <div class="flex-item flex-1">
                                                {{item.title}}
                                            </div>
                                            <div class="flex-item">
                                                <fa-icon *ngIf="!item.is_active" [icon]="faPlus"></fa-icon>
                                                <fa-icon *ngIf="item.is_active" [icon]="faMinus"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.is_active == true" class="callout long-fade-in">
                                        <div class="subtitle white margin-bottom-1">
                                            {{item.subtitle}}
                                        </div>

                                        <div *ngFor="let item of item.items ; let i = index" class="padding-bottom-1">

                                            <div class="flex-container align-center">
                                                <div class="flex-item margin-right-2">
                                                    <span class="cursive-subtitle color-yellow">
                                                        {{i+1}}
                                                    </span>
                                                </div>
                                                <div class="flex-item flex-1">
                                                    <div class="activity-tile">
                                                        <div class="title">
                                                            {{item.title}}
                                                        </div>
                                                        <div class="subtitle">
                                                            {{item.subtitle}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>


                            </div>

                        </div>

                        <div *ngIf="active_program == 'Keuntungan Program'"
                            class="benefit long-fade-in page-enter-from-right">
                            <div class="section-subtitle margin-bottom-2 margin-left-1">
                                Keuntungan mengikuti program WMM 2024!
                            </div>
                            <div class="callout padding-center">


                                <div *ngFor="let item of benefit ; let i = index"
                                    class="padding-bottom-1 flex-container align-center">
                                    <div class="flex-item margin-right-2">
                                        <img height="40" width="40" src="/assets/logo/check-logo.svg" alt="image">
                                    </div>
                                    <div class="flex-item flex-1">
                                        <div class="section-para">
                                            {{item.title}}
                                        </div>
                                    </div>
                                </div>




                            </div>

                        </div>
                        <div *ngIf="active_program == 'FAQ'" class="business long-fade-in page-enter-from-right">
                            <div class="callout padding-center">
                                <div class="section-subtitle">
                                    Pertanyaan seputar WMM2024
                                </div>
                            </div>
                            <div class="callout padding-center">


                                <div *ngFor="let item of faq ; let i = index" class="accordion-container">
                                    <div (click)="selectBusiness(item)" class="title white">

                                        <div class="flex-container">
                                            <div class="flex-item flex-1">
                                                {{item.title}}
                                            </div>
                                            <div class="flex-item">
                                                <fa-icon *ngIf="!item.is_active" [icon]="faPlus"></fa-icon>
                                                <fa-icon *ngIf="item.is_active" [icon]="faMinus"></fa-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="item.is_active == true" class="callout">
                                        <div [innerHTML]="item.subtitle"  class="subtitle white">
                                         
                                        </div>
                                        <div  [innerHTML]="item.subtitle"   *ngFor="let item of item.items" class="subtitle white">
                                           
                                        </div>

                                    </div>


                                </div>


                            </div>

                        </div>
                    </div>


                </div>







            </div>
        </div>





    </div>


</div>

<div class="section section-about show-for-small-only padding-center">
   <div class="row">
    <div class="medium-12 columns">
        <div >
            <div class="section-title color-white">
    
                WIRAUSAHA MUDA MANDIRI ?
    
    
            </div>
            <div class="section-para color-white">
                Wirausaha Muda Mandiri (WMM) adalah salah satu program utama Bank Mandiri yang telah
                dilaksanakan sejak tahun 2007, dan telah mencetak alumni lebih dari 750 wirausaha.
                Program ini bertujuan untuk membantu pemerintah dalam menumbuhkembangan kewirausahaan di
                Indonesia khususnya di kalangan generasi muda.
            </div>
        </div>
    </div>
   </div>

    <div style="margin-left: 1rem;" class="flex-container scroll-container padding-center">
        <div *ngFor="let item of activity" class="flex-item activity-border" style="flex: 0 0 75%;">
           <div class="flex-container  ">
            <div class="flex-item margin-right-05">
                <img height="50" width="50" [src]="item.icon"
                alt="image">
            </div>
            <div class="flex-item flex-1">
                <div class="activity-tile">
                    <div class="title">
                     {{item.title}}
                    </div>
                    <div class="subtitle">
                      {{item.subtitle}}
                    </div>
                </div>
            </div>
           </div>
           
        </div>
      
    </div>
</div>
<div style="background-color: #458CFF;" class="mobile-section show-for-small-only padding-center">
    <div class="about-nav flex-container scroll-container">
        <div *ngFor="let item of about_nav_mobile" class="flex-item" style="flex: none">

            <span [ngClass]="{active : item.title == active_program_mobile}" (click)="selectProgram(item)"
                class="link-nav">
                <span *ngIf="item.title == active_program_mobile" class="node"></span> {{item.title}}
            </span>
        </div>


    </div>
    <div class="row">


        <div class="medium-12 columns">






            <div *ngIf="active_program_mobile == 'Kriteria Peserta'" class="">




                <div class="section-subtitle">
                    Kriteria Calon Peserta
                </div>
                <div class="callout padding-center">


                    <div *ngFor="let item of participant ; let i = index"
                        class="padding-bottom-1 flex-container align-center">
                        <div class="flex-item flex-05">
                            <span class="cursive-subtitle color-yellow">
                                {{i+1}}
                            </span>
                        </div>
                        <div class="flex-item flex-4">
                            <div [innerHTML]="item.title" class="section-para">
                            
                            </div>
                        </div>
                    </div>

                    <div class="text-right white small-text">
                        Ketentuan pitch deck akan diatur dalam alur pendaftaran
                    </div>


                </div>

            </div>

            <div *ngIf="active_program_mobile == 'Kriteria Bisnis'" class="business long-fade-in page-enter-from-right">


                <div class="section-subtitle">
                    Kriteria Kategori Bisnis
                </div>
                <div class="callout padding-center margin-bottom-2">


                    <div *ngFor="let item of business ; let i = index" class="accordion-container">
                        <div (click)="selectBusiness(item)" class="title white">

                            <div class="flex-container">
                                <div class="flex-item flex-1">
                                    {{item.title}}
                                </div>
                                <div class="flex-item">
                                    <fa-icon *ngIf="!item.is_active" [icon]="faPlus"></fa-icon>
                                    <fa-icon *ngIf="item.is_active" [icon]="faMinus"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.is_active == true" class="callout long-fade-in">
                            <div class="subtitle white margin-bottom-1">
                                {{item.subtitle}}
                            </div>

                            <div *ngFor="let item of item.items ; let i = index" class="padding-bottom-1">

                                <div class="flex-container align-center">
                                    <div class="flex-item margin-right-2">
                                        <span class="cursive-subtitle color-yellow">
                                            {{i+1}}
                                        </span>
                                    </div>
                                    <div class="flex-item flex-1">
                                        <div class="activity-tile">
                                            <div class="title">
                                                {{item.title}}
                                            </div>
                                            <div [innerHTML]="item.subtitle" class="subtitle">
                                                {{item.subtitle}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>


                </div>

            </div>
            <div *ngIf="active_program_mobile == 'Keuntungan Program'"
                class="benefit long-fade-in page-enter-from-right">
                <div class="section-subtitle margin-bottom-1 margin-left-1">
                    Keuntungan mengikuti program WMM 2024!
                </div>
                <div class="callout padding-center">


                    <div *ngFor="let item of benefit ; let i = index"
                        class="padding-bottom-1 flex-container align-center">
                        <div class="flex-item flex-05">
                            <img height="25" width="25" src="/assets/logo/check-logo.svg" alt="image">
                        </div>
                        <div class="flex-item flex-3">
                            <div class="section-para">
                                {{item.title}}
                            </div>
                        </div>
                    </div>




                </div>

            </div>
            <div *ngIf="active_program_mobile == 'FAQ'" class="business long-fade-in page-enter-from-right">
                <div class="section-subtitle">
                    Pertanyaan seputar WMM2024
                </div>
                <div class="callout padding-center">


                    <div *ngFor="let item of faq ; let i = index" class="accordion-container">
                        <div (click)="selectBusiness(item)" class="title white">

                            <div class="flex-container">
                                <div class="flex-item flex-1">
                                    {{item.title}}
                                </div>
                                <div class="flex-item">
                                    <fa-icon *ngIf="!item.is_active" [icon]="faPlus"></fa-icon>
                                    <fa-icon *ngIf="item.is_active" [icon]="faMinus"></fa-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="item.is_active == true" class="callout">
                            <div [innerHTML]="item.subtitle" class="subtitle white">
                             
                            </div>
                            <div [innerHTML]="item.subtitle" *ngFor="let item of item.items" class="subtitle white">
                              
                            </div>

                        </div>


                    </div>


                </div>

            </div>

        </div>
    </div>
</div>