<div class="sidebar-containers">
  <div class="sidebar small-text">
    
   


    <ul *ngFor="let menu of navigation_menu" class="menu vertical">

      <li *ngIf="checkMenu(menu.menu_id)">
        <a class="child_link" (click)="pageNavigate(menu)">
          <fa-icon class="icon" [icon]="menu.icon"></fa-icon>
          {{menu.title}}
        </a>
      </li>
      <span *ngIf="active_nav == menu.menu_id">
        <span  *ngFor="let item of menu.child">
          <li *ngIf="checkMenu(item.menu_id)">
            <a routerLinkActive="active" class="child_link" [routerLink]="item.navigation"
              [queryParams]="{active_nav:menu.menu_id}">
              {{item.title}}
            </a>
          </li>
        </span>
      
      </span>


    </ul>

  </div>
</div>
