

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faChevronLeft, faChevronRight, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { title } from 'process';

import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-section-winner',
  templateUrl: './section-winner.component.html',
  styleUrls: ['./section-winner.component.scss']
})
export class SectionWinnerComponent implements OnInit {
  currentIndex = 0;
  categories = [
    {
      "title": "Winner",
      "winner": true,
      "description": "Innovative Food & Beverages",
      "items": [

        {
          "id": 10509,

          "fullname": "Danang Satria Mustari Nugroho",
          "email": "alammeherbal@gmail.com",
          "dob": "1992-12-14",
          "phone": 82121278119,
          "gender": "Pria",

          "company_name": "ALAMME",

          "pitchdeck_url": "https://api.wirausahamudamandiri.id/data_file/1727404679_Pitch Deck - Alamme.pdf",
          "business_photo_url": "https://api.wirausahamudamandiri.id/data_file/1727406087_All Product - Alamme.png",
          "personal_photo_url": "https://api.wirausahamudamandiri.id/data_file/1727406011_Pas Foto - Danang Satria Mustari N.png",
          "survey": "Bank Mandiri Instagram",
          "rank": "1",

          "longexperience": "Mendirikan Alamme pada bulan Maret 2023, saya dibantu tim yang berpengalaman berhasil menciptakan produk sehat alami yang berkualitas, serta membangun jaringan distribusi dan afiliasi yang luas. Sebelum membangun Alamme, saya adalah Vice President di Evermos yang berperan dalam strategic initiative, serta membangun value chain service untuk UMKM dan brand. Sebelumnya, saya adalah Vice President di eFishery yang memimpin pengembangan smart farming solution dan business operation. Saya memiliki pengalaman juga di Agree sebagai Chief Operations Officer, anak perusahaan Telkom Indonesia, dan berperan memimpin inovasi digital di sektor agrikultur. Karier saya dimulai di Accenture sebagai Functional Consultant, berfokus pada implementasi sistem SAP untuk perusahaan multinasional. Pengalaman saya dalam membangun dan memimpin startup di berbagai sektor memperkuat kemampuan saya untuk mengembangkan bisnis, memperluas pasar, dan membangun kemitraan strategis secara efektif.",
          "company_description_long": "PT Semua Dari Alam, dengan brand unggulan Alamme, adalah penyedia makanan dan minuman sehat yang berkomitmen untuk meningkatkan kesadaran masyarakat Indonesia melalui produk yang menyehatkan, alami, dan terjangkau. Alamme menghadirkan berbagai macam produk sehat inovatif dengan cita rasa yang enak seperti Black Garlic, Black Garlic Honey, Garlic Honey, Pure Honey, dan Nutri Glaze. Selain memberikan manfaat kesehatan, Alamme juga membantu peningkatan petani dan peternak madu di Indonesia melalui serapan bahan baku. Befokus pada inovasi, kualitas, dan keterjangkauan, produk-produk Alamme kini telah tersebar ke seluruh Indonesia. Alamme memiliki keunggulan yang terletak pada pendekatan komprehensif terhadap kualitas dan pelayanan. Kami memastikan bahwa semua produk diproduksi menggunakan bahan baku terbaik dengan sortasi ketat, serta mengikuti SOP sesuai dengan standar keamanan pangan. Selain itu, kami menawarkan layanan konsultasi kesehatan gratis kepada konsumen melalui customer care.",

        },

      ]
    }

  ];


  about_nav = [
    {
      'title': 'Beauty & Wellness',
      'key': 'Beauty%20%26%20Wellness',
      'id': 'winner',
      'is_active': true,
    },
    {
      'title': 'Fashion',
      'key': 'Fashion',
      'id': 'winner',


    },
    {
      'title': 'Innovative Food & Beverages',
      'key': 'Innovative%20Food%20%26%20Beverages',
      'id': 'winner',

    },
    {
      'title': 'Tech & Tech-Enabled Startups',
      'key': 'Tech%20%26%20Tech-Enabled%20Startups',
      'id': 'winner',

    },

  ]
  isLoading: boolean;
  participant: any[];
  alumni: { brand: string; name: string; name_detail: string; description: string; logo: string; profile_photo: string; cover: string; is_selected: boolean; }[];
  banners: { img_url: string; }[];
  carosellItem: number;
  carosellItemClone: number;
  total_slide: number;
  windowWith: number;
  buffer: number;
  start: any;
  faChevronLeft = faChevronLeft
  faChevronRight = faChevronRight

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: BusinessIntelligenceService,
    private webService: ManualOrderService,
    private serializer: UrlSerializer,
  ) {

  }

  ngOnInit(): void {

    this.about_nav.forEach(item => {
      this.getRegistration(item)

    });


    // this.getRegistrationMobile('beauty','Beauty%20%26%20Wellness')

  }



  async getRegistration(cat) {
    this.isLoading = true
    this.participant = []
    console.log(cat)
    try {

      //  let param = '?is_finalist=1'
      let param = '?is_finalist=1&winner=winner&company_category=' + cat.key

      let response = await this.webService.getRegistration(param)

      this.categories.push(
        {
          'title': cat.title,
          'description': cat.title,
          'items': response.data,
          'winner': false
        }
      )


      console.log(this.categories)
    } catch (e) {

      this.appService.openToast(e)

      console.log(e)
    } finally {

      this.isLoading = false

    }
  }

  scrollLeft() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }
  }

  scrollRight() {
    if (this.currentIndex < this.categories.length - 1) {
      this.currentIndex++;
    }
  }


}
