<span (click)="toggle()">
    <ng-content></ng-content>
</span>

<div *ngIf="isShow" class="notif-containers text-center fade-in">
 
    <div class="notif-content elevate">
        <div class="callout padding-center rounded medium-grey-bg">
            <div class="large-text margin-bottom-1">
                {{message?message:'Please confirm to continue'}}
            </div>
            <div class="flex-container align-center">
               
                <div class="flex-item flex-1 margin-right-05">
                    <button (click)="confirm(false)" class="button small rounded clear active   expanded">Cancel</button>
                </div>
                <div class="flex-item flex-1">
                    <button (click)="confirm(true)"  (keyup.enter)="confirm(true)" class="button small rounded expanded">Confirm</button>
                </div>

            </div>
            
         
        </div>
    </div>
</div>
