<div style="background: #F9E547 !important;position: sticky;top: 0;z-index: 999; padding: 0.7rem 1rem;"
    class="align-center show-for-medium flex-container">
    <div routerLink="/" class="flex-item margin-right-2 cursor color-black huge-text">
        <fa-icon [icon]="faChevronLeft"></fa-icon>
        <span class="show-for-medium">Home</span>
    </div>
    <div class="flex-item flex-1 ">
        <div class="oswald-bold huge-text color-black">
            PENDAFTARAN WMM2024
        </div>
    </div>
    <div *ngIf="registration" class="flex-item margin-right-1 color-black uppercase oswald-bold">

        Nomor Pendaftaran #{{registration.id}}

    </div>
    <div *ngIf="registration" class="flex-item margin-right-1 color-black oswald-bold">


        <span class="button tiny clear rounded uppercase">
            {{registration.status==false?'Draft':'Terkonfirmasi'}}
        </span>
    </div>
</div>

<div style="background: #F9E547 !important;position: sticky;top: 0;z-index: 999; padding: 0.7rem 1rem;"
    class="align-center show-for-small-only flex-container">
    <div routerLink="/" class="flex-item margin-right-2 cursor color-black huge-text">
        <fa-icon [icon]="faChevronLeft"></fa-icon>

    </div>
    <div class="flex-item flex-1 ">
        <div class="oswald-bold huge-text color-black">
            PENDAFTARAN #{{registration?.id}}
        </div>
    </div>

    <div *ngIf="registration" class="flex-item color-black oswald-bold">


        <span class="button tiny clear rounded uppercase">
            {{registration.status==false?'Draft':'Terkonfirmasi'}}
        </span>
    </div>
</div>
<section #myname class="section-form">
    <div class="long-fade-in padding-center padding-bottom-4">

        <div class="row">
            <div *ngIf="step !== '5'" class="medium-3 columns show-for-medium" style="position: sticky;top: 7rem;">


                <div class="form-nav ">
                    <div [ngClass]="{active: item.step <= step}" *ngFor="let item of menu"
                        class="form-item flex-container align-center">
                        <div class="flex-item margin-right-1">
                            <img *ngIf="item.step < step" height="25" width="25" src="/assets/logo/check-logo.svg"
                                alt="image">


                            <img *ngIf="item.step >= step" height="25" width="25" src="/assets/logo/check-empty.svg"
                                alt="image">

                        </div>
                        <div class="flex-item flex-3">
                            {{item.title}} <span class="tiny-text margin-left-05">{{item.time}}</span>
                        </div>

                    </div>
                </div>
            </div>
            <div  class="medium-9 columns">
                <div *ngIf="step =='1'">
                    <div class="section-subtitle margin-bottom-2">

                        Personal Information


                    </div>



                    <div class="row">
                        <div class="medium-6 columns">
                            <div *ngIf="is_registered" style="background: #486eab;"
                                class="callout elevate relative form small-text padding-center border-white  rounded margin-bottom-1 ">
                                <div class="margin-bottom-1">
                                    <fa-icon [icon]="faInfoCircle"></fa-icon> Email yang Anda masukan sudah
                                    terdaftar. Silahkan melajutkan dengan mengkonfirmasi nomor handphone Anda
                                </div>

                                <!-- <button (click)="showPhoneNumber = true" class="button tiny margin-right-05">
                                Lanjutkan dengan {{registered_email}}
                            </button> -->

                                <div>
                                    {{registered_email}}
                                </div>
                                <div class="flex-container align-center margin-bottom-2">
                                    <div class="flex-item margin-right-05">

                                        <div class="text">
                                            +62
                                        </div>
                                    </div>
                                    <div class="flex-item flex-1 margin-right-1">
                                        <input type="text" placeholder="Masukan nomor yang didaftarkan sebelumnya"
                                            class="form-control margin-bottom-0" id="phone" name="phone" maxlength="12"
                                            phoneMask (input)="numbersOnlyValidator($event)"
                                            [(ngModel)]="registered_phone" />
                                    </div>

                                    <div class="flex-item margin-right-05">

                                        <button (click)="resumeForm()" class="button clear small margin-bottom-0">
                                            Konfirmasi
                                        </button>
                                    </div>


                                </div>


                                <span (click)="is_registered = false" style="position: absolute;top: 10px;right: 13px;"
                                    class="cursor close_button">
                                    <fa-icon [icon]="faTimesCircle"></fa-icon>
                                </span>
                            </div>
                            <form [formGroup]="personalInformationForm" class="form">

                                <div class="large-text lato-light">
                                    Email <div class="hasError"
                                    *ngIf="this.personalInformationForm.get('email').hasError('required') && this.personalInformationForm.get('email').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="margin-bottom-2" *ngIf="!registration">
                                    <input [ngClass]="{warning: !is_email_valid}" (blur)="validateEmail($event)"
                                        type="email">
                                    


                                </div>
                                <div class="large-text margin-bottom-2" *ngIf="registration">
                                    {{registration.email}}
                                </div>





                                <div class="margin-bottom-2">
                                    <div class="large-text lato-light">
                                        Nama Lengkap  <div class="hasError"
                                        *ngIf="this.personalInformationForm.get('fullname').hasError('required') && this.personalInformationForm.get('fullname').touched">
                                        Wajib dilengkapi
                                    </div>
                                    </div>
                                    <input (blur)="isRequiredField()" formControlName="fullname" maxlength="80"
                                        type="text">



                                   
                                </div>



                                <div class="margin-bottom-2">
                                    <div class="large-text lato-light">
                                        Tanggal Lahir  <div class="hasError"
                                        *ngIf="this.personalInformationForm.get('dob').hasError('required') && this.personalInformationForm.get('dob').touched">
                                        Wajib dilengkapi
                                    </div>
                                    </div>
                                    <input formControlName="dob"  type="datetime-local" max="9999-12-31" type="date">
                                   
                                </div>

                                <div class="margin-bottom-2">
                                    <div class="large-text lato-light">
                                        Nomor Handphone  <div class="hasError"
                                        *ngIf="this.personalInformationForm.get('phone').hasError('required') && this.personalInformationForm.get('phone').touched">
                                        Wajib dilengkapi
                                    </div>
                                    </div>
                                    <div class="flex-container align-center margin-bottom-05">
                                        <div class="flex-item margin-right-05">

                                            <div class="text">
                                                +62
                                            </div>
                                        </div>
                                        <div class="flex-item flex-1">
                                            <input type="text" formControlName="phone"
                                                placeholder="Masukan nomor yang terhubung ke WhatsApp"
                                                class="form-control margin-bottom-0" id="phone" name="phone"
                                                maxlength="12" phoneMask (input)="numbersOnlyValidator($event)" />
                                        </div>


                                    </div>
                                   
                                </div>





                                <div class="margin-bottom-2">
                                    <div class="large-text margin-bottom-05 lato-light">
                                        Jenis Kelamin <div class="hasError"
                                        *ngIf="this.personalInformationForm.get('gender').hasError('required') && this.personalInformationForm.get('gender').touched">
                                        Wajib dilengkapi
                                    </div>
                                    </div>
                                    <div class="flex-container margin-bottom-05">
                                        <div (click)="selectOption(item,gender,'gender')" class="cursor large-text"
                                            *ngFor="let item of gender ; let last = last;">
                                            <div class="flex-item margin-right-2 text">
                                                <div class="flex-container margin-bottom-05">
                                                    <div class="flex-item margin-right-05">
                                                        <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                            [icon]="faCircle">
                                                        </fa-icon>
                                                        <fa-icon *ngIf="item.selected == item.value"
                                                            [icon]="faCheckCircle">
                                                        </fa-icon>

                                                    </div>
                                                    <div class="flex-item huge-text">
                                                        {{item.label }}

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    
                                </div>


                                
                                <div class="large-text lato-light">
                                    Adakah latar belakang/pengalaman Anda yang relevan yang bisa diceritakan? <div class="hasError"
                                    *ngIf="this.personalInformationForm.get('longexperience').hasError('required') && this.personalInformationForm.get('longexperience').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <textarea formControlName="longexperience" maxlength="1000" id=""></textarea>
                                
                            </form>
                        </div>
                        <div class="medium-6 columns">
                            <form [formGroup]="personalInformationForm" class="form">
                                <div class="callout padding-center rounded border-white form">
                                    <h4>Social Media</h4>

                                    <div class="flex-container align-center margin-bottom-05">
                                        <div class="flex-item margin-right-1">
                                            <img height="17" width="17" src="/assets/logo/linkedin-in-brands-solid.svg"
                                                alt="image">
                                        </div>
                                        <div class="flex-item flex-1">
                                            <input formControlName="linked_in_url"
                                                placeholder="ex. https://www.linkedin.com/in/wirausahamuda" type="text"
                                                class="margin-bottom-0">

                                        </div>

                                    </div>
                                    <div class="flex-container align-center margin-bottom-05">
                                        <div class="flex-item margin-right-1">
                                            <img height="17" width="17" src="/assets/logo/instagram-brands-solid.svg"
                                                alt="image">
                                        </div>
                                        <div class="flex-item flex-1">
                                            <input formControlName="instagram_url"
                                                placeholder="ex. https://www.instagram.com/wrausahamandiri/" type="text"
                                                class="margin-bottom-0">

                                        </div>
                                    </div>







                                </div>
                            </form>

                        </div>
                    </div>

                    <div class="callout text-right">
                        <!-- <div class="log tiny-text">system log : db {{this.registration?.id}} / cache_id :
                            {{this.regis_id}}</div> -->
                        <div class="margin-bottom-05">

                            <button [disabled]="!this.personalInformationForm.valid" *ngIf="this.registration?.id"
                                (click)="updatePersonalInfo(true)" class="main-button small-expanded margin-bottom-0">
                                <span>Simpan & Lanjutkan <fa-icon [icon]="faChevronRight"></fa-icon></span>
                                <!-- <div class="log tiny-text">system log : existing</div> -->
                            </button>



                            <button *ngIf="!this.registration?.id" (click)="createNewRegistration(true)"
                                class="main-button margin-bottom-0">
                                <span>Simpan & Lanjutkan<fa-icon [icon]="faChevronRight"></fa-icon></span>
                                <!-- <div class="log tiny-text">system log : new</div> -->
                            </button>
                        </div>
                        <div class="small-text show-for-medium">
                            Data yang sudah tersimpan tidak akan hilang saat browser ditutup / refresh.<br>
                            Anda dapat melanjutkan pengisian data dengan mangakses kembali halaman ini
                        </div>

                    </div>




                </div>

                <div class="page-enter-from-right" *ngIf="step =='2'">
                    <div (click)="navTo(1)" class="large-text cursor color-yellow margin-bottom-05">
                        <fa-icon [icon]="faChevronLeft"></fa-icon> Personal Information
                    </div>
                    <div class="section-subtitle margin-bottom-2">

                        Business Information
                    </div>

                    <div class="row">
                        <div class="medium-10 columns">
                            <form [formGroup]="businessInformationForm" class="form">
                               <div class="margin-bottom-2">
                                <div class="large-text lato-light">
                                    Apa nama perusahaan Anda?  <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('company_name').hasError('required') && this.businessInformationForm.get('company_name').touched">
                                    Wajib dilengkapi
                                </div>

                                </div>
                                <input maxlength="80" type="text" formControlName="company_name">
                               
                               </div>

                              <div class="margin-bottom-2">
                                <div class="large-text lato-light">
                                    Mohon berikan deskripsi singkat mengenai perusahaan Anda beserta produk yang Anda
                                    tawarkan  <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('company_description_long').hasError('required') && this.businessInformationForm.get('company_description_long').touched">
                                    Wajib dilengkapi
                                </div>

                                </div>
                                <textarea maxlength="1000" formControlName="company_description_long" name=""
                                    id=""></textarea>
                                   
                              </div>



                                <div class="padding-center form">
                                    <div class="row">
                                        <div class="medium-6 columns">
                                            <div class="margin-bottom-2">
                                                <div class="large-text lato-light">
                                                    Tahun berapa perusahaan Anda didirikan?



                                                </div>
                                                <div class="flex-container align-center">
                                                    <div class="flex-item margin-right-05">
                                                        <fa-icon [icon]="faBookmark"></fa-icon>
                                                    </div>
                                                    <div class="flex-item flex-1">
                                                        <input formControlName="company_start_year" type="text"
                                                            maxlength="4" class="margin-bottom-0"
                                                            (input)="numbersOnlyValidator($event)">
                                                    </div>
                                                </div>

                                                <div class="hasError"
                                                *ngIf="this.businessInformationForm.get('company_start_year').hasError('required') && this.businessInformationForm.get('company_start_year').touched">
                                                Wajib dilengkapi
                                            </div>
                                            </div>

                                            <div class="margin-bottom-2">
                                                <div class="large-text lato-light">
                                                    Apa website atau link media sosial perusahaan Anda?



                                                </div>
                                                <div class="flex-container align-center">
                                                    <div class="flex-item margin-right-05">
                                                        <fa-icon [icon]="faGlobe"></fa-icon>
                                                    </div>
                                                    <div class="flex-item flex-1">
                                                        <input placeholder="ex. https://wirausahamudamandiri.id/"
                                                            formControlName="company_website" type="text" maxlength="80"
                                                            class="margin-bottom-0">
                                                    </div>
                                                </div>

                                                <div class="hasError"
                                                *ngIf="this.businessInformationForm.get('company_website').hasError('required') && this.businessInformationForm.get('company_website').touched">
                                                Wajib dilengkapi
                                            </div>
                                            </div>
                                        </div>
                                        <div class="medium-6 columns">

                                            <div class="margin-bottom-2">
                                                <div class="large-text lato-light">
                                                    Berapa jumlah total karyawan perusahaan Anda?




                                                </div>
                                                <div class="flex-container align-center">
                                                    <div class="flex-item margin-right-05">
                                                        <fa-icon [icon]="faUsers"></fa-icon>
                                                    </div>
                                                    <div class="flex-item flex-1">
                                                        <input formControlName="company_employee_size" type="text"
                                                            maxlength="10" class="margin-bottom-0"
                                                            (input)="numbersOnlyValidator($event)">
                                                    </div>
                                                </div>

                                                <div class="hasError"
                                                *ngIf="this.businessInformationForm.get('company_employee_size').hasError('required') && this.businessInformationForm.get('company_employee_size').touched">
                                                Wajib dilengkapi
                                            </div>
                                            </div>




                                            <div class="margin-bottom-2">
                                                <div class="large-text lato-light">
                                                    Dimana lokasi kantor pusat perusahaan Anda berada?
                                                </div>

                                                <div class="flex-container align-center">
                                                    <div class="flex-item margin-right-05">
                                                        <fa-icon [icon]="faLocationArrow"></fa-icon>
                                                    </div>
                                                    <div class="flex-item flex-1">

                                                        <app-form-select
                                                            [selected_option]="this.businessInformationForm.get('company_city').value"
                                                            [label]="'City'" [option]="city_list"
                                                            (onSelect)="selectCity($event)"
                                                            (onSearch)="searchCity($event)"></app-form-select>
                                                        <!-- <input formControlName="company_location" type="text"
                                                            class="margin-bottom-0"> -->
                                                    </div>

                                                   
                                                </div>
                                                <div class="hasError"
                                                *ngIf="this.businessInformationForm.get('company_city').hasError('required') && this.businessInformationForm.get('company_city').touched">
                                                Wajib dilengkapi
                                            </div>

                                                
                                            </div>


                                        </div>
                                    </div>













                                </div>



                                <div class="large-text margin-bottom-1 lato-light">
                                    Apa industri/sektor perusahaan Anda?  <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('company_category').hasError('required') && this.businessInformationForm.get('company_category').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="margin-bottom-3">
                                    <div (click)="selectBusinessOption(item,company_category,'company_category')"
                                        class="cursor large-text"
                                        *ngFor="let item of company_category ; let last = last;">
                                        <div class="flex-container margin-bottom-05 text">
                                            <div class="flex-item margin-right-05">
                                                <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                    [icon]="faCircle">
                                                </fa-icon>
                                                <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                                </fa-icon>

                                            </div>
                                            <div class="flex-item huge-text">
                                                {{item.label }}



                                            </div>
                                        </div>

                                        <div style="background: #486eab;" *ngIf="item.selected == item.value"
                                            class="detail-container fade-in callout padding-center border-white rounded margin-bottom-1">
                                            <div class="large-text margin-bottom-1">{{item.subtitle}}</div>
                                            <div class="padding-bottom-05 margin-bottom-05 margin-left-05"
                                                *ngFor="let item of item.items ; let i = index">

                                                <div class="flex-container">
                                                    <div class="flex-item margin-right-1">
                                                        <div> {{i+1}}</div>
                                                    </div>
                                                    <div class="flex-item">
                                                        <div class="small-text bold">{{item.title}}</div>
                                                        <div class="small-text"> {{item.subtitle}}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>



                                    </div>

                                   
                                </div>








                                <div class="large-text margin-bottom-1 lato-light">
                                    Apakah Anda seorang owner/founder? 
                                    <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('is_founder').hasError('required') && this.businessInformationForm.get('is_founder').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="flex-container margin-bottom-2">
                                    <div (click)="selectBusinessOption(item,is_founder,'is_founder')"
                                        class="cursor large-text" *ngFor="let item of is_founder ; let last = last;">
                                        <div class="flex-item margin-right-2">
                                            <div class="flex-container text margin-bottom-05">
                                                <div class="flex-item margin-right-05">
                                                    <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                        [icon]="faCircle">
                                                    </fa-icon>
                                                    <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                                    </fa-icon>

                                                </div>
                                                <div class="flex-item huge-text">
                                                    {{item.label }}

                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>








                                <div class="large-text margin-bottom-1 lato-light">
                                    Apa peran Anda di perusahaan Anda?  <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('company_job_role').hasError('required') && this.businessInformationForm.get('company_job_role').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="margin-bottom-2 flex-container">
                                    <div (click)="selectBusinessOption(item,company_job_role,'company_job_role')"
                                        class="cursor large-text"
                                        *ngFor="let item of company_job_role ; let last = last;">
                                        <div class="flex-item margin-right-2">
                                            <div class="flex-container text margin-bottom-05">
                                                <div class="flex-item margin-right-05">
                                                    <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                        [icon]="faCircle">
                                                    </fa-icon>
                                                    <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                                    </fa-icon>

                                                </div>
                                                <div class="flex-item huge-text">
                                                    {{item.label }}

                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <input type="text" *ngIf="item.selected == 'Lainnya'"
                                                (input)="addCustomDataBusinessForm($event,'company_job_role')">

                                        </div>


                                    </div>
                                </div>






                                <div class="large-text margin-bottom-1 lato-light">
                                    Apakah Anda saat ini bekerja full-time di perusahaan Anda?
                                    <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('is_fulltime').hasError('required') && this.businessInformationForm.get('is_fulltime').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="margin-bottom-2">
                                    <div (click)="selectBusinessOption(item,is_fulltime,'is_fulltime')"
                                        class="cursor large-text" *ngFor="let item of is_fulltime ; let last = last;">
                                        <div class="flex-container text margin-bottom-05">
                                            <div class="flex-item margin-right-05">
                                                <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                    [icon]="faCircle">
                                                </fa-icon>
                                                <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                                </fa-icon>

                                            </div>
                                            <div class="flex-item huge-text">
                                                {{item.label }}

                                            </div>
                                        </div>



                                    </div>
                                </div>

                                <div class="large-text margin-bottom-1 lato-light">
                                    Di manakah tahap perusahaan Anda saat ini?
                                    <div class="hasError"
                                    *ngIf="this.businessInformationForm.get('company_step').hasError('required') && this.businessInformationForm.get('company_step').touched">
                                    Wajib dilengkapi
                                </div>

                                </div>
                                <div class="margin-bottom-3">
                                    <div (click)="selectBusinessOption(item,company_step,'company_step')"
                                        class="cursor large-text" *ngFor="let item of company_step ; let last = last;">
                                        <div class="flex-container margin-bottom-1 text">
                                            <div class="flex-item margin-right-05">
                                                <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                    [icon]="faCircle">
                                                </fa-icon>
                                                <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                                </fa-icon>

                                            </div>
                                            <div class="flex-item huge-text">
                                                {{item.label }}
                                                <div class="small-text">
                                                    {{item.desc}}
                                                </div>

                                            </div>
                                        </div>



                                    </div>
                                </div>




                            </form>
                        </div>


                    </div>

                    <div class="callout text-right">
                        <div class="margin-bottom-05 flex-container text-right">

                            <div class="flex-item" style="margin-left: auto;">
                                <button (click)="updateBusinessInfo(false)"
                                    class="main-button margin-right-05 margin-bottom-0 clean">
                                    <span>Simpan</span>
                                </button>
                            </div>
                            <div class="flex-item">
                                <button (click)="updateBusinessInfo(true)" class="main-button margin-bottom-0">
                                    <span>Simpan & Lanjutkan <fa-icon [icon]="faChevronRight"></fa-icon></span>
                                </button>

                            </div>


                        </div>

                        <div class="small-text show-for-medium">
                            Data yang sudah tersimpan tidak akan hilang saat browser ditutup / refresh.<br>
                            Anda dapat melanjutkan pengisian data dengan mangakses kembali halaman ini
                        </div>
                    </div>

                </div>

                <div class="page-enter-from-right" *ngIf="step =='3'">
                    <div (click)="navTo(2)" class="large-text color-yellow cursor margin-bottom-05">
                        <fa-icon [icon]="faChevronLeft"></fa-icon> Business Information
                    </div>
                    <div class="section-subtitle margin-bottom-2">

                        Business Metric
                    </div>
                    <div class="row form">
                        <div class="medium-9 columns">


                            <div [formGroup]="businessMetricForm" class="margin-bottom-2">

                                <div class="large-text margin-bottom-1 lato-light">
                                    Apa model pendapatan (revenue model) perusahaan Anda?
                                    <div class="hasError"
                                    *ngIf="this.businessMetricForm.get('revenue_model').hasError('required') && this.businessMetricForm.get('revenue_model').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div (click)="selectMetricOption(item,revenue_model,'revenue_model')"
                                    class="cursor large-text" *ngFor="let item of revenue_model ; let last = last;">
                                    <div class="flex-container text margin-bottom-05">
                                        <div class="flex-item margin-right-05">
                                            <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                [icon]="faCircle">
                                            </fa-icon>
                                            <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                            </fa-icon>

                                        </div>
                                        <div class="flex-item huge-text">
                                            {{item.label }}

                                        </div>
                                    </div>

                                    <div>
                                        <input type="text" *ngIf="item.selected == 'Lainnya'"
                                            (input)="addCustomData($event,'revenue_model')">

                                    </div>



                                </div>
                            </div>

                            <div class="callout padding-center border-white rounded margin-bottom-2">
                                <h4 class="margin-bottom-1">Product Details</h4>
                                
                                <div class="large-text margin-bottom-1 lato-light">
                                    Jelaskan produk atau layanan Anda secara rinci. Jika Anda memiliki beberapa
                                    produk, silahkan menambahkan produk ( Maks 5 Produk )

                                    <div class="hasError"
                                    *ngIf="this.businessMetricForm.get('product_details_long').hasError('required') && this.businessMetricForm.get('product_details_long').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>
                                <div class="flex-container small-text">
                                    <div class="flex-item flex-1 margin-right-05 lato-light">
                                        Nama Produk
                                    </div>
                                    <div class="flex-item flex-2 lato-light margin-right-05">
                                        Details
                                    </div>
                                    <div style="width: 20px;" class="flex-item">

                                    </div>
                                </div>
                                <div *ngFor="let item of product_details_long ; let i = index" class="flex-container">
                                    <div class="flex-item flex-1 margin-right-05">
                                        <input (blur)="_checkProductDetails()" placeholder="ex. Leather Mug"
                                            [(ngModel)]="item.item" type="text">
                                    </div>
                                    <div class="flex-item flex-2 margin-right-05">
                                        <input (blur)="_checkProductDetails()" placeholder="ex. gelas dari bahan kulit"
                                            [(ngModel)]="item.details" type="text">


                                    </div>
                                    <div style="width: 20px;" class="flex-item">
                                        <button [disabled]="i==0"
                                            (click)="removeProductDetails(product_details_long,i);_checkProductDetails()"
                                            class="button tiny clear margin-bottom-0">
                                            <fa-icon [icon]="faTrashAlt"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                                <button [disabled]="product_details_long.length>4"
                                    (click)="addMoreProduct(product_details_long);_checkProductDetails()"
                                    class="button tiny clear">
                                    + Produk
                                </button>
                            </div>


                            <div class="callout padding-center border-white rounded margin-bottom-2">
                                <h4 class="margin-bottom-1">Price & Margin</h4>
                                <div class="large-text lato-light margin-bottom-1">
                                    Jelaskan produk atau layanan Anda secara rinci. Jika Anda memiliki beberapa
                                    produk, silahkan menambahkan produk ( Maks 5 Produk ) 
                                    <div class="hasError"
                                    *ngIf="this.businessMetricForm.get('product_margin_long').hasError('required') && this.businessMetricForm.get('product_margin_long').touched">
                                    Wajib dilengkapi
                                </div>
                                </div>

                                <div class="margin-bottom-1">
                                    <div class="flex-container small-text">
                                        <div class="flex-item flex-2 margin-right-05 lato-light">
                                            Nama Produk
                                        </div>
                                        <div class="flex-item flex-1 margin-right-05 lato-light">
                                            Harga ( IDR )
                                            <div class="tiny-text">Harga per produk</div>
                                        </div>
                                        <div class="flex-item flex-1 lato-light margin-right-05">
                                            Margin
                                            <div class="tiny-text">Persentase keuntungan per produk</div>
                                        </div>
                                        <div style="width: 20px;" class="flex-item">

                                        </div>
                                    </div>
                                    <div *ngFor="let item of product_margin_long ; let i = index"
                                        class="flex-container align-center margin-bottom-1">
                                        <div class="flex-item flex-2 margin-right-05">
                                            <input class="margin-bottom-0" (blur)="_checkProductMargin()"
                                                placeholder="ex. Leather Mug" [(ngModel)]="item.item" type="text">
                                        </div>
                                        <div class="flex-item flex-1 margin-right-05">
                                            <input (blur)="_checkProductMargin()" placeholder="ex. 10000"
                                                [(ngModel)]="item.price" type="text" class="margin-bottom-0" separator>
                                        </div>
                                        <div class="flex-item flex-1 margin-right-05">
                                            <input (blur)="_checkProductMargin()" placeholder="ex. 10"
                                                [(ngModel)]="item.margin" maxlength="3" type="text"
                                                class="margin-bottom-0" separator>
                                        </div>
                                        <div style="width: 20px;" class="flex-item">
                                            <button [disabled]="i==0"
                                                (click)="removeProductDetails(product_margin_long,i);_checkProductMargin()"
                                                class="button tiny clear margin-bottom-0">
                                                <fa-icon [icon]="faTrashAlt"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button (blur)="_checkProductMargin()" [disabled]="product_margin_long.length > 4"
                                    (click)="addMoreMargin(product_margin_long)" class="button tiny clear ">
                                    + Produk
                                </button>
                            </div>

                            <div [formGroup]="businessMetricForm" class="callout padding-center border-white rounded">
                                <h4 class="margin-bottom-1">Profit & Revenue</h4>
                                <div class="row">
                                    <div class="medium-6 columns">
                                        <div class="margin-bottom-2">
                                            <div class="large-text lato-light">
                                                Berapa pendapatan perusahaan Anda pada tahun 2023?

                                                <div class="hasError"
                                                *ngIf="this.businessMetricForm.get('revenue_2023').hasError('required') && this.businessMetricForm.get('revenue_2023').touched">
                                                Wajib dilengkapi
                                            </div>

                                            </div>
                                            <div class="flex-container align-center">
                                                <div class="flex-item margin-right-05">
                                                    IDR
                                                </div>
                                                <div class="flex-item flex-1">
                                                    <input type="text" formControlName="revenue_2023"
                                                        class="margin-bottom-0" separator>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="margin-bottom-2">
                                            <div class="large-text lato-light">
                                                Berapa proyeksi pendapatan perusahaan Anda pada tahun 2024?

                                                <div class="hasError"
                                                *ngIf="this.businessMetricForm.get('revenue_2024_valuation').hasError('required') && this.businessMetricForm.get('revenue_2024_valuation').touched">
                                                Wajib dilengkapi
                                            </div>
                                            </div>
                                            <div class="flex-container align-center">
                                                <div class="flex-item margin-right-05">
                                                    IDR
                                                </div>
                                                <div class="flex-item flex-1">
                                                    <input formControlName="revenue_2024_valuation" type="text"
                                                        class="margin-bottom-0" separator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="medium-6 columns">
                                        <div class="margin-bottom-2">
                                            <div class="large-text lato-light">
                                                Berapa pendapatan tahun berjalan perusahaan Anda pada tahun 2024?

                                                <div class="hasError"
                                                *ngIf="this.businessMetricForm.get('revenue_2024').hasError('required') && this.businessMetricForm.get('revenue_2024').touched">
                                                Wajib dilengkapi
                                            </div>

                                            </div>
                                            <div class="flex-container align-center">
                                                <div class="flex-item margin-right-05">
                                                    IDR
                                                </div>
                                                <div class="flex-item flex-1">
                                                    <input formControlName="revenue_2024" type="text"
                                                        class="margin-bottom-0" separator>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="margin-bottom-2">
                                            <div class="large-text lato-light">
                                                Berapa proyeksi pendapatan perusahaan Anda pada tahun 2025?

                                                <div class="hasError"
                                                *ngIf="this.businessMetricForm.get('revenue_2025_valuation').hasError('required') && this.businessMetricForm.get('revenue_2025_valuation').touched">
                                                Wajib dilengkapi
                                            </div>

                                            </div>
                                            <div class="flex-container align-center">
                                                <div class="flex-item margin-right-05">
                                                    IDR
                                                </div>
                                                <div class="flex-item flex-1">
                                                    <input formControlName="revenue_2025_valuation" type="text"
                                                        class="margin-bottom-0" separator>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>





                            </div>

                            <div class="callout text-right">
                                <div class="margin-bottom-05">
                                    <button (click)="updateMetricInfo(false)"
                                        class="main-button margin-right-05 margin-bottom-0 clean">
                                        <span>Simpan</span>
                                    </button>
                                    <button (click)="updateMetricInfo(true)"
                                        class="main-button margin-bottom-0">
                                        <span>Simpan & Lanjutkan <fa-icon [icon]="faChevronRight"></fa-icon></span>
                                    </button>
                                </div>

                                <div class="small-text show-for-medium">
                                    Data yang sudah tersimpan tidak akan hilang saat browser ditutup / refresh.<br>
                                    Anda dapat melanjutkan pengisian data dengan mangakses kembali halaman ini
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

                <div [formGroup]="dataUploadForm" class="page-enter-from-right margin-bottom-4" *ngIf="step =='4'">
                    <div (click)="navTo(3)" class="large-text color-yellow cursor margin-bottom-05">
                        <fa-icon [icon]="faChevronLeft"></fa-icon> Business Metric
                    </div>
                    <div class="section-subtitle margin-bottom-2">
                        Data Upload
                    </div>

                    <div class="row">
                        <div class="medium-8 columns">


                            <div
                                class="callout padding-center-1 margin-bottom-1 flex-container rounded border-white align-center">
                                <div class="flex-item margin-right-1">
                                    <fa-icon class="mega-text" [icon]="faFile"></fa-icon>
                                </div>
                                <div class="flex-item flex-2 large-text  margin-right-1">
                                    <div class="large-text margin-bottom-05">
                                        <span class="margin-right-05">Pitchdeck</span>
                                        <span *ngIf="this.registration?.pitchdeck_url" class="flex-item tiny-text">
                                            <fa-icon [icon]="faCheckCircle"></fa-icon> Uploaded <a class="white"
                                                target="_blank" [href]="this.registration?.pitchdeck_url">( View )</a>
                                        </span>

                                        
                                    </div>

                                    <div class="hasError"
                                    *ngIf="this.dataUploadForm.get('pitchdeck_url').hasError('required') && this.dataUploadForm.get('pitchdeck_url').touched">
                                    Wajib dilengkapi
                                </div>





                                    <div class="small-text margin-bottom-05">
                                        Silakan bagikan kepada kami pitch deck terbaru Anda yang biasanya Anda bagikan
                                        kepada calon investor dalam format .pdf dengan ukuran maks.10MB

                                        <input class="hide" type="file" accept="application/pdf,.ppt, .pptx"
                                            (change)="onChange($event,'pitchdeck_url')" #fileUploadImage />


                                    </div>

                                    <div class="flex-item show-for-small-only">
                                        <button (click)="fileUploadImage.click()"
                                            class="button margin-right-05 tiny clear ">
                                            <fa-icon [icon]="faUpload"></fa-icon>
                                            {{this.registration?.pitchdeck_url?'Re-upload':'Upload'}}
                                        </button>


                                        <a target="_blank"
                                            [href]="'https://docs.google.com/presentation/d/18z_v2QFw6B9c6DvghSGmV6021KMypEVO2oYnMeyLCio/edit#slide=id.p'"
                                            class="button tiny rounded clear margin-right-05">

                                            Contoh Pitchdeck
                                        </a>

                                        <a target="_blank"
                                        [href]="'https://youtu.be/Cr5hmNPp7B8'"
                                        class="button tiny rounded clear ">

                                       Video Panduan
                                    </a>





                                    </div>
                                </div>
                                <div class="flex-item show-for-medium">
                                    <button (click)="fileUploadImage.click()"
                                        class="button margin-right-05 tiny clear ">
                                        <fa-icon [icon]="faUpload"></fa-icon>
                                        {{this.registration?.pitchdeck_url?'Re-upload':'Upload'}}
                                    </button>


                                    <a target="_blank"
                                        [href]="'https://docs.google.com/presentation/d/18z_v2QFw6B9c6DvghSGmV6021KMypEVO2oYnMeyLCio/edit#slide=id.p'"
                                        class="button tiny rounded clear margin-right-05">

                                        Contoh Pitchdeck
                                    </a>

                                    <a target="_blank"
                                    [href]="'https://youtu.be/Cr5hmNPp7B8'"
                                    class="button tiny rounded clear ">

                                   Video Panduan
                                </a>






                                </div>
                            </div>





                            <div
                                class="callout padding-center-1 margin-bottom-05 flex-container rounded border-white align-center">
                                <div *ngIf="registration?.business_photo_url" class="flex-item">
                                    <a target="_blank" [href]="registration.business_photo_url">
                                        <img class="cover-photo margin-right-05" width="150" height="150" width="150"
                                            height="150" [src]="registration.business_photo_url" alt="">

                                    </a>
                                </div>
                                <div class="flex-item flex-1 large-text margin-right-05">
                                    <div class="large-text margin-bottom-05">
                                        Product / Business Photo 
                                        
                                        <div class="hasError"
                                        *ngIf="this.dataUploadForm.get('business_photo_url').hasError('required') && this.dataUploadForm.get('business_photo_url').touched">
                                        Wajib dilengkapi
                                    </div>
    
                                    </div>
                                    <div class="small-text">
                                        Siapkan foto cover bisnis Anda. Bisa berupa foto produk, prototipe, atau
                                        service. Resolusi photo 1:1 dengan ukuran maks. 1024x1024
                                        <input class="hide" type="file" accept=".jpg,.png,.jpeg"
                                            (change)="onChange($event,'business_photo_url')" #photobusiness />
                                    </div>
                                </div>
                                <div class="flex-item">


                                    <button (click)="photobusiness.click()" class="button tiny clear ">
                                        <fa-icon [icon]="faUpload"></fa-icon>
                                        {{this.registration?.business_photo_url?'Re-upload':'Upload'}}
                                    </button>
                                </div>
                            </div>

                            <div
                                class="callout padding-center-1 flex-container rounded border-white align-center  margin-bottom-2">
                                <div *ngIf="registration?.personal_photo_url" class="flex-item ">
                                    <a target="_blank" [href]="registration.personal_photo_url">
                                        <img class="cover-photo margin-right-05" width="150" height="150"
                                            [src]="registration.personal_photo_url" alt="">

                                    </a>
                                </div>
                                <div class="flex-item flex-1 large-text margin-right-05">
                                    <div class="large-text margin-bottom-05">
                                        Personal Photo Profile
                                        
                                        <div class="hasError"
                                        *ngIf="this.dataUploadForm.get('personal_photo_url').hasError('required') && this.dataUploadForm.get('personal_photo_url').touched">
                                        Wajib dilengkapi
                                    </div>
    
                                    </div>
                                    <div class="small-text">
                                        Siapkan foto profile Anda dengan latar dan pose profesional. Resolusi photo 1:1
                                        dengan ukuran maks. 1024x1024

                                        <input class="hide" type="file" accept=".jpg,.png,.jpeg"
                                            (change)="onChange($event,'personal_photo_url')" #photoprofile />
                                    </div>
                                </div>
                                <div class="flex-item">
                                    <button (click)="photoprofile.click()" class="button tiny clear ">
                                        <fa-icon [icon]="faUpload"></fa-icon>
                                        {{this.registration?.personal_photo_url?'Re-upload':'Upload'}}
                                    </button>
                                </div>
                            </div>

                            <div class="large-text margin-bottom-1 lato-light">
                                Dari mana Anda mengetahui Program Wirausaha Muda Mandiri?
                                <div class="hasError"
                                *ngIf="this.dataUploadForm.get('survey').hasError('required') && this.dataUploadForm.get('survey').touched">
                                Wajib dilengkapi
                            </div>

                            </div>
                            <div class="margin-bottom-3 form">

                                <div (click)="selectdataUploadForm(item,survey,'survey')" class="cursor large-text"
                                    *ngFor="let item of survey ; let last = last;">
                                    <div class="flex-container margin-bottom-1 text">
                                        <div class="flex-item margin-right-05">
                                            <fa-icon class="color-grey" *ngIf="item.selected !== item.value"
                                                [icon]="faCircle">
                                            </fa-icon>


                                            <fa-icon *ngIf="item.selected == item.value" [icon]="faCheckCircle">
                                            </fa-icon>

                                            <!-- <input *ngIf="item.selected == item.value"  type="text" [value]="this.dataUploadForm.get('survey').value" 
                                            (input)="addCustomDataUpload($event,'survey')"> -->

                                        </div>
                                        <div class="flex-item huge-text">
                                            {{item.label }}
                                            <div class="large-text">
                                                {{item.desc}}
                                            </div>

                                        </div>
                                    </div>
                                    <div *ngIf="item.selected == 'Lainnya'">

                                        <input type="text" (input)="addCustomDataUpload($event,'survey')">

                                    </div>




                                </div>
                            </div>

                            <div class="callout show-for-medium tiny-text rounded border-white padding-center">
                                <div class="large-text margin-bottom-05">
                                    Pernyataan Persetujuan

                                </div>
                                <ul class="margin-bottom-2 small-text">
                                    <li>Saya setuju untuk mengikuti seleksi dan program Wirausaha Muda Mandiri.</li>
                                    <li>Saya memahami bahwa saya akan memberikan data-data pribadi dan perusahaan saya
                                        (seperti Foto Profil, KTP, Logo, dan Legalitas Perusahaan) kepada pihak
                                        penyelenggara Wirausaha Muda Mandiri untuk melakukan seleksi untuk menjadi
                                        peserta program ini. Saya memahami bahwa data tersebut tidak akan diungkapkan
                                        kepada pihak lain di luar penyelenggara Wirausaha Muda Mandiri.</li>
                                    <li>Saya memahami bahwa data pribadi saya seperti nomor telepon dan email tidak akan
                                        diberikan kepada pihak lain di luar penyelenggara program Wirausaha Muda
                                        Mandiri.</li>
                                    <li>Saya memahami bahwa selama menjadi peserta program dan mengikuti rangkaian
                                        kegiatan akan didokumentasikan dalam bentuk multimedia (audio, video, atau foto)
                                        dan bersedia digunakan untuk kebutuhan internal dan eksternal penyelenggara.
                                    </li>
                                    <li>Saya bersedia mengikuti kegiatan-kegiatan alumni Wirausaha Muda Mandiri setelah
                                        program Wirausaha Muda Mandiri 2024 selesai. </li>
                                </ul>


                            </div>





                        </div>
                        <div style="position: sticky;top: 6rem;" class="medium-4 columns">
                            <div class="callout padding-center rounded border-white form">
                                <div class="large-text lato-light">
                                    Rekening Mandiri <div class="hasError"
                                    *ngIf="this.dataUploadForm.get('rekening_mandiri').invalid && this.dataUploadForm.get('rekening_mandiri').touched">
                                    Wajib dilengkapi. Format rekening mandiri adalah 10 - 13 digit
                                </div>
                                </div>
                                <input placeholder="" formControlName="rekening_mandiri" maxlength="13" phoneMask
                                    (input)="numbersOnlyValidator($event)" class="margin-bottom-05" type="text">

                                <div class="tiny-text">
                                    Jika Anda belum mempunya nomor rekening silahkan membuat rekening dengan mudah dan
                                    cepat hanya melalui handphone Anda <a class="white bold"
                                        style="text-decoration: underline;" target="_blank"
                                        href="https://bankmandiri.co.id/livin/edukasi/cara-buka-rekening">disini</a>
                                </div>
                            </div>

                            <div class="callout show-for-small-only tiny-text rounded border-white padding-center">
                                <div class="large-text margin-bottom-05">
                                    Pernyataan Persetujuan

                                </div>
                                <ul class="margin-bottom-2 small-text">
                                    <li>Saya setuju untuk mengikuti seleksi dan program Wirausaha Muda Mandiri.</li>
                                    <li>Saya memahami bahwa saya akan memberikan data-data pribadi dan perusahaan saya
                                        (seperti Foto Profil, KTP, Logo, dan Legalitas Perusahaan) kepada pihak
                                        penyelenggara Wirausaha Muda Mandiri untuk melakukan seleksi untuk menjadi
                                        peserta program ini. Saya memahami bahwa data tersebut tidak akan diungkapkan
                                        kepada pihak lain di luar penyelenggara Wirausaha Muda Mandiri.</li>
                                    <li>Saya memahami bahwa data pribadi saya seperti nomor telepon dan email tidak akan
                                        diberikan kepada pihak lain di luar penyelenggara program Wirausaha Muda
                                        Mandiri.</li>
                                    <li>Saya memahami bahwa selama menjadi peserta program dan mengikuti rangkaian
                                        kegiatan akan didokumentasikan dalam bentuk multimedia (audio, video, atau foto)
                                        dan bersedia digunakan untuk kebutuhan internal dan eksternal penyelenggara.
                                    </li>
                                    <li>Saya bersedia mengikuti kegiatan-kegiatan alumni Wirausaha Muda Mandiri setelah
                                        program Wirausaha Muda Mandiri 2024 selesai. </li>
                                </ul>


                            </div>
                            <div class="callout">
                                <button (click)="updateFinalStep()"
                                    class="main-button expanded margin-bottom-0">
                                    <span>
                                        {{registration?.is_complete_step_4 == 1?'Konfirmasi & Update Pendaftaran':'Konfirmasi & Kirim Pendaftaran'}}
                                        <fa-icon [icon]="faCheckCircle"></fa-icon>
                                    </span>
                                </button>
                            </div>
                        </div>

                    </div>




                </div>
                <div class="page-enter-from-right margin-bottom-4" *ngIf="step =='5'">

                    <div *ngIf="this.registration">
                        <div class="section-subtitle margin-bottom-2">
                            Pendaftaran Terkonfirmasi <fa-icon [icon]="faCheckCircle"></fa-icon>
                        </div>

                        <div class="row">
                            <div class="medium-8 columns">
                                <div class="section-para margin-bottom-05">
                                    Nomor Pendaftaran <span class="cursor"
                                        style="text-decoration: underline;">{{registration?.id}}</span>
                                </div>
                                <div class="section-para margin-bottom-1">
                                    {{registration?.fullname}}, Terimakasih telah melengkapi form pendaftaran. Simpan
                                    nomor pendaftaran kamu untuk
                                    mengupdate profile dan
                                    konfirmasi berikutnya.
                                </div>

                                <!-- <div style="text-decoration: underline;line-height: 20px;"
                                    (click)="updateRegistration()" class="large-text bold cursor color-yellow">
                                    <fa-icon [icon]="faInfoCircle"></fa-icon> Update Informasi Pendaftaran
                                </div> -->
                            </div>



                        </div>
                    </div>




                </div>
            </div>
        </div>
    </div>

    <div class="small-text callout padding-center-1 show-for-small-only">
        Data yang sudah tersimpan tidak akan hilang saat browser ditutup / refresh. Anda dapat melanjutkan pengisian
        data dengan mangakses kembali halaman ini
    </div>

    <app-modal *ngIf="loginModal"></app-modal>

</section>