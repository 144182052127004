<div class="section section-gallery">


    <div class="padding-center">
        <div class="row">
            <div class="medium-5 columns">
                <div class="section-title color-yellow">

                    KESERUAN WMM 2024
                </div>

                <div class="section-para color-white">
                    Yuk intip keseruan WMM 2024
                </div>

            </div>
            <!-- <div class="medium-7 columns text-right">
                <button class="main-button">
                    <span>Gallery</span>
                </button>
            </div> -->
        </div>
        <div class="padding-center">
            <div class="carosell-wrapper">
                <div [ngStyle]="{width: windowWith+'px', transform: 'translate3d(-'+carosellItemClone+'px, 0px, 0px)'}"
                    class="carosell-container">

                    <div style="padding: 2px;" [ngStyle]="{width: carosellItem+'px'}" class="carosell-item"
                        *ngFor="let item of banners; let i=index">
                        <img width="200" height="300" style="width: 100%;" [src]="item.img_url" alt="">


                    </div>
                </div>
                <div class="carosell-navigation">
                    <a style=" background: white;
                color: #333;" class="carosell-navigation-left" (click)="scrollRight()">
                        <fa-icon [icon]="faChevronLeft"></fa-icon>
                    </a>
                    <a style=" background: white;
                color: #333;" class="carosell-navigation-right" (click)="scrollLeft()">
                        <fa-icon [icon]="faChevronRight"></fa-icon>
                    </a>
                </div>

            </div>
        </div>

        <!-- <div class="carosell-wrapper">
            <div class="padding-center" style="min-height: 60vh;">

                <div *ngFor="let item of alumni ; let i =index" class="alumni-containern long-fade-in">
    
                    <div *ngIf="item.is_selected" class="row long-fade-in">
                        <div class="carosell-navigation">
                            <a style=" background: white;
                        color: #333;" class="carosell-navigation-left"  (click)="navToLeft(item,i)">
                                <fa-icon [icon]="faChevronLeft"></fa-icon>
                            </a>
                            <a style=" background: white;
                        color: #333;" class="carosell-navigation-right" (click)="navToRight(item,i)">
                                <fa-icon [icon]="faChevronRight"></fa-icon>
                            </a>
                        </div>
                      
                        <div class="medium-5 columns">
    
                            <img class="brand_logo" height="100" width="100" [src]="item.logo" alt="image">
    
                            <div class="section-title color-yellow">
                                {{item.brand}}
                            </div>
    
                            <div class="section-para color-white margin-bottom-2">
                                {{item.description}}
                            </div>
    
                            <div class="flex-container content align-center">
                                <div class="flex-item">
                                    <div class="flex-item margin-right-05">
                                        <img class="avatar-image" height="50" width="50" [src]="item.profile_photo"
                                            alt="image">
                                    </div>
                                </div>
                                <div class="flex-item flex-1">
                                    <div class="activity-tile">
                                        <div class="title">
                                            {{item.name}}
                                        </div>
                                        <div class="subtitle">
                                            {{item.name_detail}}
                                        </div>
                                    </div>
                                </div>
    
                            </div>
    
                        </div>
                        <div class="medium-7 columns text-right">
                            <img width="600" height="300" [src]="item.cover" alt="">
                        </div>
                    </div>
                </div>
            </div>
           
        </div> -->
        
    </div>



</div>