import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';

@Component({
  selector: 'app-toast-component',
  templateUrl: './toast-component.component.html',
  styleUrls: ['./toast-component.component.scss']
})
export class ToastComponentComponent implements OnInit {
  @Output() onClick = new EventEmitter()
  message: any;
  isFull: boolean;
  show: boolean;
  msg: string;
  actionName: string;
  actionFn: VoidFunction;
  data: any;
  dialog: any;
  toast: any;
  faTimes=faTimes
  constructor(
    private appService : BusinessIntelligenceService,
  ) { }

  ngOnInit(): void {
    this.appService.dialogEvent.subscribe(
      (res)=>{
        if(res) {
          this.dialog = res
        }
      }
    )

    this.appService.toastEvent.subscribe(
      (res)=>{
        if(res) {
          this.toast = res
          setTimeout(() => {
            this.cancelToast()
          }, 5000)

        }
      }
    )
  }


  //DIALOG
  confirmDialog(){
    this.dialog.callbackFn()
    this.cancelDialog()
  }

  cancelDialog(){
    this.dialog = null
  }


  //TOAST
  confirmToast(){
    this.toast.callbackFn()
    this.cancelToast()
  }
  
  cancelToast(){
    this.toast = null
  }

}
