

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { title } from 'process';

import { BusinessIntelligenceService } from 'src/app/services/business-intelligence/business-intelligence.service';
import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-section-participant',
  templateUrl: './section-participant.component.html',
  styleUrls: ['./section-participant.component.scss']
})
export class SectionParticipantComponent implements OnInit {
  about_nav = [
    {
      'title': 'Beauty & Wellness',
      'key': 'Beauty%20%26%20Wellness',
      'id': 'BNW',
      'is_active': true,
    },
    {
      'title': 'Fashion',
      'key': 'Fashion',
      'id': 'FS',
    },
    {
      'title': 'Innovative Food & Beverages',
      'key': 'Innovative%20Food%20%26%20Beverages',
      'id': 'FNB',
    },
    {
      'title': 'Tech & Tech-Enabled Startups',
      'key': 'Tech%20%26%20Tech-Enabled%20Startups',
      'id': 'TS',
    },

  ]



  selectedCategory: any;
  participant: any;
  isLoading: boolean;
  selected_participant: any

  mobile_participant = {
    beauty: '',
    fashion: '',
    food: '',
    tech: ''
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appService: BusinessIntelligenceService,
    private webService: ManualOrderService,
    private serializer: UrlSerializer,
  ) { }

  ngOnInit(): void {
    this.about_nav.forEach(item => {
      this.getRegistration(item)

    });


    // this.getRegistrationMobile('beauty','Beauty%20%26%20Wellness')

  }

  selectCategory(item) {

    this.selectedCategory = item.key
    this.getRegistration(this.selectedCategory)
  }

  async getRegistration(cat) {
    this.isLoading = true
    this.participant = []
    console.log(cat)
    try {

      //  let param = '?is_finalist=1'
      let param = '?is_finalist=1&company_category=' + cat.key

      let response = await this.webService.getRegistration(param)
      this.participant[cat.title] = response.data
      console.log(this.participant)
      console.log(response)
    } catch (e) {

      this.appService.openToast(e)

      console.log(e)
    } finally {

      this.isLoading = false

    }
  }

  async getRegistrationMobile(key, cat) {
    this.isLoading = true


    try {

      //  let param = '?is_finalist=1'
      let param = '?is_finalist=1&company_category=' + cat

      let response = await this.webService.getRegistration(param)
      this.mobile_participant[key] = response.data

      console.log(response)
    } catch (e) {

      this.appService.openToast(e)

      console.log(e)
    } finally {

      this.isLoading = false

    }
  }

  async onChange(event: any, id) {
    const file: File = event.target.files[0];


    if (file) {

      if (event.target.files[0].size < 10000000) {
        const formData = new FormData();

        formData.append('file', file);



        try {
          this.appService.showLoading()
          let res = await this.webService.uploadPhoto(formData, 'id')
          console.log(res)
          if (res.data) {
            let form = {
              'id': id,
              'value': res.data
            }





            this.updateData(form)

          }

        } catch (error) {
          //this.appService.openToast(error)
        } finally {

          this.appService.hideLoading()

        }
      } else {
        this.appService.openToast('Ukuran file terlalu besar. Batas ukuran adalah 10 Mb')
      }



    }
  }

  async updateData(data) {


    let form = {}
    form[data.id] = data.value


    try {

      let response = await this.webService.updateRegistration(this.selected_participant.id, form)
      // console.log(response)

    } catch (e) {

      // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.openToast('Berhasil di update')

    }
  }



  async updateRegistration() {

    this.appService.showLoading()
    try {

      let form = {
        'is_confirm': false,
        'status': false
      }
      let response = await this.webService.updateRegistration(this.selected_participant.id, form)






    } catch (e) {

      // this.appService.openToast(e)

      console.log(e)
    } finally {
      this.appService.hideLoading()

    }
  }




}



