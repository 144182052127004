import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent implements OnInit {
  faChevronDown = faChevronDown
  faSearch=faSearch
  faTimes=faTimes
  searchKey: any;
  @Output() onSearch = new EventEmitter()
  @Output() onBlur = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
    this.searchKey
  }

  clearSearch(){
    this.searchKey = null
    this.onSearch.emit(this.searchKey)
  }
  search(){
    this.onSearch.emit(this.searchKey)
  }
  blur(){
    this.onBlur.emit(true)
  }
}
