<nav style="background-color: #458CFF;color: white !important;"  class="global-navigation show-for-medium elevate">
    <div class="flex-container align-center large-text">
        <div class="flex-item flex-4">
            <img class="margin-bottom-05" height="110" width="110" src="/assets/logo/mandiri-logo-white.svg"
            alt="image">

            

        </div>


      

        <div class="flex-item text-right margin-right-05">
           
            <button (click)="userLogout()" class="button small clear    margin-bottom-0">
              <fa-icon [icon]="faSignOutAlt"></fa-icon>  Logout</button>
        </div>
        



    </div>
</nav>
