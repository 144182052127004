import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { ManualOrderService } from 'src/app/services/manual-order/manual-order.service';

@Component({
  selector: 'app-learning-center-instagram',
  templateUrl: './learning-center-instagram.component.html',
  styleUrls: ['./learning-center-instagram.component.scss']
})
export class LearningCenterInstagramComponent implements OnInit {
  article: any;
  banners: { img_url: string; }[];
  carosellItem: number;
  carosellItemClone: number;
  total_slide: number;
  windowWith: number;
  buffer: number;
  start: number;

  faChevronLeft = faChevronLeft
  faChevronRight = faChevronRight
  showInsta: any;
  constructor(
    private webService: ManualOrderService,

    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router: Router,
  ) { }

  ngOnInit(): void {


    var h = window.innerHeight;

    console.log(window.innerWidth)



    this.getLearningCenter()
  }

  async getLearningCenter() {

    try {

      let response = await this.webService.getLearningCenter('?type=instagram')
      this.article = response.data
      this.total_slide = this.article.length
      this.buildslider()
    } catch (e) {



      console.log(e)
    } finally {
      this.showInsta = true
    }
  }

  buildslider() {
    if (window.innerWidth > 450) {
      this.carosellItem = window.innerWidth / 4.5
      this.carosellItemClone = window.innerWidth / 3.5
    } else {
      this.carosellItem = window.innerWidth / 2.5
      this.carosellItemClone = window.innerWidth / 1.5
    }



    this.windowWith = (window.innerWidth) * this.total_slide;
    this.buffer = this.carosellItem * (this.total_slide / 1.5)
    this.start = this.carosellItemClone

  }

  scrollLeft() {
    //console.log(this.buffer)



    this.carosellItemClone = this.carosellItemClone + this.carosellItem
    if (this.carosellItemClone >= this.buffer) {
      this.carosellItemClone = this.start
    }
    // console.log( this.carosellItemClone)
  }


  scrollRight() {



    this.carosellItemClone = this.carosellItemClone - this.carosellItem
    if (this.carosellItemClone < this.start) {
      this.carosellItemClone = (this.carosellItem * (this.total_slide - 3)) + window.innerWidth / 4.5
    }
    //  console.log( this.carosellItemClone)
  }

}
